import React, { Fragment } from "react";
import Header from "../components/Header";
import PageHeader from "../components/PageHeader";
import About from "../components/About/page";
import Footer from "../components/Footer";
import MobileMenu from "../components/MobileMenu";
import SEO from "../components/SEO";

const PageAbout = () => {
  return (
    <Fragment>
      <Header />
      <SEO />

      <PageHeader
        bgImg={require("../assets/img/about/about-head-bg.jpg")}
        title="About Kangaroo Care - Leading Women & Children Care Hospitals"
        content=""
      />
      <About
        title={"About Us"}
        heading="Welcome to <span class='basefont-strong'>Kangaroo Care</span>"
        alt="Lab technician testing in laboratory"
        thumb={require("../assets/img/about/about-page-section-one-bg.jpg")}
        content="Kangaroo Care Hospital is the result of intensive research and exhaustive studies revolving around the different needs of customers and varied groups situated in and around Vijayanagar. Being the first mother and child hospital in this area, it is utmostly important that we offer best services and facilities so that your favorable words of appreciation can helps us garner the much-needed trust factor amongst the masses. We aim to spread the idea of Kangaroo Mother Care – a method practiced by holding the baby skin to skin with mother. This direct skin-to-skin contact ensures both physiological and psychological bonding and warmth."
      />
      <About
        title=""
        heading=" Our <span class='basefont-strong'> Mission</span> & <span class='basefont-strong'>Vision </span> "
        thumb={require("../assets/img/about/about-page-section-two-bg.jpg")}
        alt="Happy mother and baby looking at each other"
        content={`<div class='container'>
          <div class='row'>
            <p>At Kangaroo care, we believe in providing everyone a Quality of Care such that our measure of success, is in the number of smiles we leave behind.</p>
          </div>
          <div class='row ts30'>
            <div class='col-md-6 col-sm-12 '>
                <p><strong>Patients:</strong> to provide the highest level of service and to give them an enjoyable and memorable experience. We consider all our patients as our own family and treat them the way we would want to be treated elsewhere.</p>
            </div>
            <div class='col-md-6 col-sm-12'>
              <p><strong>Staff:</strong> Our employees are the jewels in our crown. Each one of them helps us deliver the best service and experience.</p>
            </div>
          </div>
          <div class='row ts20'>
          <p>We share utmost respect, care, and courtesy with each one of our staff.</p>
        </div>
        <div class='row ts20'>
          <p>We at Kangaroo care would like to be India's most trusted brand in the field of women and child health. We wish to be front-runners in this sector by providing premier quality and high standard healthcare to every mother and child. Our aim is that no mother or child should be deprived of tertiary care in our surroundings.</p>
        </div>          
        </div>
        `}
        orderImg="1"
        orderContent="0"
        classes="about-page-section-two"
      />
      <About
        title=""
        heading="<span class='basefont-strong'> Core Values</span>"
        thumb={require("../assets/img/about/about-page-section-three-bg.png")}
        alt="Pregnant Women sitting on sofa holding fruit bowl"
        content={`
        <ul class="about-page-ul">
            <li>Ethical conduct</li>
            <li>Professional integrity and transparency</li>
            <li>Patient-centric quality care with commitment and compassion.</li>
            <li>We believe in following standards and protocols to ensure that all the patients get the Best Results.</li>
            <li>We aim to be a leading source of the research center and pass the benefit to patients with the newer advances and strive for continuous improvement.</li>
            <li>We will be a learning hospital where we educate and empower the next generation healthcare professionals to become leaders in Women and Child health care to their full potential.</li>
            <li>Accountability: Strict financial control which is based on uncompromising honesty so that we achieve our higher purpose.</li>
        </ul>
        `}
        classes="bs100"
      />
      <Footer />
      <MobileMenu />
    </Fragment>
  );
};
export default PageAbout;
