import React from "react";

const BlogSchema44 = () => {
  const blogSchemas44 = `<script type="application/ld+json">
  {
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": "https://kangaroocareindia.com/blogs/all-you-need-to-know-about-baby-feeding-schedules/44"
    },
    "headline": "All You Need To Know About Baby Feeding Schedules!",
    "description": "Every new parent will have these questions about feeding the baby. But the fact is there is no “perfect time” to feed your baby, you have to feed them whenever they are hungry. It’s a new experience for you and as time passes you won’t have to see the clock. It is advised to start with one meal a day, then you can move up to two for the next month or so.",
    "image": "https://kangaroocareindia.com/static/media/blog-44.f8ff8de1.jpg",  
    "author": {
      "@type": "Organization",
      "name": "Kangaroo Care",
      "url": "https://kangaroocareindia.com/"
    },  
    "publisher": {
      "@type": "Organization",
      "name": "Organization",
      "logo": {
        "@type": "ImageObject",
        "url": "https://kangaroocareindia.com/images/kc-logo-pink.png"
      }
    },
    "datePublished": "2021-10-26"
  }
  </script>
  
  
 `;

  return (
    <div>
      <iframe srcDoc={blogSchemas44} title="Blog Schema" width="0" height="0" />
    </div>
  );
};

export default BlogSchema44;
