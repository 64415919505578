import React from 'react'

function BlogSchema73() {
    const blogSchemas73 = `<script type="application/ld+json">
    {
      "@context": "https://schema.org",
      "@type": "BlogPosting",
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": "https://kangaroocareindia.com/blogs/how-to-stay-healthy-and-active-after-menopause/73"
      },
      "headline": "How To Stay Healthy And Active After Menopause?",
      "description": "Post-menopause years happen to be a very stressful and irritating part of a woman's life.",
      "image": "https://kangaroocareindia.com/static/media/blog-73.76d1bcd3a1951bfba8ca.jpg",  
      "author": {
        "@type": "Organization",
        "name": "Kangaroo care"
      },  
      "publisher": {
        "@type": "Organization",
        "name": "Kangaroo care",
        "logo": {
          "@type": "ImageObject",
          "url": "https://kangaroocareindia.com/images/kclogo-01-01.png"
        }
      },
      "datePublished": "2022-07-27"
    }
    </script>`;
    return (
      <div>
        <iframe srcDoc={blogSchemas73} title="Blog Schema" width="0" height="0" />
      </div>
    );
}

export default BlogSchema73
