import React, { Fragment } from "react";
import PageWrapper from "../../components/PageWrapper";
import DocInfo from "./DocInfo";

import docData from "../../data/Doctors/all-doctors.json";
import PageHeader from "../../components/PageHeader";

//  HELPERS
import { redirectTo404 } from "../../helpers";
import RedirectWithStatus from "../../helpers/RedirectWithStatus";
import RedirectinalArray from "../../helpers/SEO/Redirections/RedirectionLinks";

const DocDetailsPage = () => {
  //  FIND PATH NAME
  let { pathname } = window.location;
  let pathnameArr = pathname.split("/");

  //  OLD URL - GET QUERY PARAM ID & REDIRECT TO NEW URL
  let docId = new URLSearchParams(window.location.search).get("id");
  if (docId !== null) {
    return (
      <RedirectWithStatus status={301} from="" to={`${pathname}/${docId}`} />
    );
  }

  //  IF KEY NOT FOUND
  if (pathnameArr[3] === undefined) {
    redirectTo404();
  }

  docId = pathnameArr[3];
  const doctor = docData.find((member) => member.id === docId);

  if (!doctor) {
    window.location.href = "/doctors";
  }
  let { isDoctor = true } = doctor;

  if (!doctor) {
    redirectTo404();
  }

  let doctorName = doctor.firstname;
  if (doctor.lastname && doctor.lastname !== ".") {
    doctorName = doctorName + " " + doctor.lastname;
  }
  doctorName = isDoctor ? `Dr. ${doctorName}` : doctorName;

  return (
    <Fragment>
      <PageHeader
        bgImg={require("../../assets/img/doctors-bg.jpg")}
        title={doctorName}
        content={doctor.title}
      />

      <PageWrapper classes={"member-details-wrapper sm-top"}>
        <div className="col-12 bs100">
          <DocInfo doctor={doctor} doctorName={doctorName} />
        </div>
      </PageWrapper>
    </Fragment>
  );
};

export default DocDetailsPage;
