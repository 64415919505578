import React from 'react'

const Faq = () => {

    const faqSchema = (`
        <script type="application/ld+json">
            {
            "@context": "https://schema.org",
            "@type": "FAQPage",
            "mainEntity": [{
                "@type": "Question",
                "name": "Is there anything I can do before conceiving my baby, to ensure a healthy pregnancy and baby?",
                "acceptedAnswer": {
                "@type": "Answer",
                "text": "If you are planning to get pregnant, CONGRATULATIONS first of all.
            <ul>
            <li>Pregnancy can be difficult for various reasons. So, try to become as fit as possible before conceiving your baby. That will ensure that your baby grows healthy and you have a less worrying pregnancy and a safe labor.</li>
            <li> Identify a gynecologist and visit the doctor for a pregnancy checkup. Clarify all your doubts with him/her. Take diet, drug, exercise suggestions. Ask about the various stages of pregnancy and the dos and don'ts for the period. Understand all you need to know about your menstrual cycle after delivery and sexual intercourse during your pregnancy.</li>
            <li>Quit smoking for a few months before your planned pregnancy. This will help your body get in better shape to conceive and carry a healthy baby. Smoking adversely affects the baby in the womb and is a strong factor for low birth weight and birth defects. It is also one of the leading causes of infertility in men and women.</li>
            <li>Control your obesity, diabetes, blood pressure, and other health problems before you can even conceive.</li>
            <li>Eat the right foods in adequate quantities. A healthy diet is rightfully linked to a healthy and successful pregnancy, labor, and postnatal period.</li>
            <li> Also, identify a psychologist, maybe one in your maternity hospital, to help you get through the emotional highs and lows of your pregnancy and in order to avoid postnatal depression.</li>
            </ul>"
                }
            },{
                "@type": "Question",
                "name": "A lot of people have been telling me things I must and must not do during pregnancy. But I want to hear it from an expert. Can you show me what is right and wrong?",
                "acceptedAnswer": {
                "@type": "Answer",
                "text": "DOs
            <ul>
            <li>Most of your diet must now comprise leafs and healthy greens, other vegetables, all fruits, wholegrain bread and cereal, low-fat dairy products, eggs, and chicken.</li>
            <li>Ensure that you receive all the proteins, carbohydrates, calcium, and vital minerals through natural sources, instead of popping health supplement pills (unless absolutely necessary).</li>
            <li>Meet your dietician and ask them about the right and wrong foods and follow the food chart they prepare for you.</li>
            <li>Exercise lightly, walk, do little chores, etc. it is important to be active during your pregnancy. Being inactive leads to unwanted complications in your and your baby's health.</li>
            <li>Unless there is a complication that calls for a no-sex pregnancy, it is okay to have sex. There is nothing wrong in it. Your amniotic fluid naturally protects your baby and so it is okay for you to have sex and feel loved. But, meet your gynecologist and understand the safe sex positions.</li>
            <li> Keep yourself and your home clean at all times. You want to be sure that no infections affect your baby. Shower twice a day, wash your hands very frequently, etc.</li>
            <li>If you need to travel by air, do it in your second trimester. That is when the risk of premature labor or miscarriage is lowest.</li>
            </ul>
            DONT'Ts
            <ul>
            <li>Expose your body to excessive, unnatural heat by using hot water in bathtubs, sauna, and tanning booths. Too much heat can harm your baby.</li>
            <li> Take too many pills unless really necessary. Medicines can be a bad thing for your growing baby.</li>
            <li>Undergoing mammograms or x-rays can be excessively harmful to your growing baby.</li>
            <li> Travel on bad roads, ride on rollercoasters, have rough sex, etc. Remember that it is not about your temporary satisfaction, but for a healthy and uncomplicated pregnancy and a healthy baby.</li>
            </ul>"
                }
            },{
                "@type": "Question",
                "name": "Is there a way to deal with the morning sickness and all its symptoms?",
                "acceptedAnswer": {
                "@type": "Answer",
                "text": "<ul>
            <li>Nausea- Get up slowly. Hurried movements can make nausea worse. Don't be completely hungry ever. Eat 5 or 6 little meals in a day. Drink lots of fluids, hydration is extremely important. Get a lot of fresh air. Don't keep yourself craving. Eating a happy food can help.</li>
            <li> Cramps in the leg- Exercise regularly and avoid sitting in the same position for too long. Don't stand for too long. While sitting, take mini walk breaks to keep the blood flowing. Stretch your legs a few times in a day. Straighten your legs and wiggle your toes.</li>
            <li>Back pain- Wear flat shoes with a good arch and support. Do not lift heavy objects or standing and sitting for lengthy periods. Use a firm mattress to sleep on and ensure your chair is supporting your back well. Avoid painkillers for your backache. Resort to hot water bags, instead.</li>
            <li>Heartburn- Avoid acidic, greasy, spicy foods. Lower your caffeine intake. Do not lie down immediately after a meal. Move around a bit before you cave into rest.</li>
            </ul>"
                }
            },{
                "@type": "Question",
                "name": "How much weight should I ideally gain during my pregnancy?",
                "acceptedAnswer": {
                "@type": "Answer",
                "text": "Pregnancy does not mean that you can eat all you want and put on as much weight as possible. If you are overweight or underweight, it will drastically affect you and your baby's health. Ideally, you should gain your baby's weight and a little more than that. Ideally, you should not put on anything more than 12–14 kilograms. But, if you are overweight, you should not gain more than 10 kilograms and if you are underweight, you can gain up to 15–16 kilograms. If it's going to be twins, then maybe up to 20 kilograms is okay. No more, though."
                }
            },{
                "@type": "Question",
                "name": "Can I come over to the hospital and take a tour of the maternity and labor facilities? I just want to be sure.",
                "acceptedAnswer": {
                "@type": "Answer",
                "text": "Of course! In fact, we encourage you to take a tour and be doubly convinced about our expertise and facilities. You may either call and book an appointment or watch out for pre-planned tour dates and timings on our website and Facebook page."
                }
            },{
                "@type": "Question",
                "name": "How many branches do Kangaroo Care hospitals have? How do I get there?",
                "acceptedAnswer": {
                "@type": "Answer",
                "text": "<ul>
            <li> Well, we only have a single hospital, yet. So it is not going to be confusing. Check our map link and it will guide you directly to us.</li>
            <li> Alternatively, call us 080 - 65651919 and we'll give you directions.</li>
            <li> Also, here's our address: #89, 17th cross, MC Layout, Near Balagangadharnath Swamy Metro Station, Vijayanagar, Bangalore 560040.</li>
            </ul>"
                }
            },{
                "@type": "Question",
                "name": "In what situations is it okay for me to call my doctor home between prenatal checkups?",
                "acceptedAnswer": {
                "@type": "Answer",
                "text": "You'll be seeing a lot more of your gynecologist, obstetrician, prenatal care specialist, or midwife regularly during your pregnancy. But, sometimes between your prenatal checkups, you may find the need to call your doctor. If you notice any of the following symptoms, call your doctor.
            <ul>
            <li>Severe or unusual abdominal pain or cramps</li>
            <li> Significant reduction in your baby's movements, especially after you cross 28 weeks</li><li>Breathlessness</li>
            <li>Bleeding in the 2nd or 3rd trimester</li>
            <li> Frequent aches or tightening in the abdomen or lower back, or excessive fluid discharge can be signs of premature delivery</li>
            <li>Frequent ache or cramps in legs, arms, or chest</li>
            <li>High body temperature; over 100°</li>
            <li>Severe vomiting, dizziness, or diarrhea</li>
            <li>Blurred vision</li>
            <li>Excessive fingers, toes, hands, or face swelling</li>
            </ul>"
                }
            },{
                "@type": "Question",
                "name": "How do I prepare to breastfeed my baby? Is there anything in particular that I must be aware of?",
                "acceptedAnswer": {
                "@type": "Answer",
                "text": "First of all, know that breastfeeding is extremely healthy for your baby and you. It protects your baby against many diseases that can attack during infancy, childhood, and even later on in life. It helps protect your little one from everything including respiratory illnesses, diarrhea, ear infections, and possibly obesity, diabetes, and leukemia too. Moreover, it protects you. Breastfeeding mothers statistically show a lower risk of ovarian and breast cancers.
            Also
            <ul>
            <li> Talk to your dietician about the right foods that aid in milk production so that you can feed your baby for a longer period.</li>
            <li>Talk to your lactation consultant. They will provide you with prenatal breastfeeding awareness to the postnatal hospital and home visits, to relevant literature on breastfeeding, etc.</li>
            </ul>"
                }
            },{
                "@type": "Question",
                "name": "What is postnatal or postpartum depression? How can it be tackled?",
                "acceptedAnswer": {
                "@type": "Answer",
                "text": "While depression may be an unwanted symptom at any point, it is a normal occurring during or after pregnancy. It is, in fact, believed to be one of the most common complications linked with pregnancy. It is caused by a blend of inconsistent hormone levels associated with conception, pregnancy, childbirth, and parenting.
            The symptoms of postnatal or postpartum depression can include feeling hopeless, helpless, and sad; frequent crying; withdrawing from social circles, even friends and family; stressful and excessive eating or sleeping; feeling guilty or worthless; etc.
            Talk to your family or psychologist about these feelings. Don't take a drastic step, one that will affect many lives for the rest of time. Talk, take the necessary medication, and do happy things. Light-hearted reading and cinema will surely help."
                }
            },{
                "@type": "Question",
                "name": "I have so many questions I want to ask. So many things I need to know. Is there a way I can catch up on everything related to pregnancy, childbirth, and parenting?",
                "acceptedAnswer": {
                "@type": "Answer",
                "text": "Pregnancy, childbirth, and parenting are three simple words, but they need to be understood well to avoid any complication. While it is important that you read up all you want to be well informed, incorrect reading can also misinform you too. The ideal way to go about it is to consult with your doctors and specialists. First, clarify all your doubts in person and ask them all you can possibly think of. Then, take their suggestion on good online and offline reading. This will help you a lot. But most of all, saddle up and prepare yourself for the yearlong rollercoaster that is going to unfold. Prepare yourself emotionally, mentally, and physically for one of the most beautiful phases of human life. Have a happy pregnancy!"
                }
            }]
            }
        </script>
    `);

    return (
        <div>
            <iframe srcDoc={faqSchema} title="Faq Schema" width="0" height="0" />
        </div>
    )
}

export default Faq
