import React from "react";

const Careers = ({ carrerData }) => {
  //  let carrerData = CarrerList[0];
  // console.log(carrerData);
  return (
    <>
      <div className="careers-page-wrapper sm-top sm-bottom">
        <div className="container">
          <div
            className="service-details-info"
            dangerouslySetInnerHTML={{ __html: carrerData.aboutServiceDesc }}
          />
        </div>
      </div>
    </>
  );
};

export default Careers;
