import React from "react";

const BlogSchema46 = () => {
  const blogSchemas46 = `
  <script type="application/ld+json">
{
  "@context": "https://schema.org",
  "@type": "BlogPosting",
  "mainEntityOfPage": {
    "@type": "WebPage",
    "@id": "https://kangaroocareindia.com/blogs/std-causes-symptoms-and-treatment/46"
  },
  "headline": "STD(Sexually Transmitted Diseases) - Causes, Symptoms & Treatment",
  "description": "STD! A serious condition that can develop or transmit from one person to another through unprotected sexual contact. Well, this does not mean that sexual contact is the only way through which STDs are transmitted. There are many other factors through which a person can develop STD. However, the symptoms vary for men and women.",
  "image": "https://kangaroocareindia.com/static/media/blog-46.2696c438260bdf6ef37c.jpg",  
  "author": {
    "@type": "Organization",
    "name": "Kangaroo Care",
    "url": "https://kangaroocareindia.com/"
  },  
  "publisher": {
    "@type": "Organization",
    "name": "Kangaroo Care",
    "logo": {
      "@type": "ImageObject",
      "url": ""
    }
  },
  "datePublished": "2021-12-09"
}
</script>

 `;

  return (
    <div>
      <iframe srcDoc={blogSchemas46} title="Blog Schema" width="0" height="0" />
    </div>
  );
};

export default BlogSchema46;
