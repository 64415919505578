import React from "react";
import SectionTitle from "../../UI/SectionTitle";
import GetDoctors from "../../../templates/GetDoctor";

const GetDocsRight = (props) => {
  return (
    <div className="team-area bg-brand">
      <div className="row no-gutters">
        <div className="col-xl-4 col-xs-12">
          <div
            className="team-area-left text-center"
            style={{ padding: "75px 100px 0" }}
          >
            <SectionTitle
              variant="light"
              title={props.deptTitle}
              image={require("../../../assets/img/" + props.image)}
              heading=""
              text=""
            />
          </div>
        </div>

        <div
          className="col-xl-8 col-xs-12"
          style={{ borderTop: "15px solid #ef586f" }}
        >
          <div className="team-area-right team-area-right--2 bg-img">
            <div className="container mr-auto">
              <GetDoctors hospitals={props.hospitals} dept={props.dept} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GetDocsRight;
