import React from "react";
import SectionTitle from "../../UI/SectionTitle";
import SlickSlider from "../../UI/Slick";
import Founders from "./Adminteam";

import teamBg from "../../../assets/img/bg-blog.jpg";
import founderData from "../../../data/Adminteam/adminteam.json";
import founderDataMysore from "../../../data/Adminteam/adminteammysore.json";
import founderDataRamanagara from "../../../data/Adminteam/adminRamanagara.json";
import centralTem from "../../../data/Adminteam/centralTeam.json";
import centralMainTeam from "../../../data/Adminteam/centralMainTeam.json";
function Admin() {
  const settings = {
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: false,
    autoplay: false,
    dots: true,
    rows: 2,
    infinite: false,
    className: "team-content-wrap slick-dots--light mtn-md-5",
    responsive: [
      {
        breakpoint: 1550,
        settings: {
          slidesToShow: 4,
          rows: 2,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 4,
          rows: 2,
        },
      },
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 1,
          rows: 6,
        },
      },
    ],
  };
  return (
    // <div
    //   className="team-area-wrapper bg-img sp-y"
    //   style={{ backgroundImage: `url(${teamBg})` }}
    // >
    //   <div className="container-fluid">
    //     <div className="row align-items-center">
    //       <div className="col-lg-4">
    //         <SectionTitle
    //           variant="light"
    //           title="Operational Team"
    //           heading=""
    //           text=""
    //           image={require("../../../assets/img/separator8.svg")}
    //         />
    //       </div>

    //       <div className="col-lg-8">
    //         <SlickSlider settings={settings}>
    //           {founderData.map((team) => (
    //             <div key={team.id}>
    //               <Founders
    //                 id={team.id}
    //                 text={team.text}
    //                 image={team.image}
    //                 name={team.name}
    //                 link={team.link}
    //               />
    //             </div>
    //           ))}
    //         </SlickSlider>
    //       </div>
    //     </div>
    //   </div>
    // </div>

    <div
      className="team-area-wrapper bg-img sp-y"
      style={{ backgroundImage: `url(${teamBg})` }}
    >
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-lg-12 text-center">
            <SectionTitle
              variant="light"
              title="Central Team "
              heading=""
              text=""
              image={require("../../../assets/img/separator8.svg")}
              titleTag="h1"
            />
          </div>

          <div className="row text-center d-flex justify-content-center">
            {centralMainTeam.map((team) => (
              <div className="col col-md-3 col-11 ">
                <div key={team.id}>
                  <Founders
                    id={team.id}
                    text={team.text}
                    image={team.image}
                    name={team.name}
                    link={team.link}
                    place={team.place}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-lg-12 text-center">
            <SectionTitle
              variant="light"
              title="Central Team "
              heading=""
              text=""
              image={require("../../../assets/img/separator8.svg")}
              titleTag="h1"
            />
          </div>

          <div className="row text-center d-flex justify-content-center">
            {centralTem.map((team) => (
              <div className="col col-md-3 col-11 ">
                <div key={team.id}>
                  <Founders
                    id={team.id}
                    text={team.text}
                    image={team.image}
                    name={team.name}
                    link={team.link}
                    place={team.place}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="container-fluid mt-sm-5 mt-2">
        <div className="row align-items-center">
          <div className="col-lg-12 text-center">
            <SectionTitle
              variant="light"
              title=" Team Bangalore"
              heading=""
              text=""
              image={require("../../../assets/img/separator8.svg")}
            />
          </div>

          <div className="row text-center justify-content-center">
            {founderData.map((team) => (
              <div className="col col-md-3 col-11">
                <div key={team.id}>
                  <Founders
                    id={team.id}
                    text={team.text}
                    image={team.image}
                    name={team.name}
                    link={team.link}
                    place={team.place}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="container-fluid mt-sm-5 mt-2">
        <div className="row align-items-center">
          <div className="col-lg-12 text-center">
            <SectionTitle
              variant="light"
              title=" Team Mysore"
              heading=""
              text=""
              image={require("../../../assets/img/separator8.svg")}
            />
          </div>

          <div
            className="row text-center justify-content-center"
            style={{ margin: "0 auto" }}
          >
            {founderDataMysore.map((team) => (
              <div className="col col-md-4 col-11">
                <div key={team.id}>
                  <Founders
                    id={team.id}
                    text={team.text}
                    image={team.image}
                    name={team.name}
                    link={team.link}
                    place={team.place}
                  />
                </div>{" "}
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="container-fluid mt-sm-5 mt-2">
        <div className="row align-items-center">
          <div className="col-lg-12 text-center">
            <SectionTitle
              variant="light"
              title=" Team Ramanagara"
              heading=""
              text=""
              image={require("../../../assets/img/separator8.svg")}
            />
          </div>

          <div
            className="row text-center justify-content-center"
            style={{ margin: "0 auto" }}
          >
            {founderDataRamanagara.map((team) => (
              <div className="col col-md-3 col-11">
                <div key={team.id}>
                  <Founders
                    id={team.id}
                    text={team.text}
                    image={team.image}
                    name={team.name}
                    link={team.link}
                    place={team.place}
                  />
                </div>{" "}
              </div>
            ))}
          </div>
        </div>
      </div> */}
    </div>
  );
}

export default Admin;
