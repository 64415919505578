import React from "react";

const NICUChildDevelopFAQ = () => {
  const childdevelopSchema = `
<script type="application/ld+json">
{
  "@context": "https://schema.org",
  "@type": "FAQPage",
  "mainEntity": [{
    "@type": "Question",
    "name": "What is child development?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Child development means the period of physical, cognitive, and social growth of the child, begins from birth and continues to early adulthood. It focuses on the changes that take place in humans as they grow."
    }
  },{
    "@type": "Question",
    "name": "Why is child development important?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Child development is a great opportunity for the children to release their full potential so that they can become mentally and physically strong in their respective age. Early childhood experiences enhance the learning skills of a child and also affect emotional and social development."
    }
  },{
    "@type": "Question",
    "name": "What is the exact age for child development?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "The child goes through a distinct stage of development as they grow, from birth to early adulthood. During these stages, it is important to take care of the child properly so that he/she can show his/her full potential. It is better to consult a child development specialist hospital in Bangalore for any queries."
    }
  },{
    "@type": "Question",
    "name": "What are the major stages of child development?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Every stage of child development is crucial. However, there are three stages: early childhood (0-8), middle childhood (8-12), and adolescence ( 12-18). Social norms and institutions, customs, and laws are influenced at these stages."
    }
  },{
    "@type": "Question",
    "name": "What does a child development specialist do?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "A child development specialist or paediatrician is a healthcare specialist ensuring the health of the children who are suffering from development and psychological disorder and helps to overcome the disability and difficult obstacles."
    }
  },{
    "@type": "Question",
    "name": "What is the most important thing in child development?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Children grow every day, there's rapid development but factors like physical, emotional, intellectual, social, and psychological growth are very important in child development."
    }
  },{
    "@type": "Question",
    "name": "What are the most important years of a child's development?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "The most important years of a child’s development are from birth to three years old. They experience and explore many things during this period. It can be considered as the learning period."
    }
  }
]
}
</script>

 `;

  return (
    <div>
      <iframe
        srcDoc={childdevelopSchema}
        title="NICU Child Structured Schema"
        width="0"
        height="0"
      />
    </div>
  );
};

export default NICUChildDevelopFAQ;
