import React from 'react'

function BlogSchema82() {
    const blogSchemas82 = `<script type="application/ld+json">
    {
      "@context": "https://schema.org",
      "@type": "BlogPosting",
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": "https://kangaroocareindia.com/blogs/important-information-about-postpartum-rehabilitation/82"
      },
      "headline": "Important Information About Postpartum Rehabilitation",
      "description": "Life for new mothers can get hectic and chaotic. From changing baby’s diapers and feeding them to taking proper care of them, it becomes difficult to even take a few minutes for yourself.",
      "image": "https://kangaroocareindia.com/static/media/blog-82.acf829f4cbdafeb7c2b2.jpg",  
      "author": {
        "@type": "Organization",
        "name": "Kangaroocare",
        "url": "https://kangaroocareindia.com/"
      },  
      "publisher": {
        "@type": "Organization",
        "name": "Kangaroocare",
        "logo": {
          "@type": "ImageObject",
          "url": "https://kangaroocareindia.com/images/kangaroo-care-new-logo.png"
        }
      },
      "datePublished": "2022-10-26"
    }
    </script>  
    `;
    return (
      <div>
        <iframe srcDoc={blogSchemas82} title="Blog Schema" width="0" height="0" />
      </div>
    );
}

export default BlogSchema82
