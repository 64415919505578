import React from "react";
import DocSchedule from "./DocSchedule";

const DocInfo = ({ doctor, doctorName = "" }) => {
  let {
    image,
    firstname,
    lastname,
    about,
    title,
    imageTitle = "",
    research = "",
  } = doctor;

  let docName = doctorName ? doctorName : `Dr. ${firstname} ${lastname}`;

  if (imageTitle === "") {
    imageTitle = firstname + " " + lastname;
  }

  return (
    <div className="member-details-top">
      <div className="row">
        <div className="col-md-5">
          <div className="member-pic mb-sm-35">
            <img
              src={require("../../assets/img/doctors/" + image)}
              alt={imageTitle}
              title={imageTitle}
              className="docPic"
            />
          </div>
          <DocSchedule doctor={doctor} />
        </div>

        <div className="col-md-7 ml-auto">
          <div className="member-desc">
            <h4>{docName}</h4>
            <h5>{title}</h5>
            <br />
            <p
              className="doctor-member-title"
              dangerouslySetInnerHTML={{ __html: about }}
            />
            {research !== "" && (
              <p
                className="service-details-info"
                dangerouslySetInnerHTML={{ __html: research }}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DocInfo;
