export const blogSchema = [
  {
    id: "38",
    schemaCode: `
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": "https://kangaroocareindia.com/blogs/health-benefits-of-using-a-pregnancy-pillow/38"
    },
    "headline": "Health Benefits Of Using A Pregnancy Pillow",
    "description": "For every woman, pregnancy experiences are the most challenging as well as exciting period. This period of 9 months requires extreme care and attention.  It is required to create a safe and comfortable pregnancy environment. Birthing pillow plays an important role in enabling this environment.",
    "image": "https://kangaroocareindia.com/static/media/blog-39.f361ac28.jpg",  
    "author": {
      "@type": "Organization",
      "name": "Kangaroo Care",
      "url": "https://kangaroocareindia.com/"
    },  
    "publisher": {
      "@type": "Organization",
      "name": "Kangaroo Care",
      "logo": {
        "@type": "ImageObject",
        "url": "https://kangaroocareindia.com/images/kc-logo-pink.png"
      }
    },
    "datePublished": "2021-09-16" `,
  },
  {
    id: "91",
    schemaCode: `
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": "https://kangaroocareindia.com/blogs/tips-for-improving-your-mental-health-during-pregnancy/91"
    },
    "headline": "Tips For Improving Your Mental Health During Pregnancy",
    "description": "Every woman has a unique pregnancy experience, whether she is ready for it or not. Pregnant women experience mental problems such as depression, stress, drowsiness, overexcitement, weakness, loneliness, sadness, etc",
    "image": "https://kangaroocareindia.com/static/media/blog-91.cd0b1fc6cbba8ea83115.jpg",  
    "author": {
      "@type": "Organization",
      "name": "kangaroo care",
      "url": "https://kangaroocareindia.com/"
    },  
    "publisher": {
      "@type": "Organization",
      "name": "kangaroo care",
      "logo": {
        "@type": "ImageObject",
        "url": "https://kangaroocareindia.com/images/kangaroo-care-new-logo.png"
      }
    },
    "datePublished": "2023-19-01"
  `,
  },
  {
    id: "92",
    schemaCode: `
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": "https://kangaroocareindia.com/blogs/pregnancy-guidelines-for-women-with-pre-eclampsia/92"
    },
    "headline": "Pregnancy Guidelines For Women With Pre-eclampsia",
    "description": "Pregnancy is a beautiful and life-changing experience, but the other side can be full of difficulties and complications. Pre-eclampsia is one such pregnancy complication, a complex condition that affects about 5-8% of all pregnancies",
    "image": "https://kangaroocareindia.com/static/media/blog-92.a3b91ae785d543a12315.jpg",  
    "author": {
      "@type": "Organization",
      "name": "Kangaroo care",
      "url": "https://kangaroocareindia.com/"
    },  
    "publisher": {
      "@type": "Organization",
      "name": "Kangaroo care",
      "logo": {
        "@type": "ImageObject",
        "url": "https://kangaroocareindia.com/images/kangaroo-care-new-logo.png"
      }
    },
    "datePublished": "2023-24-01"
  
  `,
  },
  {
    id: "93",
    schemaCode: `
    "@context": "https://schema.org",
  "@type": "BlogPosting",
  "mainEntityOfPage": {
    "@type": "WebPage",
    "@id": "https://kangaroocareindia.com/blogs/symptoms-and-risk-factors-of-preterm-delivery-and-how-to-avoid-it/93"
  },
  "headline": "Symptoms And Risk Factors Of Preterm Delivery And How To Avoid It?",
  "description": "Preterm delivery, also known as premature birth, is a significant health problem in women across the globe. According to WHO (World Health Organization), 15 million babies are born prematurely every year around the world.",
  "image": "https://kangaroocareindia.com/static/media/blog-93.1d3b2f8a7cdd6df2187f.jpg",  
  "author": {
    "@type": "Organization",
    "name": "Kangaroo care",
    "url": "https://kangaroocareindia.com/"
  },  
  "publisher": {
    "@type": "Organization",
    "name": "Kangaroo care",
    "logo": {
      "@type": "ImageObject",
      "url": "https://kangaroocareindia.com/images/kangaroo-care-new-logo.png"
    }
  },
  "datePublished": "2023-02-02"

  `,
  },
  {
    id: "94",
    schemaCode: `
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": "https://kangaroocareindia.com/blogs/your-daily-schedule-as-a-work-at-home-mother/94"
    },
    "headline": "Your Daily Schedule As A Work-At-Home Mother",
    "description": "The majority of women worry and struggle to balance work and family obligations. Most women are confused to justify their priorities. They get stressed as a result of this uncertainty, which in turn damages both their mental and physical health.",
    "image": "https://kangaroocareindia.com/static/media/blog-94.a8d11c4142c4744bbadc.jpg",  
    "author": {
      "@type": "Organization",
      "name": "Kangaroo care",
      "url": "https://kangaroocareindia.com/"
    },  
    "publisher": {
      "@type": "Organization",
      "name": "Kangaroo care",
      "logo": {
        "@type": "ImageObject",
        "url": "https://kangaroocareindia.com/images/kangaroo-care-new-logo.png"
      }
    },
    "datePublished": "2023-02-08"
  
  `,
  },
  {
    id: "95",
    schemaCode: `
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": "https://kangaroocareindia.com/blogs/how-pregnancy-is-calculated/95"
    },
    "headline": "How Pregnancy Is Calculated",
    "description": "Most women have many doubts and fears about pregnancy, like the length of the pregnancy or gestational age. Some women neglect to check their pregnancies and track their babies.",
    "image": "https://kangaroocareindia.com/static/media/blog-95.daffad3795b8ebfab235.jpg",  
    "author": {
      "@type": "Organization",
      "name": "Kangaroo Care",
      "url": "https://kangaroocareindia.com/"
    },  
    "publisher": {
      "@type": "Organization",
      "name": "Kangaroo Care",
      "logo": {
        "@type": "ImageObject",
        "url": "https://kangaroocareindia.com/images/kangaroo-care-new-logo.png"
      }
    },
    "datePublished": "2023-03-12"
  
  `,
  },
  {
    id: "96",
    schemaCode: `
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": "https://kangaroocareindia.com/blogs/what-is-ovarian-cyst/96"
    },
    "headline": "What is Ovarian Cyst?",
    "description": "Ovarian cysts are fluid-filled sacs that develop on or inside the ovaries. They are common, and most women will develop at least one during their lifetime. Ovarian cysts can range in size from a few millimeters to several centimeters.",
    "image": "https://kangaroocareindia.com/static/media/blog-96.ee111e0b9041dfe88779.jpg",  
    "author": {
      "@type": "Organization",
      "name": "Kangaroo Care",
      "url": "https://kangaroocareindia.com/"
    },  
    "publisher": {
      "@type": "Organization",
      "name": "Kangaroo Care",
      "logo": {
        "@type": "ImageObject",
        "url": "https://kangaroocareindia.com/images/kangaroo-care-new-logo.png"
      }
    },
    "datePublished": "2023-02-15"
  
  `,
  },
  {
    id: "97",
    schemaCode: `
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": "https://kangaroocareindia.com/blogs/important-information-about-how-to-get-ultrasounds-during-pregnancy/97"
    },
    "headline": "Important Information About How to get Ultrasounds During Pregnancy",
    "description": "Ultrasounds are a medical imaging technique that uses sound waves to create detailed images of organs, soft tissues, and blood vessels. Ultrasound imaging is used to diagnose and monitor various medical conditions, including pregnancy.",
    "image": "https://kangaroocareindia.com/static/media/blog-97.cd19ff27d049c93d0687.jpg",  
    "author": {
      "@type": "Organization",
      "name": "Kangaroo Care",
      "url": "https://kangaroocareindia.com/"
    },  
    "publisher": {
      "@type": "Organization",
      "name": "Kangaroo Care",
      "logo": {
        "@type": "ImageObject",
        "url": "https://kangaroocareindia.com/images/kangaroo-care-new-logo.png"
      }
    },
    "datePublished": "2023-02-22"
  
  `,
  },
  {
    id: "98",
    schemaCode: `
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": "https://kangaroocareindia.com/blogs/7-effective-techniques-to-reduce-chronic-pelvic-pain/98"
    },
    "headline": "7 Effective Techniques to Reduce Chronic Pelvic Pain",
    "description": "Chronic pelvic pain (CPP) is a complex, disabling condition that affects 1-in-6 women of reproductive age. It is characterized by persistent or recurrent pain in the lower abdomen, pelvic region, and lower back that lasts for more than 6 months.",
    "image": "https://kangaroocareindia.com/static/media/blog-98.9129a3b9a711feb5ffed.jpg",  
    "author": {
      "@type": "Organization",
      "name": "Kangaroo Care",
      "url": "https://kangaroocareindia.com/"
    },  
    "publisher": {
      "@type": "Organization",
      "name": "Kangaroo Care",
      "logo": {
        "@type": "ImageObject",
        "url": "https://kangaroocareindia.com/images/kangaroo-care-new-logo.png"
      }
    },
    "datePublished": "2023-02-28"
  
  `,
  },
  {
    id: "99",
    schemaCode: `
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": "https://kangaroocareindia.com/blogs/10-things-i-wish-somebody-had-Told-Me-About-C-Sections/99"
    },
    "headline": "10 Things I Wish Somebody Had Told Me About C-Sections",
    "description": "Childbirth is one of the most miraculous and extraordinary experiences one can have. But for some, it can also be a stressful and uncomfortable experience. C-sections are a common procedure in which a baby is delivered surgically through an incision in the mother's abdomen and uterus.",
    "image": "https://kangaroocareindia.com/static/media/blog-99.1894a907829fcc005cdc.png",  
    "author": {
      "@type": "Organization",
      "name": "Kangaroo Care",
      "url": "https://kangaroocareindia.com/"
    },  
    "publisher": {
      "@type": "Organization",
      "name": "Kangaroo Care",
      "logo": {
        "@type": "ImageObject",
        "url": "https://kangaroocareindia.com/images/kangaroo-care-new-logo.png"
      }
    },
    "datePublished": "2023-05-03"
  
  `,
  },
  {
    id: "100",
    schemaCode: `
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": "https://kangaroocareindia.com/blogs/the-evidence-on-advanced-maternal-age/100"
    },
    "headline": "The evidence on advanced maternal age",
    "description": "Advanced maternal age (AMA) is defined as a woman being 35 years of age or older at the time of conception. It is a topic of much debate in the medical community due to the potential risks associated with this age group.",
    "image": "https://kangaroocareindia.com/static/media/blog-100.33514b23b0735e72500e.png",  
    "author": {
      "@type": "Organization",
      "name": "Kangaroo Care",
      "url": "https://kangaroocareindia.com/"
    },  
    "publisher": {
      "@type": "Organization",
      "name": "Kangaroo Care",
      "logo": {
        "@type": "ImageObject",
        "url": "https://kangaroocareindia.com/images/kangaroo-care-new-logo.png"
      }
    },
    "datePublished": "2023-06-09"
  
  `,
  },
  {
    id: "101",
    schemaCode: `
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": "https://kangaroocareindia.com/blogs/what-are-the-symptoms-of-hyperovulation/101"
    },
    "headline": "What Are the Symptoms of Hyperovulation?",
    "description": "Hyper-ovulation, also known as super-ovulation, is a condition that occurs when a woman releases more than one egg during a menstrual cycle. This phenomenon is typically caused by fertility treatments or hormonal imbalances, and it can increase the likelihood of conceiving twins or multiple pregnancies.",
    "image": "https://kangaroocareindia.com/static/media/blog-101.6d436e95243fd510b6f8.png",  
    "author": {
      "@type": "Organization",
      "name": "Kangaroo Care",
      "url": "https://kangaroocareindia.com"
    },  
    "publisher": {
      "@type": "Organization",
      "name": "Kangaroo Care",
      "logo": {
        "@type": "ImageObject",
        "url": "https://kangaroocareindia.com/images/kangaroo-care-new-logo.png"
      }
    },
    "datePublished": ""
  
  `,
  },
  {
    id: "102",
    schemaCode: `
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": "https://kangaroocareindia.com/blogs/pregnant-chicken-pox/102"
    },
    "headline": "Pregnant Chicken pox",
    "description": "Pregnancy is an exciting time for many women, but it can also be a time of concern and anxiety, especially when it comes to the risk of infections.",
    "image": "https://kangaroocareindia.com/static/media/blog-102.512ecfbb1fec46ae51cc.png",  
    "author": {
      "@type": "Organization",
      "name": "Kangaroo Care",
      "url": "https://kangaroocareindia.com/"
    },  
    "publisher": {
      "@type": "Organization",
      "name": "Kangaroo Care",
      "logo": {
        "@type": "ImageObject",
        "url": "https://kangaroocareindia.com/images/kangaroo-care-new-logo.png"
      }
    },
    "datePublished": "2023-05-25"
  
  `,
  },
  {
    id: "103",
    schemaCode: `
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": "https://kangaroocareindia.com/blogs/understanding-menstrual-irregularities-and-how-to-manage-them/103"
    },
    "headline": "Understanding Menstrual Irregularities and How to Manage Them",
    "description": "For many women, menstrual irregularities can be a frustrating and sometimes even alarming issue. Menstrual irregularities can take many forms, from missed periods to unusually heavy or prolonged bleeding.",
    "image": "https://kangaroocareindia.com/static/media/blog-103.dfb5f80bed93faa54692.png",  
    "author": {
      "@type": "Organization",
      "name": "Kangaroo Care",
      "url": "https://kangaroocareindia.com/"
    },  
    "publisher": {
      "@type": "Organization",
      "name": "Kangaroo Care",
      "logo": {
        "@type": "ImageObject",
        "url": "https://kangaroocareindia.com/images/kangaroo-care-new-logo.png"
      }
    },
    "datePublished": ""
  
  `,
  },
  {
    id: "104",
    schemaCode: `
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": "https://kangaroocareindia.com/blogs/pregnancy-planning-tips-and-advice-from-a-gynecologist/104"
    },
    "headline": "Pregnancy Planning: Tips and Advice from a Gynecologist",
    "description": "Are you planning to start a family? At Kangaroo Care, we understand the importance of pregnancy planning for a healthy pregnancy and a healthy baby.",
    "image": "https://kangaroocareindia.com/static/media/blog-104.0514756b220d61c7803a.png",  
    "author": {
      "@type": "Organization",
      "name": "Kangaroo Care",
      "url": "https://kangaroocareindia.com/"
    },  
    "publisher": {
      "@type": "Organization",
      "name": "Kangaroo Care",
      "logo": {
        "@type": "ImageObject",
        "url": "https://kangaroocareindia.com/images/kangaroo-care-new-logo.png"
      }
    },
    "datePublished": "2023-04-10"
  
  `,
  },
  {
    id: "105",
    schemaCode: `
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": "https://kangaroocareindia.com/blogs/myths-and-facts-about-contraception-what-women-need-to-know/105"
    },
    "headline": "Admin Admin Administrator Myths and Facts about Contraception: What Women Need to Know",
    "description": "When it comes to contraception, there are many myths and misconceptions that can lead to confusion and misinformation. As a woman, it's essential to have accurate information about contraception options to make informed decisions about your sexual and reproductive health.",
    "image": "https://kangaroocareindia.com/static/media/blog-105.968076599d7bff02526e.png",  
    "author": {
      "@type": "Organization",
      "name": "Kangaroo Care",
      "url": "https://kangaroocareindia.com/"
    },  
    "publisher": {
      "@type": "Organization",
      "name": "Kangaroo Care",
      "logo": {
        "@type": "ImageObject",
        "url": "https://kangaroocareindia.com/images/kangaroo-care-new-logo.png"
      }
    },
    "datePublished": "2023-04-18"
  
  `,
  },
  {
    id: "106",
    schemaCode: `
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": "https://kangaroocareindia.com/blogs/what-are-the-benefits-of-antenatal-care/106"
    },
    "headline": "What are the benefits of antenatal care?",
    "description": "Pregnancy is a remarkable journey filled with anticipation and excitement. As an expecting mother, you naturally want to ensure the best possible outcome for you and your baby.",
    "image": "https://kangaroocareindia.com/static/media/blog-106.3c6f54e7bb1eab47a334.png",  
    "author": {
      "@type": "Organization",
      "name": "Kangaroo Care",
      "url": "https://kangaroocareindia.com/"
    },  
    "publisher": {
      "@type": "Organization",
      "name": "Kangaroo Care",
      "logo": {
        "@type": "ImageObject",
        "url": "https://kangaroocareindia.com/images/kangaroo-care-new-logo.png"
      }
    },
    "datePublished": "2023-05-05"
  
  `,
  },
  {
    id: "107",
    schemaCode: `
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": "https://kangaroocareindia.com/blogs/what-are-the-most-common-gynecological-conditions/107"
    },
    "headline": "What are the most common gynecological conditions?",
    "description": "At Kangaroo Care, we provide exceptional care and support to expectant mothers like you. Our team of experienced gynecologists and obstetricians is dedicated to your well-being, ensuring a smooth and memorable pregnancy journey.",
    "image": "https://kangaroocareindia.com/static/media/blog-107.80e45b65cdc27c3d2373.png",  
    "author": {
      "@type": "Organization",
      "name": "Kangaroo Care",
      "url": "https://kangaroocareindia.com/"
    },  
    "publisher": {
      "@type": "Organization",
      "name": "Kangaroo Care",
      "logo": {
        "@type": "ImageObject",
        "url": "https://kangaroocareindia.com/images/kangaroo-care-new-logo.png"
      }
    },
    "datePublished": "2023-05-05"
  
  `,
  },
  {
    id: "108",
    schemaCode: `
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": "https://kangaroocareindia.com/blogs/self-care-ideas-for-pregnant-moms/108"
    },
    "headline": "Self-Care Ideas for Pregnant Moms",
    "description": "As an expectant mother, caring for yourself is vital for a healthy pregnancy and overall well-being. At Kangaroo Care, we understand the importance ofself-care during pregnancy and want to provide you with valuable insights and ideas to support your journey.",
    "image": "https://kangaroocareindia.com/static/media/blog-108.98c5ff4e5c0a30e19bf2.png",  
    "author": {
      "@type": "Organization",
      "name": "Kangaroo Care",
      "url": "https://kangaroocareindia.com/"
    },  
    "publisher": {
      "@type": "Organization",
      "name": "Kangaroo Care",
      "logo": {
        "@type": "ImageObject",
        "url": "https://kangaroocareindia.com/images/kangaroo-care-new-logo.png"
      }
    },
    "datePublished": "2023-05-17"
  
  `,
  },
  {
    id: "109",
    schemaCode: `
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": "https://kangaroocareindia.com/blogs/tips-for-the-first-time-mom-on-breastfeeding/109"
    },
    "headline": "Tips for The First Time Mom on Breastfeeding",
    "description": "Congratulations on your journey into motherhood! As a first-time mom, you may have countless questions and concerns, especially regarding breastfeeding. Of course, it's an incredible and natural way to nourish and bond with your baby.",
    "image": "https://kangaroocareindia.com/static/media/blog-109.87b0a997b6c5a6a9055b.png",  
    "author": {
      "@type": "Organization",
      "name": "Kanagaroo Care",
      "url": "https://kangaroocareindia.com/"
    },  
    "publisher": {
      "@type": "Organization",
      "name": "Kanagaroo Care",
      "logo": {
        "@type": "ImageObject",
        "url": "https://kangaroocareindia.com/images/kangaroo-care-new-logo.png"
      }
    },
    "datePublished": "2023-08-17"
  
  `,
  },
  {
    id: "110",
    schemaCode: `
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": "https://kangaroocareindia.com/blogs/how-to-control-hormonal-imbalance-in-females-naturally/110"
    },
    "headline": "How to control hormonal imbalance in females naturally",
    "description": "Hormone imbalance in women is nothing but too much or too few hormones in their bodies, which causes mood swings. The hormones are called body messengers, making you happy and sad.",
    "image": "https://kangaroocareindia.com/static/media/blog-110.b0989a224c0bf51d2a9c.png",  
    "author": {
      "@type": "Organization",
      "name": "Kangaroo Care",
      "url": "https://kangaroocareindia.com/"
    },  
    "publisher": {
      "@type": "Organization",
      "name": "Kangaroo Care",
      "logo": {
        "@type": "ImageObject",
        "url": "https://kangaroocareindia.com/images/kangaroo-care-new-logo.png"
      }
    },
    "datePublished": "2023-06-02"
  
  `,
  },
  {
    id: "111",
    schemaCode: `
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": "https://kangaroocareindia.com/blogs/does-fibroids-affect-pregnancy-how-to-cure-fibroids/111"
    },
    "headline": "Does fibroids affect pregnancy? How to cure fibroids?",
    "description": "A study says that fibroids is a rare condition affecting 3% of pregnant women annually. However, fibroids has the chance of affecting female fertility and pregnancy. It cannot be treated during pregnancy as it may affect the fetus and mother.",
    "image": "https://kangaroocareindia.com/images/kangaroo-care-new-logo.png",  
    "author": {
      "@type": "Organization",
      "name": "Kangaroo Care",
      "url": "https://kangaroocareindia.com/"
    },  
    "publisher": {
      "@type": "Organization",
      "name": "Kangaroo Care",
      "logo": {
        "@type": "ImageObject",
        "url": "https://kangaroocareindia.com/images/kangaroo-care-new-logo.png"
      }
    },
    "datePublished": "2023-06-07"
  
  `,
  },
  {
    id: "112",
    schemaCode: `
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": "https://kangaroocareindia.com/blogs/do-menstrual-cycles-occur-during-pregnancy/112"
    },
    "headline": "Do menstrual cycles occur during pregnancy?",
    "description": "No, it is impossible to get periods once your pregnancy is confirmed. You may experience other kinds of bleeding like light spotting for a few days during the implantation period and so on. You may also get bleeding in case of miscarriage and ectopic pregnancy.",
    "image": "https://kangaroocareindia.com/static/media/blog-112.e643bac44fcd10610dbc.png",  
    "author": {
      "@type": "Organization",
      "name": "Kangaroo Care",
      "url": "https://kangaroocareindia.com/"
    },  
    "publisher": {
      "@type": "Organization",
      "name": "Kangaroo Care",
      "logo": {
        "@type": "ImageObject",
        "url": "https://kangaroocareindia.com/images/kangaroo-care-new-logo.png"
      }
    },
    "datePublished": "2023-06-18"
  
  `,
  },
  {
    id: "113",
    schemaCode: `
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": "https://kangaroocareindia.com/blogs/are-you-a-high-risk-pregnancy-case-here-are-the-symptoms/113"
    },
    "headline": "Are You a High-Risk Pregnancy Case? Here Are The Symptoms",
    "description": "A high-risk pregnancy is a pregnancy in which high risks are involved in the health of the pregnant woman, fetus, or both. Some health conditions of the mother make the pregnancy high-risk.",
    "image": "https://kangaroocareindia.com/static/media/blog-113.aa61c468060790e5d72f.png",  
    "author": {
      "@type": "Organization",
      "name": "Kangaroo Care",
      "url": "https://kangaroocareindia.com/"
    },  
    "publisher": {
      "@type": "Organization",
      "name": "Kangaroo Care",
      "logo": {
        "@type": "ImageObject",
        "url": "https://kangaroocareindia.com/images/kangaroo-care-new-logo.png"
      }
    },
    "datePublished": "2023-07-29"
  
  `,
  },
  {
    id: "114",
    schemaCode: `
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": "https://kangaroocareindia.com/blogs/how-to-get-twins-pregnancy/114"
    },
    "headline": "How To Get Twins Pregnancy",
    "description": "Research says that 1 in 250 pregnancies results in delivering twin babies. Many people who wish to conceive will love to have twin babies. Instead of praying for a twin pregnancy, one should understand the factors behind conceiving twin babies.",
    "image": "https://kangaroocareindia.com/static/media/blog-114.c786f3bfe31ed0b36167.jpg",  
    "author": {
      "@type": "Organization",
      "name": "Kangaroo Care",
      "url": "https://kangaroocareindia.com/"
    },  
    "publisher": {
      "@type": "Organization",
      "name": "Kangaroo Care",
      "logo": {
        "@type": "ImageObject",
        "url": "https://kangaroocareindia.com/images/kangaroo-care-new-logo.png"
      }
    },
    "datePublished": "2023-07-03"
  
  `,
  },
  {
    id: "116",
    schemaCode: `
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": "https://kangaroocareindia.com/blogs/stages-of-menopause/116"
    },
    "headline": "Stages of menopause",
    "description": "Menopause occurs in a part of ageing in women where they won’t get periods for 12 consecutive months. On average, women around 51 will reach menopause. There are 3 stages of menopause. They are perimenopause, menopause & postmenopause.",
    "image": "https://kangaroocareindia.com/static/media/blog-116.c75e3b24aeb4e1e5379b.jpg",  
    "author": {
      "@type": "Organization",
      "name": "Kangaroo Care",
      "url": "https://kangaroocareindia.com/"
    },  
    "publisher": {
      "@type": "Organization",
      "name": "Kangaroo Care",
      "logo": {
        "@type": "ImageObject",
        "url": "https://kangaroocareindia.com/images/kangaroo-care-new-logo.png"
      }
    },
    "datePublished": "2023-07-17"
  
  `,
  },
  {
    id: "117",
    schemaCode: `
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": "https://kangaroocareindia.com/blogs/how-to-treat-pcos-naturally-to-get-periods/117"
    },
    "headline": "How To Treat Pcos Naturally To Get Periods",
    "description": "PCOS has become the most common condition these days. It is found that 1 in 10 women are affected by the condition. Also, there is no complicated procedure involved in treating it.",
    "image": "https://kangaroocareindia.com/static/media/blog-117.1335651d496fa731203b.jpg",  
    "author": {
      "@type": "Organization",
      "name": "Kangaroo Care",
      "url": "https://kangaroocareindia.com/"
    },  
    "publisher": {
      "@type": "Organization",
      "name": "Kangaroo Care",
      "logo": {
        "@type": "ImageObject",
        "url": "https://kangaroocareindia.com/images/kangaroo-care-new-logo.png"
      }
    },
    "datePublished": "2023-07-26"
  
  `,
  },
  {
    id: "118",
    schemaCode: `
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": "https://kangaroocareindia.com/blogs/what-are-the-important-medicines-during-pregnancy/118"
    },
    "headline": "What are the important medicines during pregnancy?",
    "description": "Pregnancy is a transformative and joyous phase in a woman's life. During this period, expectant mothers require special care and attention, especially concerning their health and well-being.",
    "image": "https://kangaroocareindia.com/static/media/blog-118.6a0e6ddcf7860e742e88.png",  
    "author": {
      "@type": "Organization",
      "name": "Kangaroo Care",
      "url": "https://kangaroocareindia.com/"
    },  
    "publisher": {
      "@type": "Organization",
      "name": "Kangaroo Care",
      "logo": {
        "@type": "ImageObject",
        "url": "https://kangaroocareindia.com/images/kangaroo-care-new-logo.png"
      }
    },
    "datePublished": ""
  
  `,
  },
  {
    id: "119",
    schemaCode: `
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": "https://kangaroocareindia.com/blogs/how-do-i-manage-pcod-and-conceive-naturally/119"
    },
    "headline": "How do I manage PCOD and conceive naturally?",
    "description": "Polycystic Ovary Disease (PCOD) is a common hormonal disorder affecting women of reproductive age. It can disrupt menstrual cycles, lead to infertility, and cause other health complications.",
    "image": "https://kangaroocareindia.com/static/media/blog-119.502cdb7c33fb28f89e0a.png",  
    "author": {
      "@type": "Organization",
      "name": "Kangaroo Care",
      "url": "https://kangaroocareindia.com"
    },  
    "publisher": {
      "@type": "Organization",
      "name": "Kangaroo Care",
      "logo": {
        "@type": "ImageObject",
        "url": "https://kangaroocareindia.com/images/kangaroo-care-new-logo.png"
      }
    },
    "datePublished": "2023-08-11"
  
  `,
  },
  {
    id: "120",
    schemaCode: `
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": "https://kangaroocareindia.com/blogs/what-are-the-essential-items-to-buy-for-a-newborn-baby/120"
    },
    "headline": "What are the essential items to buy for a newborn baby?",
    "description": "Welcoming a newborn baby into your life is a joyous occasion, but it also comes with great preparation. As a new parent, ensuring you have all the essential items ready for your little bundle of joy is paramount.",
    "image": "https://kangaroocareindia.com/static/media/blog-120.7629f9879cab648cc6a4.png",  
    "author": {
      "@type": "Organization",
      "name": "Kangaroo Care",
      "url": "https://kangaroocareindia.com/"
    },  
    "publisher": {
      "@type": "Organization",
      "name": "Kangaroo Care",
      "logo": {
        "@type": "ImageObject",
        "url": "https://kangaroocareindia.com/images/kangaroo-care-new-logo.png"
      }
    },
    "datePublished": "2023-08-17"
  
  `,
  },
  {
    id: "121",
    schemaCode: `
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": "https://kangaroocareindia.com/blogs/tips-for-children-during-the-rainy-seasons/121"
    },
    "headline": "Tips for Children During the Rainy Seasons",
    "description": "Rainy season is a time of joy and rejuvenation. But it also comes with its share of challenges, especially for the 'little ones'. While they love to jump in puddles and splash water droplets on their faces, they need to be constantly monitored to ensure they are safe and well.",
    "image": "https://kangaroocareindia.com/static/media/blog-121.8e0b118f42e8cca01a01.png",  
    "author": {
      "@type": "Organization",
      "name": "Kangaroo Care",
      "url": "https://kangaroocareindia.com/"
    },  
    "publisher": {
      "@type": "Organization",
      "name": "Kangaroo Care",
      "logo": {
        "@type": "ImageObject",
        "url": "https://kangaroocareindia.com/images/kangaroo-care-new-logo.png"
      }
    },
    "datePublished": ""
  
  `,
  },
  {
    id: "125",
    schemaCode: `
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": "https://kangaroocareindia.com/blogs/stages-of-fetal-development/125"
    },
    "headline": "Stages of Fetal Development",
    "description": "The journey from conception to birth is a miraculous process marked by intricate stages of fetal development. From a single cell to a fully formed baby, the journey is a testament to the wonders of life. This blog will take you through the remarkable stages of fetal development, shedding light on the incredible transformations within the womb.",
    "image": "https://kangaroocareindia.com/static/media/blog-125.73dbb08eb1e898866b14.png",  
    "author": {
      "@type": "Organization",
      "name": "kangaroo care",
      "url": "https://kangaroocareindia.com/"
    },  
    "publisher": {
      "@type": "Organization",
      "name": "kangaroo care",
      "logo": {
        "@type": "ImageObject",
        "url": "https://kangaroocareindia.com/"
      }
    },
    "datePublished": "2023-09-22",
    "dateModified": "2023-09-22"
  
    `,
  },
  {
    id: "124",
    schemaCode: `
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": "https://kangaroocareindia.com/blogs/why-is-prenatal-care-important-for-the-baby/124"
    },
    "headline": "why is prenatal care important for the baby",
    "description": "Prenatal care is paramount for the mother's and developing baby's health and well-being. It involves medical check-ups, screenings, and guidance provided by healthcare professionals, typically gynecologists or obstetricians, throughout the pregnancy journey. Here's why prenatal care is crucial for the baby's health:",
    "image": "https://kangaroocareindia.com/static/media/blog-124.f4ec76213fd9de12acb2.png",  
    "author": {
      "@type": "Organization",
      "name": "Kangaroo care",
      "url": "https://kangaroocareindia.com/"
    },  
    "publisher": {
      "@type": "Organization",
      "name": "kangaroo care",
      "logo": {
        "@type": "ImageObject",
        "url": "https://kangaroocareindia.com/"
      }
    },
    "datePublished": "2023-09-16",
    "dateModified": "2023-09-16"
  
    `,
  },
  {
    id: "123",
    schemaCode: `
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": "https://kangaroocareindia.com/blogs/healthy-eating-during-pregnancy/123"
    },
    "headline": "Healthy Eating During Pregnancy",
    "description": "Pregnancy is a transformative journey marked by profound physical and emotional changes. One of the most critical aspects of this journey is maintaining a healthy diet that supports both your well-being and the optimal development of your baby. This blog will delve into the significance of healthy eating during pregnancy, providing valuable insights and tips to help you make informed dietary choices for a nourishing and joyful pregnancy experience.",
    "image": "https://kangaroocareindia.com/static/media/blog-123.617dab9c89a81e98d138.png",  
    "author": {
      "@type": "Organization",
      "name": "kangaroo care",
      "url": "https://kangaroocareindia.com/"
    },  
    "publisher": {
      "@type": "Organization",
      "name": "kangaroo care",
      "logo": {
        "@type": "ImageObject",
        "url": "https://kangaroocareindia.com/"
      }
    },
    "datePublished": "2023-09-09",
    "dateModified": "2023-09-09"
  
    `,
  },
  {
    id: "122",
    schemaCode: `
    
  "@context": "https://schema.org",
  "@type": "BlogPosting",
  "mainEntityOfPage": {
    "@type": "WebPage",
    "@id": "https://kangaroocareindia.com/blogs/what-are-the-care-needs-of-a-child/122"
  },
  "headline": "What are the care needs of a child",
  "description": "Parenting is a rewarding journey filled with love, joy, and responsibilities. As children grow and develop, their care needs evolve, requiring caregivers to adapt and provide appropriate support. This blog will explore the essential care needs of a child from infancy through adolescence, offering insights into the physical, emotional, and developmental aspects that contribute to nurturing a healthy and happy child.",
  "image": "https://kangaroocareindia.com/static/media/blog-122.820c0aeab61939b6d5d7.png",  
  "author": {
    "@type": "Organization",
    "name": "Kangaroo care",
    "url": "https://kangaroocareindia.com/"
  },  
  "publisher": {
    "@type": "Organization",
    "name": "Kangaroo care",
    "logo": {
      "@type": "ImageObject",
      "url": "https://kangaroocareindia.com/"
    }
  },
  "datePublished": "2023-09-06",
  "dateModified": "2023-09-06"

    `,
  },

  {
    id: "126",
    schemaCode: `
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": "https://kangaroocareindia.com/blogs/pregnancy-workouts-at-home/126"
    },
    "headline": "Pregnancy Workouts at Home",
    "description": "Staying active throughout pregnancy can help to ensure a healthier, more comfortable pregnancy. Pregnancy is a magnificent adventure.",
    "image": "https://kangaroocareindia.com/static/media/blog-126.5f439c5465d6f6bdec4f.png",  
    "author": {
      "@type": "Organization",
      "name": "Kangaroo Care",
      "url": "https://kangaroocareindia.com/"
    },  
    "publisher": {
      "@type": "Organization",
      "name": "Kangaroo Care",
      "logo": {
        "@type": "ImageObject",
        "url": "https://kangaroocareindia.com/images/kangaroo-care-new-logo.png"
      }
    },
    "datePublished": "2023-10-06"
    `,
  },
  {
    id: "127",
    schemaCode: `
    
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": "https://kangaroocareindia.com/blogs/how-to-reduce-labour-pain-naturally/127"
    },
    "headline": "How to Reduce Labour Pain Naturally",
    "description": "Although giving birth to a child is a beautiful and life-changing experience, many pregnant moms worry about the discomfort of labor.",
    "image": "https://kangaroocareindia.com/static/media/blog-127.690f15b2eaedf888ef55.png",  
    "author": {
      "@type": "Organization",
      "name": "Kangaroo Care",
      "url": "https://kangaroocareindia.com/"
    },  
    "publisher": {
      "@type": "Organization",
      "name": "Kangaroo Care",
      "logo": {
        "@type": "ImageObject",
        "url": "https://kangaroocareindia.com/images/kangaroo-care-new-logo.png"
      }
    },
    "datePublished": "2023-10-10"
    `,
  },
  {
    id: "128",
    schemaCode: `
    
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": "https://kangaroocareindia.com/blogs/things-to-avoid-saying-to-a-woman-in-labor/128"
    },
    "headline": "Things to Avoid Saying to a Woman in Labor",
    "description": "For women, the experience of giving birth is powerful, transformational, and characterized by intense feelings and physical experiences.",
    "image": "https://kangaroocareindia.com/static/media/blog-128.2275b5a2e7788100b2bf.png",  
    "author": {
      "@type": "Organization",
      "name": "Kangaroo Care",
      "url": "https://kangaroocareindia.com/"
    },  
    "publisher": {
      "@type": "Organization",
      "name": "Kangaroo Care",
      "logo": {
        "@type": "ImageObject",
        "url": "https://kangaroocareindia.com/images/kangaroo-care-new-logo.png"
      }
    },
    "datePublished": "2023-10-17"
    `,
  },
  {
    id: "129",
    schemaCode: `
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": "https://kangaroocareindia.com/blogs/things-to-avoid-saying-to-a-woman-in-labor/129"
    },
    "headline": "How To Prevent Your Child From Falling Sick This Winter",
    "description": "As the winter season approaches, parents and caregivers often face the challenge of ensuring their children stay warm, safe, and healthy.",
    "image": "https://kangaroocareindia.com/static/media/blog-129.0b311be1fe869ca6f827.jpeg",  
    "author": {
      "@type": "Organization",
      "name": "Kangaroo Care",
      "url": "https://kangaroocareindia.com/"
    },  
    "publisher": {
      "@type": "Organization",
      "name": "Kangaroo Care",
      "logo": {
        "@type": "ImageObject",
        "url": "https://kangaroocareindia.com/images/kangaroo-care-new-logo.png"
      }
    },
    "datePublished": "2023-10-25"
    `,
  },
  {
    id: "115",
    schemaCode: `
      "@context": "https://schema.org",
  "@type": "BlogPosting",
  "mainEntityOfPage": {
    "@type": "WebPage",
    "@id": "https://kangaroocareindia.com/blogs/how-to-take-care-in-4th-month-of-pregnancy/115"
  },
  "headline": "How To Take Care In 4th Month Of Pregnancy",
  "description": "Embark on a journey of well-being in the 4th month of pregnancy. Kangaroo Care India's blog offers indispensable guidance for expectant mothers.",
  "image": "https://kangaroocareindia.com/static/media/blog-115.0ab92da08924aa6fdec4.jpg",  
  "author": {
    "@type": "Organization",
    "name": "Kangaroo Care",
    "url": "https://kangaroocareindia.com/"
  },  
  "publisher": {
    "@type": "Organization",
    "name": "Kangaroo Care",
    "logo": {
      "@type": "ImageObject",
      "url": "https://kangaroocareindia.com/images/kangaroo-care-new-logo.png"
    }
  },
  "datePublished": "2023-07-09"
  `,
  },
  {
    id: "114",
    schemaCode: `
    "@context": "https://schema.org",
  "@type": "BlogPosting",
  "mainEntityOfPage": {
    "@type": "WebPage",
    "@id": "https://kangaroocareindia.com/blogs/how-to-get-twins-pregnancy/114"
  },
  "headline": "How To Get Twins Pregnancy",
  "description": "Empower your twin pregnancy with Kangaroo Care India's essential guide. Learn proven strategies for a smooth and joyful experience. Dive in now!",
  "image": "https://kangaroocareindia.com/static/media/blog-114.c786f3bfe31ed0b36167.jpg",  
  "author": {
    "@type": "Organization",
    "name": "Kangaroo Care",
    "url": "https://kangaroocareindia.com/"
  },  
  "publisher": {
    "@type": "Organization",
    "name": "Kangaroo Care",
    "logo": {
      "@type": "ImageObject",
      "url": "https://kangaroocareindia.com/images/kangaroo-care-new-logo.png"
    }
  },
  "datePublished": "2023-07-03"
  `,
  },
  {
    id: "130",
    schemaCode: `
    "@context": "https://schema.org",
  "@type": "BlogPosting",
  "mainEntityOfPage": {
    "@type": "WebPage",
    "@id": "https://kangaroocareindia.com/blogs/navigating-menopause-in-marriage:--tips-for-a-harmonious-journey/130"
  },
  "headline": "Navigating Menopause In Marriage: Tips For A Harmonious Journey",
  "description": "Menopause is a significant life transition that can bring about both physical and emotional changes for women.",
  "image": "https://kangaroocareindia.com/static/media/blog-130.19f28e65c09597d0ff51.jpg",  
  "author": {
    "@type": "Organization",
    "name": "Kangaroo Care",
    "url": "https://kangaroocareindia.com/"
  },  
  "publisher": {
    "@type": "Organization",
    "name": "https://kangaroocareindia.com/",
    "logo": {
      "@type": "ImageObject",
      "url": "https://kangaroocareindia.com/images/kangaroo-care-new-logo.png"
    }
  },
  "datePublished": "2023-11-07"
  `,
  },
  {
    id: "131",
    schemaCode: `
      "@context": "https://schema.org",
  "@type": "BlogPosting",
  "mainEntityOfPage": {
    "@type": "WebPage",
    "@id": "https://kangaroocareindia.com/blogs/navigating-pregnancy-understanding-the-trimesters-week-by-week/131"
  },
  "headline": "Navigating Pregnancy: Understanding the Trimesters Week by Week",
  "description": "Embarking on the pregnancy journey is a thrilling and transformative experience for expectant parents.",
  "image": "https://kangaroocareindia.com/static/media/blog-131.799083386662dcffc764.jpg",  
  "author": {
    "@type": "Organization",
    "name": "Kangaroo Care",
    "url": "https://kangaroocareindia.com/"
  },  
  "publisher": {
    "@type": "Organization",
    "name": "https://kangaroocareindia.com/",
    "logo": {
      "@type": "ImageObject",
      "url": "https://kangaroocareindia.com/images/kangaroo-care-new-logo.png"
    }
  },
  "datePublished": "2023-11-13"
  `,
  },
  {
    id: "132",
    schemaCode: `
     "@context": "https://schema.org",
  "@type": "BlogPosting",
  "mainEntityOfPage": {
    "@type": "WebPage",
    "@id": "https://kangaroocareindia.com/blogs/unveiling-solutions-common-treatment-options-for-pcos/132"
  },
  "headline": "Unveiling Solutions: Common Treatment Options For PCOS",
  "description": "Polycystic Ovary Syndrome (PCOS) is a common hormonal disorder that affects individuals during their reproductive years.",
  "image": "https://kangaroocareindia.com/static/media/blog-132.804d1ac92764054880c4.jpg",  
  "author": {
    "@type": "Organization",
    "name": "Kangaroo Care",
    "url": "https://kangaroocareindia.com/"
  },  
  "publisher": {
    "@type": "Organization",
    "name": "https://kangaroocareindia.com/",
    "logo": {
      "@type": "ImageObject",
      "url": "https://kangaroocareindia.com/images/kangaroo-care-new-logo.png"
    }
  },
  "datePublished": "2023-11-22"
  `,
  },
  {
    id: "133",
    schemaCode: `
    "@context": "https://schema.org",
  "@type": "BlogPosting",
  "mainEntityOfPage": {
    "@type": "WebPage",
    "@id": "https://kangaroocareindia.com/blogs/nurturing-life-foods-to-avoid-during-pregnancy/133"
  },
  "headline": "Nurturing Life: Foods to Avoid During Pregnancy",
  "description": "Pregnancy is an adorable journey when you must be extra conscious about your lifestyle. A healthy diet plays a crucial role in following a healthy lifestyle.",
  "image": "https://kangaroocareindia.com/static/media/blog-133.80b80f83967fa97369a0.jpg",  
  "author": {
    "@type": "Organization",
    "name": "Kangaroo Care",
    "url": "https://kangaroocareindia.com/"
  },  
  "publisher": {
    "@type": "Organization",
    "name": "https://kangaroocareindia.com/",
    "logo": {
      "@type": "ImageObject",
      "url": "https://kangaroocareindia.com/images/kangaroo-care-new-logo.png"
    }
  },
  "datePublished": "2023-11-25"
  `,
  },
  {
    id: "134",
    schemaCode: `
     "@context": "https://schema.org",
      "@type": "BlogPosting",
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": "https://kangaroocareindia.com/blogs/what-are-the-symptoms-of-the-1st-week-of-pregnancy/134"
      },
      "headline": " What are the symptoms of the 1st week of pregnancy? ",
      "description": "Understanding pregnancy week one: Explore symptoms and changes. Empower yourself with knowledge for a confident and healthy beginning | Kangaroocare.",
      "image": "https://kangaroocareindia.com/static/media/blog-134.74e46bbb1be50642a16e.jpg",  
      "author": {
        "@type": "Organization",
        "name": "Kangaroo Care ",
        "url": "https://kangaroocareindia.com//"
      },  
      "publisher": {
        "@type": "Organization",
        "name": "Kangaroo Care ",
        "logo": {
          "@type": "ImageObject",
          "url": "https://kangaroocareindia.com/images/kangaroo-care-new-logo.png"
        }
      },
      "datePublished": "2023-12-06"
  `,
  },
  {
    id: "135",
    schemaCode: `
     "@context": "https://schema.org",
      "@type": "BlogPosting",
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": "https://kangaroocareindia.com/blogs/what-can-cause-a-miscarriage-in-early-pregnancy/135"
      },
      "headline": " What can cause a miscarriage in early pregnancy? ",
      "description": "Miscarriage in early pregnancy. From common causes to preventive steps, empower yourself with essential information for a successful pregnancy.",
      "image": "https://kangaroocareindia.com/static/media/blog-135.84932fcffd8c6c7b6722.jpg",  
      "author": {
        "@type": "Organization",
        "name": "Kangaroo Care ",
        "url": "https://kangaroocareindia.com//"
      },  
      "publisher": {
        "@type": "Organization",
        "name": "Kangaroo Care ",
        "logo": {
          "@type": "ImageObject",
          "url": "https://kangaroocareindia.com/images/kangaroo-care-new-logo.png"
        }
      },
      "datePublished": "2023-12-11"
  `,
  },
  {
    id: "136",
    schemaCode: `
    "@context": "https://schema.org",
      "@type": "BlogPosting",
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": "https://kangaroocareindia.com/blogs/choosing-the-right-gynecologist-a-comprehensive-guide/136"
      },
      "headline": " Choosing the Right Gynecologist: A Comprehensive Guide ",
      "description": "Discover the ultimate guide to choosing the perfect gynecologist. Your comprehensive resource for informed decisions on women's health | Kangaroo Care.",
      "image": "https://kangaroocareindia.com/static/media/blog-136.93e7e9d28998ce261cad.jpg",  
      "author": {
        "@type": "Organization",
        "name": "Kangaroo Care ",
        "url": "https://kangaroocareindia.com//"
      },  
      "publisher": {
        "@type": "Organization",
        "name": "Kangaroo Care ",
        "logo": {
          "@type": "ImageObject",
          "url": "https://kangaroocareindia.com/images/kangaroo-care-new-logo.png"
        }
      },
      "datePublished": "2023-12-20"
  `,
  },
  {
    id: "137",
    schemaCode: `
    "@context": "https://schema.org",
      "@type": "BlogPosting",
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": "https://kangaroocareindia.com/blogs/advantages-of-antenatal-care-kangaroo-care-hospital/137"
      },
      "headline": "Advantages of antenatal care - kangaroo care hospital ",
      "description": "Discover the benefits of antenatal care at Kangaroo Care Hospital. Nurture a healthy pregnancy with our expert guidance and support.",
      "image": "https://kangaroocareindia.com/static/media/blog-137.d3189781558be138b5de.jpg",  
      "author": {
        "@type": "Organization",
        "name": "Kangaroo Care ",
        "url": "https://kangaroocareindia.com//"
      },  
      "publisher": {
        "@type": "Organization",
        "name": "Kangaroo Care ",
        "logo": {
          "@type": "ImageObject",
          "url": "https://kangaroocareindia.com/images/kangaroo-care-new-logo.png"
        }
      },
      "datePublished": "2024-12-26"
  `,
  },
  {
    id: "138",
    schemaCode: `
    "@context": "https://schema.org",
      "@type": "BlogPosting",
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": "https://kangaroocareindia.com/blogs/early-stages-of-pregnancy-for-a-healthy-journey/138"
      },
      "headline": " Nurturing Wellness: Early Pregnancy Tips for a Healthy Journey ",
      "description": "Embark on a healthy pregnancy with expert tips! Explore nurturing wellness practices for a vibrant early pregnancy journey. Your well-being matters!.",
      "image": "https://kangaroocareindia.com/static/media/blog-138.f660860c5112caffd4a5.jpg",  
      "author": {
        "@type": "Organization",
        "name": "Kangaroo Care ",
        "url": "https://kangaroocareindia.com//"
      },  
      "publisher": {
        "@type": "Organization",
        "name": "Kangaroo Care ",
        "logo": {
          "@type": "ImageObject",
          "url": "https://kangaroocareindia.com/images/kangaroo-care-new-logo.png"
        }
      },
      "datePublished": "2024-01-25"
  `,
  },
  {
    id: "139",
    schemaCode: `
    "@context": "https://schema.org",
      "@type": "BlogPosting",
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": "https://kangaroocareindia.com/blogs/irregular-periods-understanding-the-causes-and-solutions/139"
      },
      "headline": " Irregular Periods: Understanding the Causes and Solutions ",
      "description": "Unlock the mysteries of irregular periods! Explore causes and effective solutions for a balanced menstrual cycle. Take control of your health today..",
      "image": "https://kangaroocareindia.com/static/media/blog-139.381e22b6581716523cb6.jpg",  
      "author": {
        "@type": "Organization",
        "name": "Kangaroo Care ",
        "url": "https://kangaroocareindia.com//"
      },  
      "publisher": {
        "@type": "Organization",
        "name": "Kangaroo Care ",
        "logo": {
          "@type": "ImageObject",
          "url": "https://kangaroocareindia.com/images/kangaroo-care-new-logo.png"
        }
      },
      "datePublished": "2024-01-25"
  `,
  },
  {
    id: "140",
    schemaCode: `
    "@context": "https://schema.org",
      "@type": "BlogPosting",
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": "https://kangaroocareindia.com/blogs/foods-to-eat-while-pregnant-a-nutrient-rich-guide-for-expecting-moms/140"
      },
      "headline": " Nutrient-Rich Pregnancy Foods: A Guide for Expecting Moms ",
      "description": "Unveil the secrets of a healthy pregnancy with our nutrient-rich foods guide. Essential tips for expecting moms to nurture both mom and baby..",
      "image": "https://kangaroocareindia.com/static/media/blog-140.672070b1a1792495c1e9.jpg",  
      "author": {
        "@type": "Organization",
        "name": "Kangaroo Care ",
        "url": "https://kangaroocareindia.com//"
      },  
      "publisher": {
        "@type": "Organization",
        "name": "Kangaroo Care ",
        "logo": {
          "@type": "ImageObject",
          "url": "https://kangaroocareindia.com/images/kangaroo-care-new-logo.png"
        }
      },
      "datePublished": "2024-01-25"
  `,
  },
  {
    id: "141",
    schemaCode: `
    "@context": "https://schema.org",
      "@type": "BlogPosting",
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": "https://kangaroocareindia.com/blogs/fever-during-pregnancy-causes-symptoms-and-management/141"
      },
      "headline": " Fever During Pregnancy: Causes, Symptoms, and Management ",
      "description": "Discover causes, symptoms, and effective management of fever during pregnancy. Your guide to a healthy and safe journey for both mom and baby..",
      "image": "https://kangaroocareindia.com/static/media/blog-141.360bf95831538fdc7d31.jpg",  
      "author": {
        "@type": "Organization",
        "name": "Kangaroo Care ",
        "url": "https://kangaroocareindia.com//"
      },  
      "publisher": {
        "@type": "Organization",
        "name": "Kangaroo Care ",
        "logo": {
          "@type": "ImageObject",
          "url": "https://kangaroocareindia.com/images/kangaroo-care-new-logo.png"
        }
      },
      "datePublished": "2024-01-25"
  `,
  },
  {
    id: "142",
    schemaCode: `
      "@context": "https://schema.org",
  "@type": "BlogPosting",
  "mainEntityOfPage": {
    "@type": "WebPage",
    "@id": "https://kangaroocareindia.com/blogs/the-benefits-of-exercise-during-pregnancy/142"
  },
  "headline": "The Benefits Of Exercise During Pregnancy",
  "description": "The Benefits of Exercise During Pregnancy
Pregnancy is a transformative journey filled with anticipation and joy, but it comes with its share of physical changes and challenges. Recently, there has been a growing emphasis on the importance of exercise during pregnancy to promote overall well-being for both mother and baby. While exercising during pregnancy may seem daunting to some, understanding its benefits and following safety guidelines can make it a rewarding and empowering experience.",
  "image": "https://kangaroocareindia.com/static/media/blog-142.e731240667334fe424d1.jpg",  
  "author": {
    "@type": "Organization",
    "name": "Kangaroo care",
    "url": "https://kangaroocareindia.com/"
  },  
  "publisher": {
    "@type": "Organization",
    "name": "Kangaroo care",
    "logo": {
      "@type": "ImageObject",
      "url": "https://kangaroocareindia.com/"
    }
  },
  "datePublished": "2024-02-25"
  `,
  },
  {
    id: "143",
    schemaCode: `
    "@context": "https://schema.org",
  "@type": "BlogPosting",
  "mainEntityOfPage": {
    "@type": "WebPage",
    "@id": "https://kangaroocareindia.com/blogs/second-baby-planning-key-considerations/143"
  },
  "headline": "Second Baby Planning: Key Considerations",
  "description": "Welcoming a second baby into the family is an exciting and joyous occasion, but it also comes with its considerations and challenges. Whether you're already a parent or preparing for your second pregnancy journey, it's essential to understand the unique aspects of this experience and how to navigate them effectively. From managing second pregnancy symptoms to planning for the arrival of your new addition, careful preparation and thoughtful consideration can help ensure a smooth transition for your growing family.


Understanding Second Pregnancy

Every pregnancy is unique, and this holds for second pregnancies as well. While some aspects may feel familiar from your first experience, there are also differences. Physically, the body may respond differently to the hormonal changes and demands of pregnancy, and emotionally, you may find yourself balancing the needs of your growing family while preparing for a new arrival. Understanding these differences can help you approach your second pregnancy with confidence and clarity.",
  "image": "https://kangaroocareindia.com/static/media/blog-143.fbdc7c9bad39df36d4c2.jpg",  
  "author": {
    "@type": "Organization",
    "name": "Kangaroo care",
    "url": "https://kangaroocareindia.com/"
  },  
  "publisher": {
    "@type": "Organization",
    "name": "Kangaroo care",
    "logo": {
      "@type": "ImageObject",
      "url": "https://kangaroocareindia.com/images/kangaroo-care-new-logo.png"
    }
  },
  "datePublished": "2024-03-25"
  `,
  },
  {
    id: "144",
    schemaCode: `
    "@context": "https://schema.org",
  "@type": "BlogPosting",
  "mainEntityOfPage": {
    "@type": "WebPage",
    "@id": "https://kangaroocareindia.com/blogs/common-dental-problems-in-children-prevention-and-treatment/144"
  },
  "headline": "Common Dental Problems in Children: Prevention and Treatment",
  "description": "The oral health of a child significantly determines their well-being and growth. Though some parents need to realize the significant contribution of oral hygiene in children's early childhood and give less importance to the topic, such a lack of attention might result in dental issues in the future. The blog will describe regular pediatric dental conditions, how to prevent them, and treatment options to maintain a healthy smile for your child.


Awareness about the Most Basic Dental Issues in Children is of Paramount Importance",
  "image": "https://kangaroocareindia.com/static/media/blog-144.7102998fc2b8a8715785.jpg",  
  "author": {
    "@type": "Organization",
    "name": "Kangaroo Care",
    "url": "https://kangaroocareindia.com/"
  },  
  "publisher": {
    "@type": "Organization",
    "name": "Kangaroo Care",
    "logo": {
      "@type": "ImageObject",
      "url": "https://kangaroocareindia.com/images/kangaroo-care-new-logo.png"
    }
  },
  "datePublished": "2024-02-25"
  `,
  },
  {
    id: "145",
    schemaCode: `
    "@context": "https://schema.org",
  "@type": "BlogPosting",
  "mainEntityOfPage": {
    "@type": "WebPage",
    "@id": "https://kangaroocareindia.com/blogs/step-by-step-feeding-your-baby-for-the-first-time/145"
  },
  "headline": "Step-by-Step: Feeding Your Baby for the First Time",
  "description": "Beginning to give solid foods to your baby is an adorable milestone in their development journey. As a parent, it's natural to feel a mix of emotions—from anticipation to uncertainty—about this significant transition. Understanding how to navigate this process confidently and with care is essential for you and your little one. In this comprehensive guide, we'll explore everything you need to know about feeding your baby for the first time, from baby providing to step-by-step instructions and helpful tips.


Understanding Baby Feeding


Before diving into the practical aspects of feeding your baby solid foods, it's crucial to have a solid understanding of the basics. Baby feeding involves more than just nourishment; it's fundamental to bonding, communication, and early nutrition development. Babies typically begin showing signs of readiness for solid foods around 4 to 6 months. Still, every child is unique, so it's essential to observe your baby's cues and consult with your pediatrician before beginning the weaning process. As you embark on this journey, remember that patience, persistence, and a positive attitude are critical ingredients for success.",
  "image": "https://kangaroocareindia.com/static/media/blog-145.3a1923b8a1d94b68d662.jpg",  
  "author": {
    "@type": "Organization",
    "name": "Kangaroo Care",
    "url": "https://kangaroocareindia.com/"
  },  
  "publisher": {
    "@type": "Organization",
    "name": "Kangaroo Care",
    "logo": {
      "@type": "ImageObject",
      "url": "https://kangaroocareindia.com/images/kangaroo-care-new-logo.png"
    }
  },
  "datePublished": "2024-02-25"
  `,
  },
  {
    id: "146",
    schemaCode: `
     "@context": "https://schema.org",
  "@type": "BlogPosting",
  "mainEntityOfPage": {
    "@type": "WebPage",
    "@id": "https://kangaroocareindia.com/blogs/understanding-the-importance-of-growth-scans-during-pregnancy/146"
  },
  "headline": "Understanding the Importance of Growth Scans During Pregnancy",
  "description": "Various medical tests and scans are conducted during pregnancy to monitor the mother's and baby's health and development. One such essential tool is the growth scan, which provides crucial information about the baby's growth and well-being in the womb. This blog will delve into the importance of growth scans during pregnancy, their significance, types, and what to expect from these scans.


Understanding the Importance of Growth Scans During Pregnancy


Growth scans play a pivotal role in ensuring the healthy progression of pregnancy by assessing the baby's growth, size, and overall development in the womb. These scans help healthcare providers identify potential issues or abnormalities early on, allowing for timely intervention and management if necessary. Additionally, growth scans provide expectant parents valuable insights into their baby's health and offer reassurance about their pregnancy journey.",
  "image": "https://kangaroocareindia.com/static/media/blog-146.e24be631c6f3b5e9f6a8.jpg",  
  "author": {
    "@type": "Organization",
    "name": "Kangaroo Care",
    "url": "https://kangaroocareindia.com/"
  },  
  "publisher": {
    "@type": "Organization",
    "name": "Kangaroo Care",
    "logo": {
      "@type": "ImageObject",
      "url": "https://kangaroocareindia.com/images/kangaroo-care-new-logo.png"
    }
  },
  "datePublished": "2024-02-25"
  `,
  },
  {
    id: "147",
    schemaCode: `
      "@context": "https://schema.org",
  "@type": "BlogPosting",
  "mainEntityOfPage": {
    "@type": "WebPage",
    "@id": "https://kangaroocareindia.com/blogs/diabetes-in-pregnancy-medication-and-treatment-options/147"
  },
  "headline": "Diabetes in Pregnancy: Medication and Treatment Options",
  "description": "The journey of pregnancy is incredibly life-changing. It is a stage of life that includes expectations and unexpected health challenges. Due to this, some expectant mothers have to grapple with gestational diabetes.",
  "image": "https://kangaroocareindia.com/static/media/blog-147.23df4f0191e7f867fa07.jpg",  
  "author": {
    "@type": "Organization",
    "name": "Kangaroo Care",
    "url": "https://kangaroocareindia.com/"
  },  
  "publisher": {
    "@type": "Organization",
    "name": "Kangaroo Care",
    "logo": {
      "@type": "ImageObject",
      "url": "https://kangaroocareindia.com/images/kangaroo-care-new-logo.png"
    }
  },
  "datePublished": "2024-03-18"

  `,
  },
  {
    id: "148",
    schemaCode: `
      "@context": "https://schema.org",
  "@type": "BlogPosting",
  "mainEntityOfPage": {
    "@type": "WebPage",
    "@id": "https://kangaroocareindia.com/blogs/increase-breast-milk-lactation-consultant-tips/148"
  },
  "headline": "Increase breast milk: Lactation consultant tips",
  "description": "Breastfeeding a baby is the best that can be done during motherhood and in the baby’s interest because it has many significant advantages for both. On the other hand, the mother may encounter problems such as low breast milk production volume, raising concerns about their infant’s ability to feed adequately.",
  "image": "https://kangaroocareindia.com/static/media/blog-148.aa79854e92c2b0003f30.jpg",  
  "author": {
    "@type": "Organization",
    "name": "Kangaroo Care",
    "url": "https://kangaroocareindia.com/"
  },  
  "publisher": {
    "@type": "Organization",
    "name": "Kangaroo Care",
    "logo": {
      "@type": "ImageObject",
      "url": "https://kangaroocareindia.com/images/kangaroo-care-new-logo.png"
    }
  },
  "datePublished": "2024-03-18"

  `,
  },
  {
    id: "149",
    schemaCode: `
      "@context": "https://schema.org",
  "@type": "BlogPosting",
  "mainEntityOfPage": {
    "@type": "WebPage",
    "@id": "https://kangaroocareindia.com/blogs/signs-of-fever-in-infants-and-children-what-parents-need-to-know/149"
  },
  "headline": "Signs of Fever in Infants and Children: What Parents Need to Know",
  "description": "As a parent, your child's fever can be troubling and stressful. Identifying and treating fever in infants and children involves fundamental health care. Through this brief guide, we aim to give parents important information about recognizing, understanding, and managing elevated temperatures during infancy.",
  "image": "https://kangaroocareindia.com/static/media/blog-149.41d434dd5bd9a0db695c.jpg",  
  "author": {
    "@type": "Organization",
    "name": "Kangaroo Care",
    "url": "https://kangaroocareindia.com/"
  },  
  "publisher": {
    "@type": "Organization",
    "name": "Kangaroo Care",
    "logo": {
      "@type": "ImageObject",
      "url": "https://kangaroocareindia.com/images/kangaroo-care-new-logo.png"
    }
  },
  "datePublished": "2024-03-18"

  `,
  },
  {
    id: "150",
    schemaCode: `
      "@context": "https://schema.org",
  "@type": "BlogPosting",
  "mainEntityOfPage": {
    "@type": "WebPage",
    "@id": "https://kangaroocareindia.com/blogs/neonatal-care-at-home-essential-tips-for-new-parents/150"
  },
  "headline": "Neonatal Care at Home: Essential Tips for New Parents",
  "description": "The arrival of a new human being to the family is an anxious havoc full of fluttering heartbeats and sour stomachs, but simultaneously, it is an incredible journey. When you become a parent, as you are ceaselessly caring for a fragile existence, you might feel at your wit's end. Nevertheless, if you are well-informed and take appropriate care, none of this will be a hindrance, and you can give your baby the best treatment wherever you want without leaving your house.",
  "image": "https://kangaroocareindia.com/static/media/blog-150.e159fa7bfa3a4104e21d.jpg",  
  "author": {
    "@type": "Organization",
    "name": "Kangaroo Care",
    "url": "https://kangaroocareindia.com/"
  },  
  "publisher": {
    "@type": "Organization",
    "name": "Kangaroo Care",
    "logo": {
      "@type": "ImageObject",
      "url": "https://kangaroocareindia.com/images/kangaroo-care-new-logo.png"
    }
  },
  "datePublished": "2024-03-18"

  `,
  },
  {
    id: "151",
    schemaCode: `
      "@context": "https://schema.org",
  "@type": "BlogPosting",
  "mainEntityOfPage": {
    "@type": "WebPage",
    "@id": "https://kangaroocareindia.com/blogs/heavy-menstrual-bleeding-understanding-the-causes-and-solutions/151"
  },
  "headline": "Heavy Menstrual Bleeding: Understanding the Causes and Solutions",
  "description": "The cyclic procedure of menstruation is, in fact, a primary characteristic of women during their reproductive years. Periods that are irregular, prolonged, or shortened are quite frequent. However, a low flow might also indicate some health issues unrelated to menstruation. This all-inclusive guide will cover the fundamental causes of such disproportionate menstrual flow and will address efficient methods of dealing with the issue.",
  "image": "https://kangaroocareindia.com/static/media/blog-151.9dc3b81ca7638724f785.jpg",  
  "author": {
    "@type": "Organization",
    "name": "Kangaroo Care",
    "url": "https://kangaroocareindia.com/"
  },  
  "publisher": {
    "@type": "Organization",
    "name": "Kangaroo Care",
    "logo": {
      "@type": "ImageObject",
      "url": "https://kangaroocareindia.com/images/kangaroo-care-new-logo.png"
    }
  },
  "datePublished": "2024-03-18"

  `,
  },
  {
    id: "152",
    schemaCode: `
       "@context": "https://schema.org",
  "@type": "BlogPosting",
  "mainEntityOfPage": {
    "@type": "WebPage",
    "@id": "https://kangaroocareindia.com/blogs/vaginal-birth-vs-c-section-understanding-the-pros-and-cons/152"
  },
  "headline": "Vaginal Birth vs C-Section: Understanding the Pros and Cons",
  "description": "Bringing a new life into the world is undoubtedly one of the most profound experiences a person can have. As expectant parents prepare for childbirth, they are often faced with decisions regarding the method of delivery.",
  "image": "https://kangaroocareindia.com/static/media/blog-152.f5bdd805322d08367a9f.jpg",  
  "author": {
    "@type": "Organization",
    "name": "Kangaroo Care",
    "url": "https://kangaroocareindia.com/"
  },  
  "publisher": {
    "@type": "Organization",
    "name": "Kangaroo Care",
    "logo": {
      "@type": "ImageObject",
      "url": "https://kangaroocareindia.com/images/kangaroo-care-new-logo.png"
    }
  },
  "datePublished": "2024-04-04"


  `,
  },
  {
    id: "153",
    schemaCode: `
       "@context": "https://schema.org",
  "@type": "BlogPosting",
  "mainEntityOfPage": {
    "@type": "WebPage",
    "@id": "https://kangaroocareindia.com/blogs/foods-for-normal-delivery-nourishing-choices-for-a-smooth-birth/153"
  },
  "headline": "Foods for Normal Delivery: Nourishing Choices for a Smooth Birth",
  "description": "Bringing a new life into this world is a beautiful journey filled with anticipation and excitement. For expecting mothers, childbirth can be both thrilling and daunting. While some may opt for medical interventions, many aspire to a natural, normal delivery. This guide delves into the foods for normal delivery and practices that can contribute to a smooth and healthy normal delivery.",
  "image": "https://kangaroocareindia.com/static/media/blog-153.36141a6be4d790cdc9ad.jpg",  
  "author": {
    "@type": "Organization",
    "name": "Kangaroo Care",
    "url": "https://kangaroocareindia.com/"
  },  
  "publisher": {
    "@type": "Organization",
    "name": "Kangaroo Care",
    "logo": {
      "@type": "ImageObject",
      "url": "https://kangaroocareindia.com/images/kangaroo-care-new-logo.png"
    }
  },
  "datePublished": "2024-04-08"


  `,
  },
  {
    id: "154",
    schemaCode: `
       "@context": "https://schema.org",
  "@type": "BlogPosting",
  "mainEntityOfPage": {
    "@type": "WebPage",
    "@id": "https://kangaroocareindia.com/blogs/high-risk-cases-in-pregnancy-understanding-complications/154"
  },
  "headline": "High-Risk Cases in Pregnancy: Understanding Complications",
  "description": "Pregnancy is a beautiful and transformative journey for many women, but it can also come with its fair share of issues & risks, especially for some expectant mothers. High-risk pregnancy is a term used to define pregnancies that pose potential complications to the health of the mother, the fetus, or both. Understanding the complexities and nuances of high-risk pregnancy is crucial for ensuring the well-being of both mother and baby throughout this delicate time.",
  "image": "https://kangaroocareindia.com/static/media/blog-154.12c3011d16bac2543177.jpg",  
  "author": {
    "@type": "Organization",
    "name": "Kangaroo Care",
    "url": "https://kangaroocareindia.com/"
  },  
  "publisher": {
    "@type": "Organization",
    "name": "Kangaroo Care",
    "logo": {
      "@type": "ImageObject",
      "url": "https://kangaroocareindia.com/images/kangaroo-care-new-logo.png"
    }
  },
  "datePublished": "2024-04-16"


  `,
  },
  {
    id: "155",
    schemaCode: `
       "@context": "https://schema.org",
  "@type": "BlogPosting",
  "mainEntityOfPage": {
    "@type": "WebPage",
    "@id": "https://kangaroocareindia.com/blogs/low-blood-pressure-during-pregnancy-causes-and-management/155"
  },
  "headline": "Low Blood Pressure During Pregnancy: Causes and Management",
  "description": "Pregnancy brings about various physiological changes in a woman's body. One such change that can occur is low blood pressure, also known as hypotension. While low blood pressure pregnancy is commonly considered beneficial for overall health, experiencing it during pregnancy can lead to certain complications that require careful management. The comprehensive guide will delve into the causes, symptoms, effects, and management strategies for low blood pressure during pregnancy, considerations for shallow blood pressure, and tips for maintaining normal blood pressure levels throughout this crucial period.",
  "image": "https://kangaroocareindia.com/static/media/blog-155.afb86e0aa3073de18d28.jpg",  
  "author": {
    "@type": "Organization",
    "name": "Kangaroo Care",
    "url": "https://kangaroocareindia.com/"
  },  
  "publisher": {
    "@type": "Organization",
    "name": "Kangaroo Care
",
    "logo": {
      "@type": "ImageObject",
      "url": "https://kangaroocareindia.com/images/kangaroo-care-new-logo.png"
    }
  },
  "datePublished": "2024-04-20"


  `,
  },
  {
    id: "156",
    schemaCode: `
      "@context": "https://schema.org",
  "@type": "BlogPosting",
  "mainEntityOfPage": {
    "@type": "WebPage",
    "@id": "https://kangaroocareindia.com/blogs/ovarian-cyst-procedure-everything-you-need-to-know/156"
  },
  "headline": "Ovarian Cyst Procedure: Everything You Need To Know",
  "description": "Ovarian cysts are common among women of reproductive age. They are swelling, tiny sacs filled with fluid. Patients commonly blame the existing problem on this pulsing thrill, which can result in different symptoms and complications. A fundamental understanding of ovarian cysts is the first and foremost step to women's health. These cysts may be harmless and resolve independently, or some may require medical assistance. The size, shape, and composition of these cysts can vary from one another.",
  "image": "https://kangaroocareindia.com/static/media/blog-156.2bfa680702b05601386c.jpg",  
  "author": {
    "@type": "Organization",
    "name": "Kangaroo Care",
    "url": "https://kangaroocareindia.com/"
  },  
  "publisher": {
    "@type": "Organization",
    "name": "Kangaroo Care",
    "logo": {
      "@type": "ImageObject",
      "url": "https://kangaroocareindia.com/images/kangaroo-care-new-logo.png"
    }
  },
  "datePublished": "2024-04-25"


  `,
  },
  {
    id: "157",
    schemaCode: `
      "@context": "https://schema.org",
  "@type": "BlogPosting",
  "mainEntityOfPage": {
    "@type": "WebPage",
    "@id": "https://kangaroocareindia.com/blogs/dizziness-in-pregnancy-exploring-symptoms-risks-and-remedies/157"
  },
    "headline": "Dizziness in Pregnancy: Exploring Symptoms, Risks, and Remedies",
  "description": "Pregnancy is an extraordinary journey filled with physical and emotional changes. Among the array of symptoms that women may experience during pregnancy, dizziness is a common occurrence. It can range from mild lightheadedness to more severe episodes of feeling faint. Understanding the causes, risks, and management strategies for dizziness during pregnancy is crucial for ensuring the well-being of both the mother and the baby.",
  "image": "https://kangaroocareindia.com/static/media/blog-157.2f61f8afcb25472f1f60.jpg",  
  "author": {
    "@type": "Organization",
    "name": "Kangaroo Care",
    "url": "https://kangaroocareindia.com/"
  },  
  "publisher": {
    "@type": "Organization",
    "name": "Kangaroo Care",
    "logo": {
      "@type": "ImageObject",
      "url": "https://kangaroocareindia.com/images/kangaroo-care-new-logo.png"
    }
  },
  "datePublished": "2024-05-06"


  `,
  },
  {
    id: "158",
    schemaCode: `
      "@context": "https://schema.org",
  "@type": "BlogPosting",
  "mainEntityOfPage": {
    "@type": "WebPage",
    "@id": "https://kangaroocareindia.com/blogs/pneumonia-in-children-recognizing-symptoms-and-seeking-timely-care/158
"
  },
  "headline": "Pneumonia in Children: Recognizing Symptoms and Seeking Timely Care",
  "description": "Pneumonia is a prevalent and potentially serious respiratory infection, particularly in children. Understanding the signs, symptoms, and appropriate care is vital for timely intervention and optimal health outcomes. In this comprehensive guide, we'll delve into the nuances of pneumonia, focusing on children, to equip parents and caregivers with essential knowledge",
  "image": "https://kangaroocareindia.com/static/media/blog-158.8783d1e296d575ff7af3.jpg",  
  "author": {
    "@type": "Organization",
    "name": "Kangaroo Care",
    "url": "https://kangaroocareindia.com/"
  },  
  "publisher": {
    "@type": "Organization",
    "name": "Kangaroo Care",
    "logo": {
      "@type": "ImageObject",
      "url": "https://kangaroocareindia.com/images/kangaroo-care-new-logo.png"
    }
  },
  "datePublished": "2024-05-08"


  `,
  },
  {
    id: "159",
    schemaCode: `
      "@context": "https://schema.org",
  "@type": "BlogPosting",
  "mainEntityOfPage": {
    "@type": "WebPage",
    "@id": "https://kangaroocareindia.com/blogs/vomiting-in-third-trimester-effective-approaches/159"
  },
  "headline": "Vomiting in Third Trimester: Effective Approaches",
  "description": "Pregnancy is an astonishing journey filled with anticipation, excitement, and, for many women, a fair share of challenges. One of these challenges that can be particularly distressing is vomiting in the third trimester, especially during the third trimester. However, by understanding the causes, triggers, and effective management strategies for vomiting during this crucial phase of pregnancy, you can take control of your health and ensure your and your baby's well-being.",
  "image": "https://kangaroocareindia.com/static/media/blog-159.79cfd7de7f990a4ff986.jpg",  
  "author": {
    "@type": "Organization",
    "name": "Kangaroo Care",
    "url": "https://kangaroocareindia.com/"
  },  
  "publisher": {
    "@type": "Organization",
    "name": "Kangaroo Care",
    "logo": {
      "@type": "ImageObject",
      "url": "https://kangaroocareindia.com/images/kangaroo-care-new-logo.png"
    }
  },
  "datePublished": "2024-05-14"


  `,
  },
  {
    id: "160",
    schemaCode: `
      "@context": "https://schema.org",
  "@type": "BlogPosting",
  "mainEntityOfPage": {
    "@type": "WebPage",
    "@id": "https://kangaroocareindia.com/blogs/breastfeeding-positions-optimizing-bonding-and-milk-flow/160"
  },
  "headline": "Breastfeeding Positions: Optimizing Bonding and Milk Flow",
  "description": "Pregnancy is an extraordinary journey filled with physical and emotional changes. Among the array of symptoms that women may experience during pregnancy, dizziness is a common occurrence. It can range from mild lightheadedness to more severe episodes of feeling faint. Understanding the causes, risks, and management strategies for dizziness during pregnancy is crucial for ensuring the well-being of both the mother and the baby.",
  "image": "https://kangaroocareindia.com/static/media/blog-160.95b40242d3b619057cc5.jpg",  
  "author": {
    "@type": "Organization",
    "name": "Kangaroo Care",
    "url": "https://kangaroocareindia.com/"
  },  
  "publisher": {
    "@type": "Organization",
    "name": "Kangaroo Care",
    "logo": {
      "@type": "ImageObject",
      "url": "https://kangaroocareindia.com/"
    }
  },
  "datePublished": "2024-05-17"


  `,
  },
  {
    id: "161",
    schemaCode: `
      "@context": "https://schema.org",
  "@type": "BlogPosting",
  "mainEntityOfPage": {
    "@type": "WebPage",
    "@id": "https://kangaroocareindia.com/blogs/menstrual-hygiene-essential-tips-for-every-woman/161"
  },
  "headline": "Menstrual Hygiene: Essential Tips for Every Woman",
  "description": "Menstruation is a natural biological process experienced by women and girls worldwide. Despite its universality, menstruation often carries a stigma, leading to misconceptions and inadequate hygiene practices. Addressing this issue and promoting awareness about menstrual hygiene is crucial to ensure the well-being of women and girls everywhere.",
  "image": "https://kangaroocareindia.com/static/media/blog-161.87928e2922e39dd12384.jpg",  
  "author": {
    "@type": "Organization",
    "name": "Kangaroo Care",
    "url": "https://kangaroocareindia.com/"
  },  
  "publisher": {
    "@type": "Organization",
    "name": "Kangaroo Care",
    "logo": {
      "@type": "ImageObject",
      "url": "https://kangaroocareindia.com/images/kangaroo-care-new-logo.png"
    }
  },
  "datePublished": "2024-05-20"


  `,
  },
];
