import React from "react";

const NICUMaternityFAQSchema = () => {
  const maternitySchema = `
<script type="application/ld+json">
{
  "@context": "https://schema.org",
  "@type": "FAQPage",
  "mainEntity": [{
    "@type": "Question",
    "name": "Which is the best maternity clinic in Mysore?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "With comprehensive antenatal care and counseling throughout maternity care, Kangaroo Care India is the best maternity hospital in Mysore."
    }
  },{
    "@type": "Question",
    "name": "Which is the best hospital for delivery in Mysore?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "With all amenities, modern facilities at affordable cost, Kangaroo Care India ranked among the best hospitals for delivery in Mysore."
    }
  },{
    "@type": "Question",
    "name": "Which is the best hospital for a high-risk pregnancy?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "If your baby has health problems or even a possibility of it, your pregnancy is a high-risk one. Kangaroo Care India certified doctors pay special attention to such a case."
    }
  },{
    "@type": "Question",
    "name": "How often do I need to visit a maternity hospital during pregnancy?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Your doctor may schedule your prenatal visits based on your health conditions but it’s likely to be in this order.
Weeks 4 to 28 - One in a month
Weeks 28 to 36 - One in two weeks
Weeks 36 to 40 - Every week"
    }
  },{
    "@type": "Question",
    "name": "What are the consultation charges at maternity hospitals?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Consultation charges may differ from doctor to doctor, we at Kangaroo Care India, offer affordable consultations."
    }
  },{
    "@type": "Question",
    "name": "Do I need to book an appointment before visiting a maternity care hospital in Mysore?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Here at Kangaroo Care India, we offer round-the-clock services. To schedule an appointment you can either visit our website or call now."
    }
  }]
}
</script>
 `;

  return (
    <div>
      <iframe
        srcDoc={maternitySchema}
        title="NICU Gynecology FAQ Schema"
        width="0"
        height="0"
      />
    </div>
  );
};

export default NICUMaternityFAQSchema;
