import React from 'react'

function BlogSchema85() {
    const blogSchemas85 = `<script type="application/ld+json">
    {
      "@context": "https://schema.org",
      "@type": "BlogPosting",
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": "https://kangaroocareindia.com/blogs/postpartum-tips-essentials-for-new-moms-after-birth/85"
      },
      "headline": "Postpartum Tips: Essentials For New Moms After Birth",
      "description": "Pregnancy is perhaps the most charming moment in a woman's life, and post-pregnancy is equally thrilling. There is much to do ━ preparing hospital bags, choosing the baby's name, and decorating the room.",
      "image": "https://kangaroocareindia.com/static/media/blog-85.136c9ba28b804a3b4cfe.jpg",  
      "author": {
        "@type": "Organization",
        "name": "kangaroocare",
        "url": "https://kangaroocareindia.com/"
      },  
      "publisher": {
        "@type": "Organization",
        "name": "kangaroocare",
        "logo": {
          "@type": "ImageObject",
          "url": "https://kangaroocareindia.com/images/kangaroo-care-new-logo.png"
        }
      },
      "datePublished": "2022-11-21"
    }
    </script>           
    `;
    return (
      <div>
        <iframe srcDoc={blogSchemas85} title="Blog Schema" width="0" height="0" />
      </div>
    );
}

export default BlogSchema85
