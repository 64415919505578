import React, { Fragment } from "react";
import Header from "../components/Header";
import PageHeader from "../components/PageHeader";
import PageWrapper from "../components/PageWrapper";
import NewBeginnings from "../components/NewBeginnings/Video";
import Footer from "../components/Footer";
import MobileMenu from "../components/MobileMenu";
import SEO from "../components/SEO";

const Video = () => {
  const pathname = window.location.pathname;
  // console.log(pathname);

  let pageTitle = "Video Gallery Of Kangaroo Care Women And Children Hospitals";
  let page = 1;
  if (pathname == "/gallery/customer-testimonal") {
    pageTitle = "Customer Testimonal";
    page = 2;
  }

  return (
    <Fragment>
      <Header />
      <PageHeader
        bgImg={require("../assets/img/gallery-bg.jpg")}
        title={pageTitle}
      />
      <SEO />

      <PageWrapper classes="sm-top">
        <NewBeginnings page={page} />
      </PageWrapper>
      <Footer />
      <MobileMenu />
    </Fragment>
  );
};

export default Video;
