import React from "react";
import DocMoreInfo from "./DocMoreInfo";
import BookAppointmentButton from "../../components/UI/BookApopintmentButton";

const DocSchedule = ({ doctor }) => {
  let {
    schedule: sch = [],
    isAppointmentAvailble = true,
    appointmentStr = "",
  } = doctor;
  const doctorName = doctor.firstname + " " + doctor.lastname;

  let moreInfoClass = isAppointmentAvailble ? "col-lg-12" : "col-lg-12";

  return (
    <div
      className="member-details-middle sm-top-wt "
      style={{ color: "#848484" }}
    >
      <div className="row mtn-50">
        <div className={moreInfoClass}>
          <div className="my-history-area mem-achieve-item">
            <h4>More Info</h4>
            <div className="history-content-wrap how-we-works-content mt-40 mt-sm-30">
              <DocMoreInfo
                key={sch.day}
                qualification={doctor.qualification}
                dept={doctor.dept}
                consultations={doctor.consultations}
                hospitals={doctor.hospitals}
                bookLink={doctor.bookLink}
                docId={doctor.id}
                doctorName={doctorName}
              />
            </div>
          </div>
        </div>

        {/* {isAppointmentAvailble && 
          <div className={moreInfoClass}>
              <div className="row">
                  <div className="container bookAppointmentDiv">
                    <BookAppointmentButton bookLink={doctor.bookLink} docId={doctor.id} doctorName={doctorName} appointmentStr={appointmentStr} />
                  </div>
              </div>
          </div>
        } */}
      </div>
    </div>
  );
};

export default DocSchedule;
