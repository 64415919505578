import React from "react";

const NICUFetalMedicineFAQ = () => {
  const fetalSchema = `
  <script type="application/ld+json">
{
  "@context": "https://schema.org",
  "@type": "FAQPage",
  "mainEntity": [{
    "@type": "Question",
    "name": "What is fetal medicine?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Maternal-Fetal Medicine is a subspecialty of obstetrics that focuses on managing the health of expectant mothers and their babies prior to, during, or after delivery. One who is looking for Fetal Medicine should approach a renowned healthcare center like Kangaroo Care Hospital, fetal medicine and maternal care near Rajajinagar Bangalore."
    }
  },{
    "@type": "Question",
    "name": "What does a maternal-fetal medicine doctor do?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "A maternal-fetal medicine doctor takes care of a woman having a complicated and high-risk pregnancy. MFM doctors also manage the other existing health problems such as diabetes, high blood pressure. He/she performs tests and procedures such as ultrasound and also monitors labor and handles the delivery."
    }
  },{
    "@type": "Question",
    "name": "Does maternal-fetal medicine perform surgery?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "MFM specialists are physicians who subspecialize within the field of obstetrics. They may perform prenatal tests, render treatment and perform surgeries."
    }
  },{
    "@type": "Question",
    "name": "What qualifies as a high-risk pregnancy?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "High-risk pregnancy defines a woman with one or more problems that raise her and her unborn baby to chances of health problems and other high risks such as death during pregnancy or delivery. These risks are high if the woman is: age 17 or younger or age 35 or older."
    }
  },{
    "@type": "Question",
    "name": "Why would I be referred to fetal medicine?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "You may be referred to an MFM specialist if you have a pre-existing medical condition prior to pregnancy or develop any medical condition during pregnancy or your doctor found any complications that could happen during your delivery. You may check-in with Kangaroo Care Hospital, one of the best providers of maternal-fetal medicine in Bangalore."
    }
  }]
}
</script>
 `;

  return (
    <div>
      <iframe
        srcDoc={fetalSchema}
        title="Fetal Structured Schema"
        width="0"
        height="0"
      />
    </div>
  );
};

export default NICUFetalMedicineFAQ;
