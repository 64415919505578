import React from "react";

const BlogSchema47 = () => {
  const blogSchemas47 = `
  <script type="application/ld+json">
{
  "@context": "https://schema.org",
  "@type": "BlogPosting",
  "mainEntityOfPage": {
    "@type": "WebPage",
    "@id": "https://kangaroocareindia.com/blogs/natural-ways-to-deal-with-menopause-symptoms/47"
  },
  "headline": "Natural Ways to Deal with Menopause Symptoms!",
  "description": "Menopause…! It occurs when a woman does not have menstrual cycles for 12 consecutive months and is no longer able to conceive naturally. For most women, menopause begins in the late 40s or early 50s and usually lasts a few years. It can cause unpleasant symptoms, such as hot flashes, weight gain, and so on. Some women get medical help while others turn to natural supplements and remedies to deal with menopause symptoms.",
  "image": "https://kangaroocareindia.com/static/media/blog-47.c877e56953bb45d4a80a.jpg",  
  "author": {
    "@type": "Organization",
    "name": "Kangaroo Care",
    "url": "https://kangaroocareindia.com/"
  },  
  "publisher": {
    "@type": "Organization",
    "name": "Kangaroo Care",
    "logo": {
      "@type": "ImageObject",
      "url": ""
    }
  },
  "datePublished": "2021-12-16"
}
</script>

 `;

  return (
    <div>
      <iframe srcDoc={blogSchemas47} title="Blog Schema" width="0" height="0" />
    </div>
  );
};

export default BlogSchema47;
