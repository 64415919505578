import React from "react";
import Content from "../../UI/Content";

const About = ({
  title,
  heading,
  content,
  thumb,
  orderImg,
  orderContent,
  classes,
  alt,
}) => {
  return (
    <div className={`page-about-wrapper sm-top about-page-section ${classes}`}>
      <div className="container">
        <div className="row align-items-lg-center">
          <div className={`col-md-6 col-lg-7 order-${orderContent}`}>
            <Content classes="about-content ml-0">
              <h6>{title}</h6>
              <h2 dangerouslySetInnerHTML={{ __html: heading }} />
              <p dangerouslySetInnerHTML={{ __html: content }} />
            </Content>
          </div>

          <div className={`col-md-6 col-lg-5 order-0 order-md-${orderImg}`}>
            <img src={thumb} classes="about-thumb about-page-img" alt={alt} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
