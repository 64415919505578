import React from "react";

const NICUGynecologyFAQ = () => {
  const gynecologySchema = `
<script type="application/ld+json">
{
  "@context": "https://schema.org",
  "@type": "FAQPage",
  "mainEntity": [{
    "@type": "Question",
    "name": "What is Gynecology?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Gynaecology deals with the disease of the reproductive system of women. It comprises the both medical and surgical fields as well."
    }
  },{
    "@type": "Question",
    "name": "What are the most common gynecological conditions?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Among so many gynecological conditions in which a woman should consult a gynecologist are a menstrual disorder, family planning, menopause, pelvic floor disorder, infertility, pap smear abnormalities, and some other problems are acne and abnormal female hair distribution, genital tract infection, etc."
    }
  },{
    "@type": "Question",
    "name": "At what age do you start consulting a gynecologist?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "According to the Royal College of Obstetricians and Gynaecologists (RCOG), a girl should visit a gynecologist when she is between the ages of 11 and 18 years. There could be various reasons to check with a gynecologist once a girl begins with her menstruation."
    }
  },{
    "@type": "Question",
    "name": "When should I first consult a Gynecologist for a Gynecological exam?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "The Royal College of Obstetricians and Gynaecologists (RCOG) recommended that the girls should first consult a gynecologist for a gynecological examination between the ages of 11 and 18. Although, most girls won’t require a pelvic exam during their first visit."
    }
  },{
    "@type": "Question",
    "name": "Which health conditions are treated by a gynecologist?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Not one or two, there are several conditions that are treated by gynecologists such as menstrual disorders, pelvic pain, cervical dysplasia, pelvic floor prolapse, polycystic ovarian syndrome, uterine fibroids, urinary incontinence, etc."
    }
  },{
    "@type": "Question",
    "name": "Is there a difference between Gynecologist & Obstetrician?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Yes, there is a difference between a Gynecologist and an Obstetrician. Gynecologists look after all types of Women related health issues whereas Obstetricians look after preconception, pregnancy, Childbirth, and Postnatal health.."
    }
  },{
    "@type": "Question",
    "name": "What is a PAP test?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "PAP test commonly called as Papanicolaou test is a process of Cervical screening, used to detect any precancerous and cancerous cells in the cervix."
    }
  },{
    "@type": "Question",
    "name": "Who should get a Pap Test?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Women are advised to take a Pap Test once in 3 years. It is the human papillomavirus (HPV), the reason for Cervix Cancer in Women. So a Pap test is required to identify such cancerous cells in the human body."
    }
  },{
    "@type": "Question",
    "name": "Will the birth control pill make me gain weight?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "There is no biological proof that birth control pills cause weight gain. The weight gain might be because of water retention and not fat accumulation. The weight gain might also be due to hormonal changes in the body."
    }
  }
]
}
</script>

 `;

  return (
    <div>
      <iframe
        srcDoc={gynecologySchema}
        title="Gynecology Structured Schema"
        width="0"
        height="0"
      />
    </div>
  );
};

export default NICUGynecologyFAQ;
