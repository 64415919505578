import React from "react";

const BlogSchema36 = () => {
  const blogSchemas36 = `<script type="application/ld+json">
  {
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": "https://kangaroocareindia.com/blogs/postpartum-exercise-tips-for-new-moms/36"
    },
    "headline": "Postpartum Exercise Tips For New Moms",
    "description": "Whether it is your first-, second- or third-time giving birth to a baby, every time will feel different from your pre-pregnancy self to post-pregnancy self. After all, you just gave birth to a human being…! If you have been through a healthy pregnancy and delivery, you can start working when you feel ready. Exercise that you can do post-delivery are as follows:",
    "image": "https://kangaroocareindia.com/static/media/blog-37.06d2d54e.jpg",  
    "author": {
      "@type": "Organization",
      "name": "Kangaroo Care",
      "url": "https://kangaroocareindia.com/"
    },  
    "publisher": {
      "@type": "Organization",
      "name": "Kangaroo Care",
      "logo": {
        "@type": "ImageObject",
        "url": "https://kangaroocareindia.com/images/kc-logo-pink.png"
      }
    },
    "datePublished": "2021-08-25"
  }
  </script>  
  
 `;

  return (
    <div>
      <iframe srcDoc={blogSchemas36} title="Blog Schema" width="0" height="0" />
    </div>
  );
};

export default BlogSchema36;
