import React from "react";

const BlogSchema33 = () => {
  const blogSchemas33 = `<script type="application/ld+json">
{
  "@context": "https://schema.org",
  "@type": "BlogPosting",
  "mainEntityOfPage": {
    "@type": "WebPage",
    "@id": "https://kangaroocareindia.com/blogs/valuable-breastfeeding-tips-for-new-moms/33"
  },
  "headline": "Valuable Breastfeeding Tips for New Moms.!",
  "description": "Breastfeeding.! It can be challenging for every new mom. But you must also know that breast milk contains the right balance of nutrients essential for your baby.

It is easier for your baby to digest breast milk than formula milk. Breast milk contains antibodies that boost your baby's immunity. Breastfeeding might even help you lose weight after the delivery. Surprised, right?

For new moms, it can be challenging so here are some breastfeeding tips to get off to a good start.",
  "image": "https://kangaroocareindia.com/static/media/blog-34.c2c29803.jpg",  
  "author": {
    "@type": "Organization",
    "name": "Kangaroo Care"
  },  
  "publisher": {
    "@type": "Organization",
    "name": "Kangaroo Care",
    "logo": {
      "@type": "ImageObject",
      "url": "https://kangaroocareindia.com/images/kc-logo-pink.png"
    }
  },
  "datePublished": "2021-08-05"
}
</script>

 `;

  return (
    <div>
      <iframe srcDoc={blogSchemas33} title="Blog Schema" width="0" height="0" />
    </div>
  );
};

export default BlogSchema33;
