import React, { Fragment } from "react";
import Header from "../components/Header";
import Docs from "../components/Team/journey";
import Footer from "../components/Footer";
import MobileMenu from "../components/MobileMenu";
import SEO from "../components/SEO";

const OurJourney = () => {
  return (
    <Fragment>
      <Header />
      <SEO />
      <Docs />
      <Footer />
      <MobileMenu />
    </Fragment>
  );
};

export default OurJourney;
