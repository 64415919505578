import React, { Fragment } from "react";
import Header from "../components/Header";
import ServiceDetails from "../templates/ServiceDetails";
import Footer from "../components/Footer";
import MobileMenu from "../components/MobileMenu";
import SEO from "../components/SEO";

const PageServiceDetails = () => {
  return (
    <Fragment>
      <Header />
      <ServiceDetails />
      <SEO />
      <Footer />
      <MobileMenu />
    </Fragment>
  );
};

export default PageServiceDetails;
