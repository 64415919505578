import React, { Fragment } from "react";
import Header from "../components/Header";
import PageHeader from "../components/PageHeader";
import Gallery from "../components/Gallery";
import Footer from "../components/Footer";
import MobileMenu from "../components/MobileMenu";
import SEO from "../components/SEO";

const GalleryPage = () => {
  return (
    <Fragment>
      <Header />
      <PageHeader
        bgImg={require("../assets/img/gallery-bg.jpg")}
        title="Photo Gallery of Kangaroo Care Women and Children Hospitals"
      />
      <SEO />
      <Gallery type={1} text={"Bangalore Facility"} />
      <Gallery type={2} text={"Mysore Facility"} />
      <Gallery type={3} text={"Ramanagara Facility"} />
      <Footer />
      <MobileMenu />
    </Fragment>
  );
};

export default GalleryPage;
