import React from "react";
import { Accordion, Card } from "react-bootstrap";
const Faqs = (props) => {
  const index = parseInt(props.id) - 1;
  return (
    <Card>
      <Accordion.Toggle as={Card.Header} eventKey={index}>
        {props.title}
      </Accordion.Toggle>
      <Accordion.Collapse eventKey={index}>
        <Card.Body dangerouslySetInnerHTML={{ __html: props.text }} />
      </Accordion.Collapse>
    </Card>
  );
};

export default Faqs;
