import React from "react";
// import { Link } from "react-router-dom";
import error from "../assets/img/uc.jpg";
import error1 from "../assets/img/error.jpg";
import Header from "../components/Header";
const Error404 = () => {
  return (
    <>
      <div>
        <Header />
      </div>
      <br />
      <div style={styles}>
        <div className="container">
          <div className="row text-center justify-content-center align-items-center">
            <img src={error1} alt="404" className="img-fluid" />
          </div>
          <div className="row justify-content-around">
            <h4
              style={{ color: "#ef586f", fontSize: "40px", fontWeight: "700" }}
            >
              Page Not Found. Sorry !!!
            </h4>
          </div>
          <div className="row justify-content-around">
            <a
              href="/"
              className={"btn-brand"}
              style={{ display: "inline-Block", marginTop: 30 }}
            >
              Go Back Home
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

const styles = {
  margin: "auto",
  justifyContent: "center",
  alignItems: "center",
  height: "100vh",
  textAlign: "center",
  padding: 50,
};

export default Error404;
