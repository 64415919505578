import React, { Fragment } from "react";
import { Link } from "react-scroll";
const doctorDeptListRamanagar = [
  {
    id: 1,
    deptTitle: "Obstetrics and Gynaecology",
    dept: "obstetrics_and_gynecology",
  },
  {
    id: 2,
    deptTitle: "Paediatrics and SubSpecialities",
    dept: "paediatrics_and_subspeciatilies",
  },
  {
    id: 3,
    deptTitle: "Paediatric Surgeon",
    dept: "paediatrics_and_surgeon",
  },
  {
    id: 4,
    deptTitle: "Developmental Pediatrics",
    dept: "developmental_pediatrics",
  },
  {
    id: 11,
    deptTitle: "Fertility & IVF",
    dept: "infertility-and-ivf",
  },
  {
    id: 5,
    deptTitle: "Fetal Medicine",
    dept: "fetal_medicine",
  },
  {
    id: 6,
    deptTitle: "Physicians",
    dept: "physicians",
  },
  {
    id: 7,
    deptTitle: "Anaesthesia",
    dept: "anaesthesia",
  },
  {
    id: 8,
    deptTitle: "Other Specialities",
    dept: "other_specialities",
  },
];
const DeptList = ({ onSetActiveFunc }) => {
  const [deptList, setDeptList] = React.useState(doctorDeptListRamanagar);

  return (
    <Fragment>
      <br />
      <div className="container">
        <div className="row deptListDiv">
          {deptList.map((deptData) => (
            <div key={deptData.id} className="col-sm-4 cardComp">
              <Link
                activeclassname="active"
                to={deptData.dept}
                spy={true}
                smooth={true}
                duration={1000}
                delay={500}
                offset={-300}
                onSetActive={onSetActiveFunc}
              >
                <div className="card cardStyle">
                  <div className="card-body">
                    <h5 className="card-title cardTitle">
                      {deptData.deptTitle}
                    </h5>
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
      <br />
    </Fragment>
  );
};

export default DeptList;
export { doctorDeptListRamanagar };
