import React from 'react'

function BlogSchema77() {
    const blogSchemas77 = ` <script type="application/ld+json">
    {
      "@context": "https://schema.org",
      "@type": "BlogPosting",
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": "https://kangaroocareindia.com/blogs/essential-baby-care-tips-for-monsoon-season/77"
      },
      "headline": "Essential Baby Care Tips For Monsoon Season",
      "description": "Child care can be quite complicated, especially in rainy seasons",
      "image": "https://kangaroocareindia.com/static/media/blog-77.63401a001224e759a81d.jpg",  
      "author": {
        "@type": "Organization",
        "name": "Kangaroo care"
      },  
      "publisher": {
        "@type": "Organization",
        "name": "Kangaroo care",
        "logo": {
          "@type": "ImageObject",
          "url": "https://kangaroocareindia.com/images/kclogo-01-01.png"
        }
      },
      "datePublished": "2022-08-16"
    }
    </script>
    `;
    return (
      <div>
        <iframe srcDoc={blogSchemas77} title="Blog Schema" width="0" height="0" />
      </div>
    );
}

export default BlogSchema77
