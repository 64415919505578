import React from 'react';
import ServiceItem from "./ServiceItem";
import ServicesData from "../../data/Services/allservices";

const ServiceList = () => {
    return (
        <React.Fragment>
            <div className="service-content-area">
                <div className="container">
                <div className="row mtn-30">
                    {ServicesData.map((service) => (
                    <ServiceItem
                        key={service.id}
                        id={service.id}
                        icon={service.icon}
                        title={service.title}
                        text={service.shortDesc}
                        thumb={service.previewImg}
                    />
                    ))}
                </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default ServiceList
