import React from "react";
import { Accordion } from "react-bootstrap";
import Kats from "./Kats";

import katsData from "../../data/Kats/kats";

const KatsIndex = () => {
  return (
    <>
      <div className="faq-page-wrapper sm-top">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-md-6">
              <h4>About KATS</h4>
              <p>
                'KATS', the Newborn Paediatric & Obstetric Emergency Transport
                Service is a regional service of Kangaroo Care Women and
                Children Hospital, Vijayanagar (Bangalore and Mysore). It is the
                only service of its kind in West Bangalore and Mysore, providing
                expert clinical advice, clinical co-ordination, emergency
                treatment and stabilization, and inter-hospital transport for
                very sick babies, children and obstetric patients. KATS operates
                24 hours a day, 7 days a week.
              </p>
              <p>
                In order to further these aims, the department also provides
                advice to medical practitioners, nurses, and paramedical
                personnel regarding aspects of stabilization and transport of
                sick newborn infants.
              </p>
              <p>
                It has been shown that the treatment of sick newborn infants in
                an intensive care unit increases their chances of survival as
                well as the quality of survival. However, the special
                facilities, constant intensive medical and nursing supervision
                provided by an intensive care unit are only offered in Kangaroo
                Care Level 3B NICU for entire West Bangalore and surrounding
                areas.
              </p>
            </div>
            <div className="col-xs-12 col-md-6" style={{ textAlign: "center" }}>
              <img
                src={require("../../assets/img/kats-round.png")}
                alt="Kangaroo Care Acute Emergency Transport Services (KATS)"
                className="katsround"
              />
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <Accordion defaultActiveKey="0">
              {katsData.map((kats) => (
                <Kats
                  key={kats.id}
                  id={kats.id}
                  title={kats.title}
                  text={kats.text}
                />
              ))}
            </Accordion>
          </div>
        </div>
      </div>
    </>
  );
};

export default KatsIndex;
