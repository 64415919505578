import React from "react";

const MissionVisionValues = () => {
  return (
    <div>
      <h4>Mission Vision & Values</h4>
    </div>
  );
};

export default MissionVisionValues;
