import React, { Fragment } from "react";
import PageHeader from "../../components/PageHeader";
import PageWrapper from "../../components/PageWrapper";
import VideoTestListPage from "./VideoTestListPage";
import Testimonials from "../../components/Testimonials/home-one";

const PackageDetail = () => {

  return (
    <Fragment>
      <PageHeader
        bgImg={require("../../assets/img/service/service-bg-1.jpg")}
        title={"Video Testimonals"}
      />
      <PageWrapper classes="sm-top">
        <VideoTestListPage/>
      </PageWrapper>
      <Testimonials/>
    </Fragment>
  );
};

export default PackageDetail;
