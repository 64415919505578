import React from "react";

const NICUNeonatalIntensiveFAQ = () => {
  const NeontalSchema = `

<script type="application/ld+json">
{
  "@context": "https://schema.org",
  "@type": "FAQPage",
  "mainEntity": [{
    "@type": "Question",
    "name": "What is NICU?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Neonatal Intensive Care Unit (NICU) provides care for newborn babies that are either sick and/ or born earlier than their due date (before 37 weeks) and require medical/surgical support. The word “neonatal” means newborn or less than 28 days of age. Doctors looking after neonates are called Neonatologists."
    }
  },{
    "@type": "Question",
    "name": "Why would a baby need admission to NICU?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Most of the babies are born without any problems. Occasionally some babies need additional medical assistance. Such babies are admitted to NICU to provide them the support that they need. Some of the many reasons include, being born prematurely, low birth weight, low blood sugar, early onset infection, jaundice, difficulties with breathing etc. Occasionally, babies could also be born with conditions which might be caused due to genetic or inherited conditions. The growth of such babies may be different/affected in the womb causing congenital anomalies (Heart defects, brain/spinal cord, lung defects etc). Such babies are also admitted to NICU for a thorough investigation and management of their condition."
    }
  },{
    "@type": "Question",
    "name": "Who can visit the NICU?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Babies are very vulnerable to infection. Hence only parents are allowed inside NICU. Visit from other relatives is restricted to minimize the risk of spreading infection."
    }
  },{
    "@type": "Question",
    "name": "What happens in NICU routinely?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "The babies are looked after by different nurses each day, based on their shifts. The nurses would be the first point of call for any queries parents may have. The doctors usually do ward rounds in the morning and update the parents regarding the baby’s progress. The care of the baby would continue based on the plan made by the doctor during ward round. This might include blood tests, Xray, scans or other procedures on the baby. The mother is encouraged to stay with the baby to develop bonding by being part of baby’s routine care and to help establish breast feeds. Nurses or lactation consultant will be available to advise mothers regarding breast feeding."
    }
  },{
    "@type": "Question",
    "name": "What is the NICU equipped with?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "NICU will be equipped with various machines that a baby would need for its treatment. This includes a plastic box (incubator) where a baby is kept warm and humid. The baby will be attached with wires that are connected to a monitor, which checks the baby’s heart rate, breathing, temperature and oxygen level in the blood. Sometimes, if the baby needs breathing support, they may be connected to machines called as “High Flow oxygen” or CPAP or even Ventilators. The baby may be connected to a drip to provide fluids or a tube through its nose to provide milk. Parents may find the alarms in NICU upsetting, however healthcare staff will always be around to help ease parental anxiety and clarify any doubts the parents may have."
    }
  },{
    "@type": "Question",
    "name": "What support do parents get in NICU?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "We understand having a baby in NICU is an anxious and emotional time. There will be help available for parents 24/7, which may include explaining about your baby’s medical condition, to emotional support through counselling. The nurses will help parents to care for their baby’s routine like, changing nappy, skin to skin care (Kangaroo care), feeding, giving oral medicines or giving a bath. We ensure that parents are filled with confidence in handling babies prior to discharge home."
    }
  },{
    "@type": "Question",
    "name": "When will the baby be discharged from NICU?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Babies can be discharged home safely, when they can feed orally by themselves, gaining weight and maintaining their body temperature without any additional heating support. Usually we would say that a premature baby will be ready for discharge around their due date. This however is arbitrary, as some babies will be ready much earlier than their due date, provided there are no other complications. Before going home, parents will be offered to “room in” with their baby for 1 or 2 days, so that they will have a feel of how to independently handle their baby. There will be a checklist of things that needs completing prior to discharge which includes screening tests on the baby as well parental basic life support (BLS) training. Should parents have any concerns during any point in time, they could always speak to the healthcare staff who will be happy to help."
    }
  },{
    "@type": "Question",
    "name": "Is there any need for follow up?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Babies discharged from NICU are followed up routinely in the clinic to ensure they are catching up on their growth and milestones. Sometimes, babies may develop complications due their prematurity or their congenital anomaly. They will need follow up under various specialities depending on the baby’s condition."
    }
  },{
    "@type": "Question",
    "name": "How long does a baby stay in NICU?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "The baby is kept in the NICU depending on the severity of the illness. On average, the baby is kept in NICU for 13.2 days. However, babies born earlier than 32 weeks into pregnancy are kept for an average of 46.2 days."
    }
  },{
    "@type": "Question",
    "name": "Why are newborns kept in NICU?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "If the baby is born with an immature condition or is having any health problems, within 34 weeks of the gestation period, then the baby is required to stay in the NICU."
    }
  },{
    "@type": "Question",
    "name": "What is the best hospital for premature babies?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "We have a fully equipped nursery to handle any type of emergency baby care with the help of an expert team of neonatologists. Our NICU nurses and critical care specialists are trained in taking care of the physical, and psychological needs of the babies."
    }
  }
      ]
}
</script>

 `;

  return (
    <div>
      <iframe
        srcDoc={NeontalSchema}
        title="Neontal Structured Schema"
        width="0"
        height="0"
      />
    </div>
  );
};

export default NICUNeonatalIntensiveFAQ;
