import React from "react";
import GalleryItem from "./GalleryItems";
import galleryData from "../../data/Gallery/gallery.json";
import galleryDataNew from "../../data/Gallery/newGallery.json";
import galleryRamanagar from "../../data/Gallery/ramanagar.json";

const Gallery = ({ type, text }) => {
  let galleryList = galleryData;

  if (type == 2) {
    galleryList = [];
    galleryList = galleryDataNew;
  }
  if (type == 3) {
    galleryList = [];
    galleryList = galleryRamanagar;
  }
  return (
    <div className="page-content-wrapper sm-top service-details-wrapper bs100 section-gallery">
      <h4 className="cityName">
        <b>{text}</b>
      </h4>
      <div className="container">
        <div className="row">
          {galleryList.map((gal) => (
            <GalleryItem key={gal.id} text={gal.text} image={gal.image} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Gallery;
