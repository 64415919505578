import React from "react";

const BlogSchema48 = () => {
  const blogSchemas48 = `<script type="application/ld+json">
  {
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": "https://kangaroocareindia.com/blogs/it-time-to-take-charge-of-your-maternity-care/48"
    },
    "headline": "It’s Time to Take Charge of Your Maternity Care.!",
    "description": "Which birth preparation class should I take? Who should be with me during childbirth? Which gynecologist should I go to?
  These are some of the common questions every to-be-mom would have in her mind. However, all these can be a little overwhelming. You will want the best care for yourself and your baby.",
    "image": "https://kangaroocareindia.com/static/media/blog-48.70d22b05ce0bbc9f555f.jpg",  
    "author": {
      "@type": "Organization",
      "name": "Kangaroo Care",
      "url": "https://kangaroocareindia.com/"
    },  
    "publisher": {
      "@type": "Organization",
      "name": "Kangaroo Care",
      "logo": {
        "@type": "ImageObject",
        "url": ""
      }
    },
    "datePublished": "2021-12-21"
  }
  </script>
  
  

 `;

  return (
    <div>
      <iframe srcDoc={blogSchemas48} title="Blog Schema" width="0" height="0" />
    </div>
  );
};

export default BlogSchema48;
