import React from "react";
// import {Link} from 'react-router-dom'

function BlogItem(props) {
  //  const blogURL = `/blogs/${props.title.split(" ").join("-").toLocaleLowerCase()}?id=${props.id}`;
  const blogURL = `/blogs/${props.urlIndex}`;
  return (
    <div className={props.cols ? props.cols : "col-md-6 col-lg-4"}>
      <div className="blog-item">
        {props.thumb ? (
          <figure className="blog-thumb">
            <a href={`${process.env.PUBLIC_URL + blogURL}`}>
              <img
                src={require("../../assets/img/" + props.thumb)}
                alt={props.title}
              />
            </a>
          </figure>
        ) : null}
        <div className="blog-content">
          <h2 className="h5">
            <a href={`${process.env.PUBLIC_URL + blogURL}`}>{props.title}</a>
          </h2>
          <p>{props.excerpt}</p>

          <div className="blog-meta">
            <a href={`${process.env.PUBLIC_URL + blogURL}`}>
              {/* By: {props.postBy} */}
            </a>
            <a href={`${process.env.PUBLIC_URL + blogURL}`}>{props.date}</a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BlogItem;
