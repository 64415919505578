import React from "react";
import SectionTitle from "../../UI/SectionTitle";
import SlickSlider from "../../UI/Slick";
import Founders from "./Adminteam";

import teamBg from "../../../assets/img/bg-blog.jpg";
import founderData from "../../../data/Adminteam/adminteam";

function Admin({ doctorList }) {
  // console.log(doctorList);
  const settings = {
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: true,
    autoplay: false,
    dots: false,
    className: "team-content-wrap slick-dots--light mtn-md-5",
    responsive: [
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <div
      className="team-area-wrapper bg-img sp-y"
      style={{ backgroundImage: `url(${teamBg})` }}
    >
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-lg-12">
            <SlickSlider settings={settings}>
              {doctorList.map((team) => (
                <div key={team.id}>
                  <Founders
                    id={team.id}
                    text={team.text}
                    image={team.image}
                    firstname={team.firstname}
                    lastname={team.lastname}
                    link={team.link}
                    designation={team.title}
                  />
                </div>
              ))}
            </SlickSlider>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Admin;
