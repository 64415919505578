import React from 'react'

function BlogSchema83() {
    const blogSchemas83 = `<script type="application/ld+json">
    {
      "@context": "https://schema.org",
      "@type": "BlogPosting",
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": "https://kangaroocareindia.com/blogs/what-are-regular-pregnancy-losses-and-what-are-their-causes/83"
      },
      "headline": "What Are Regular Pregnancy Losses, And What Are Their Causes?",
      "description": "Pregnancy loss is the difficult phase any woman can experience during her pregnancy, and it can be a physically and emotionally taxing phase for the couple and the entire family. Pregnancy loss is a surprisingly common occurrence, with approximately 15% of all clinically recognised pregnancies resulting in pregnancy failure.",
      "image": "https://kangaroocareindia.com/static/media/blog-83.2498d547481884dc0a7f.jpg",  
      "author": {
        "@type": "Organization",
        "name": "kangaroo care",
        "url": "https://kangaroocareindia.com/"
      },  
      "publisher": {
        "@type": "Organization",
        "name": "kangaroo care",
        "logo": {
          "@type": "ImageObject",
          "url": "https://kangaroocareindia.com/images/kangaroo-care-new-logo.png"
        }
      },
      "datePublished": "2022-10-29"
    }
    </script>    
    `;
    return (
      <div>
        <iframe srcDoc={blogSchemas83} title="Blog Schema" width="0" height="0" />
      </div>
    );
}

export default BlogSchema83
