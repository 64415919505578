import React, { Fragment, useState } from "react";
//  import GetDocsRight from "../../components/Doctors/departments/section-left";
import GetDocsLeft from "../../components/Doctors/departments/section-right";
import PageHeader from "../../components/PageHeader";

//  DATA
import Dept from "../../data/Dept";

const DocDeptWise = () => {
  const [deptData, setDeptData] = useState({});

  const [city, setCity] = useState("");

  const [pageTitle, setPageTitle] = useState("");

  const [deptName, setDeptName] = useState("");

  React.useEffect(() => {
    //  FIND PATH NAME
    let { pathname } = window.location;
    let pathnameArr = pathname.split("/");

    if (pathnameArr.length >= 3 && pathnameArr[3] !== "") {
      const cityData = pathnameArr[2];

      let cityName = "";
      if (cityData === "bangalore") {
        cityName = "kcblr";
      } else if (cityData === "mysore") {
        cityName = "kcmys";
      } else if (cityData === "ramanagara") {
        cityName = "kcramanagar";
      } else if (cityData === "kangaroocare-nagarabhavi-clinic") {
        cityName = "kcclinicsnagarabhavi";
      }

      setCity(cityName);

      //  GET DEPT NAME
      let rawDeptName = pathnameArr[3];
      rawDeptName = rawDeptName.replace(`-in-${cityData}`, "");

      setDeptName(rawDeptName);

      //  GET DEPT DATA
      const deptObj = Dept.find((dept) => dept.newLink == rawDeptName);
      // console.log(rawDeptName);
      // alert(deptObj);
      setDeptData(deptObj);

      //  PAGE TITLE
      let deptPageTitle = `${deptObj.title} in ${cityData}`;
      setPageTitle(deptPageTitle);
    }
  }, []);

  const ContentService = () => {
    if (city == "kcblr" && deptName == "obstetrician-gynaecologist") {
      let c1 =
        "<br/><br/><h2> Best Gynecologist and Obstetrician in Bangalore</h2><p style='font-size:16px;'>We have an expert team of top renowned Gynaecologists to treat all types of gynaecological problems that a woman faces in her lifetime, and also Urogynecologist, Laparoscopic surgeon along with experienced radiologists. </p> <br/>";
      return (
        <>
          <div className="container" dangerouslySetInnerHTML={{ __html: c1 }} />
        </>
      );
    } else if (city == "kcblr" && deptName == "fertility-ivf-specialist") {
      let c1 =
        "<br/><br/><h2>Best Fertility Specialist in Bangalore</h2><p style='font-size:16px;'>Kangaroo Care is the best fertility specialist in Bangalore, we make sure that you get the best opportunity to embrace parenthood by receiving the best fertility treatments from our professionally expert specialists in fertility. </p> <br/>";
      return (
        <>
          <div className="container" dangerouslySetInnerHTML={{ __html: c1 }} />
        </>
      );
    } else if (city == "kcblr" && deptName == "paediatrician-neonatologist") {
      let c1 =
        "<br/><br/><h2 style='font-size:24px;'>Top Neonatologist in Bangalore </h2><p style='font-size:16px;'>We at Kangaroo Care have the best neonatologists in Bangalore and neonatal nurses for comprehensive care for the premature and sick babies, specialized in all types of neonatology treatments. </p><br/><h2 style='font-size:24px;'>Best Child Specialist in Bangalore</h2><p style='font-size:16px;'>Being the best child specialists in Bangalore, Kangaroo Care takes pride in the ability to manage any child related medical condition with the help of state of the art equipment and extremely well trained doctors.</p><br/>";
      return (
        <>
          <div className="container" dangerouslySetInnerHTML={{ __html: c1 }} />
        </>
      );
    } else if (city == "kcmys" && deptName == "obstetrician-gynaecologist") {
      let c1 =
        "<br /><br /><h2 style='font-size:24px;'>Best Gynecologist in Mysore</h2><p style='font-size:16px;'>Kangaroo Care has a team of expert gynecologists and obstetricians to provide treatment for all types of gynecological problems that a woman experiences in her lifetime. Our team of gynecologists strives to provide the best comprehensive treatment. </p><br />";
      return (
        <>
          <div className="container" dangerouslySetInnerHTML={{ __html: c1 }} />
        </>
      );
    } else if (city == "kcmys" && deptName == "fertility-ivf-specialist") {
      let c1 =
        "<br /><br /><h2 style='font-size:24px;'>Best Fertility Specialist in Mysore</h2><p style='font-size:16px;'>At Kangaroo Care, we have a team of experienced fertility specialists to provide extensive care and treatment. We help you embrace parenthood with our advanced fertility services.</p><br />";
      return (
        <>
          <div className="container" dangerouslySetInnerHTML={{ __html: c1 }} />
        </>
      );
    } else {
      return <p></p>;
    }
  };

  return (
    <Fragment>
      <PageHeader
        bgImg={require("../../assets/img/doctors-bg.jpg")}
        title={pageTitle}
        content=""
      />
      {Object.keys(deptData).length > 0 && (
        <GetDocsLeft
          hospitals={city}
          deptTitle={deptData.deptTitle}
          dept={deptData.dept}
          image={deptData.image}
        />
      )}
      <ContentService />
    </Fragment>
  );
};

export default DocDeptWise;
