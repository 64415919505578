import React from "react";
// import { Link } from "react-router-dom";

function ServiceItem(props) {
  /*
  const serviceURL = `/service/${props.title
    .split(" ")
    .join("-")
    .toLowerCase()}?id=${props.id}`;
*/
  const serviceURL = props.link;
  return (
    <div className="col-sm-6 col-lg-4">
      <div className="service-item">
        <figure className="service-thumb">
          <a href={`${process.env.PUBLIC_URL + serviceURL}`}>
            <img
              src={require("../../assets/img/" + props.thumb)}
              alt={props.title}
            />
          </a>

          <figcaption className="service-txt services-text">
            <h3>{props.title}</h3>
          </figcaption>
        </figure>
        <div className="service-content">
          <div className="service-content-inner">
            <h5>
              <a
                href={`${process.env.PUBLIC_URL + serviceURL}`}
                className="stretched-link"
              >
                {props.title}
              </a>
            </h5>
            <p>{props.text}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ServiceItem;
