import React from "react";
import NewBeginnings from "./NewBeginnings";
import nbData from "../../data/NewBeginnings/new-beninnings.json";

const NewB = () => {
  return (
    <div className="page-content-wrapper sm-top service-details-wrapper bs100 section-facilities">
      <div className="container">
        {nbData.map((data) => (
          <NewBeginnings
            key={data.id}
            title={data.title}
            image={data.image}
            text={data.text}
          />
        ))}
      </div>
    </div>
  );
};

export default NewB;
