import React, { Fragment } from "react";
import Header from "../components/Header";
import PageHeader from "../components/PageHeader";
import NewBeginnings from "../components/NewBeginnings";
import Footer from "../components/Footer";
import MobileMenu from "../components/MobileMenu";
import SEO from '../components/SEO';

const NewBPage = () => {
  return (
    <Fragment>
      <Header />
      <PageHeader
        bgImg={require("../assets/img/gallery-bg.jpg")}
        title="New Beginnings"
      />
      <SEO/>

      <NewBeginnings />
      <Footer />
      <MobileMenu />
    </Fragment>
  );
};

export default NewBPage;
