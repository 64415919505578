import React, { Fragment, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import Modal from "../Modal";
import "../style.css";

const AppointmentBranch = () => {
  const [branch, setBranch] = useState(null);

  const Links = {
    bengaluru:
      "https://kangaroocare-bangalore.pulseright.io/booking?orgId=63ee5c67c1c47672d05db803",
    mysore:
      "https://kangaroocare-bangalore.pulseright.io/booking?orgId=641191431b1d0308a0681d5f",

    fertility:
      "https://kangaroocare-bangalore.pulseright.io/booking?orgId=64428e3d94af9311780d62a3",

    nagarbhavi:
      "https://kangaroocare-bangalore.pulseright.io/booking?orgId=64429ce294af9311780d6478",

    dentistry:
      "https://kangaroocare-bangalore.pulseright.io/booking?orgId=6437b8152543ae6913aa3996",

    ramanagara:
      "https://kangaroocare-bangalore.pulseright.io/booking?orgId=65fd1bd8133c30154bdcda30",
    "ramanagara-fertility":
      "https://kangaroocare-bangalore.pulseright.io/booking?orgId=660e5a5c769c7a6a01f3dcfb",
    "mysore-fertility":
      "https://kangaroocare-bangalore.pulseright.io/booking?orgId=65f80548133c30154bd93d76",
  };

  useEffect(() => {
    const branchLink = window.location.pathname.split("/").slice(-1);

    setBranch(Links[branchLink]);
  }, []);

  return (
    <Fragment>
      <div className="findDoctorDiv doctorAppointmentContainer pt-5 ">
        <div className="container mt-5 mb-5">
          <div className="row mtn-30">
            <h1 className="col-12 findDocText">Book Appointment</h1>

            <div className="col-12">
              <div className="row">
                {branch && (
                  <iframe
                    title="Appointment"
                    width="100%"
                    height="600px"
                    src={branch}
                  ></iframe>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AppointmentBranch;
