import React from 'react'

const Kats = () => {

    const katsFaqSchema = (`
        <script type="application/ld+json">
        {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": [{
            "@type": "Question",
            "name": "Where is KATS?",
            "acceptedAnswer": {
            "@type": "Answer",
            "text": "KATS center is located at Kangaroo Care Women and Children Hospital in Vijayanagar, Bangalore. The service is available to anyone of the nearby hospitals which call for help from time to time across West Bangalore and surrounding areas."
            }
        },{
            "@type": "Question",
            "name": "KATS is a hospital on Wheels!",
            "acceptedAnswer": {
            "@type": "Answer",
            "text": "<p>KATS is not just a transport service; KATS provides a referral service for hospitals faced with a neonatal, paediatric, and obstetric emergency. Discussion about patient illness or needs occurs via conference calls with a KATS consultant and other clinical experts.</p>
        
        <p>KATS provides a neonatal intensive care team of trained nurses and when indicated, a paediatrician experienced in neonatology. KATS also provides a specially equipped transport incubator with intensive care facilities including monitoring equipment, facilities for controlled oxygen administration and assisted ventilation, and also for continuous intravenous infusion. After receiving a referral call, the team mobilizes as quickly as possible and travels to the referring hospital to pick up the infant and supervise the transfer. Safe transport and experienced supervision in transit with adequate facilities are the essences of the stabilization of the infant.</p>"
            }
        },{
            "@type": "Question",
            "name": "Which infants should be transported?",
            "acceptedAnswer": {
            "@type": "Answer",
            "text": "Infants and other patients who cannot be managed optimally with the facilities available and those at risk of serious complications should be transferred. The final decision regarding transfer rests with the physician in charge. Each hospital varies in the level of facilities for the newborn care they can offer."
            }
        },{
            "@type": "Question",
            "name": "How soon will KATS arrive?",
            "acceptedAnswer": {
            "@type": "Answer",
            "text": "<p>This depends on the time required for mobilization and travel. As a guide, one should allow 30-40 minutes for mobilization plus the time taken to travel from Kangaroo Care Women and Children Hospital to the referring hospital. The KATS team makes every effort to keep the response time to an absolute minimum.</p>
        
        <p>It is generally unwise to attempt to move the infant from the hospital to meet the KATS team. Disasters may occur when infants are not adequately stabilized or supported.</p>"
            }
        },{
            "@type": "Question",
            "name": "What should be done while awaiting transport?",
            "acceptedAnswer": {
            "@type": "Answer",
            "text": "While awaiting the KATS team, the infant should be kept warm, positioned to allow maximum observation, given sufficient oxygen to maintain a pink color, and a clear airway maintained by adequate (but not overzealous) suctioning."
            }
        },{
            "@type": "Question",
            "name": "What happens when the KATS team arrives?",
            "acceptedAnswer": {
            "@type": "Answer",
            "text": "KATS personnel will ask about the infant's condition and request for lab reports. They will assess the infant, record observations, and then perform the required procedures to stabilize the infant ready for transport. The KATS team will inform the parents about their management and show the infant to them. The parents are given an explanation and information booklet about Kangaroo Care Hospital. Occasionally, it is possible for parents to travel with their babies. This must be decided by the KATS team."
            }
        },{
            "@type": "Question",
            "name": "What happens when the KATS team leaves?",
            "acceptedAnswer": {
            "@type": "Answer",
            "text": "Naturally, parents often feel anxious and depressed when their infant is transferred. They should be encouraged to enquire frequently about their infant’s condition and visit as soon as possible. The KATS team will endeavor to telephone the parents once the transfer is completed."
            }
        },{
            "@type": "Question",
            "name": "How can you help?",
            "acceptedAnswer": {
            "@type": "Answer",
            "text": "KATS relies on the community’s help to treat the newborns transported by our teams. Your contribution can make a difference!"
            }
        }]
        }
        </script>    
    `);

    return (
        <div>
            <iframe width="0" height="0" srcDoc={katsFaqSchema} title="KATS FAQ Structured Schema" />
        </div>
    )
}

export default Kats
