import React from "react";

const BlogSchema32 = () => {
  const blogSchemas32 = `<script type="application/ld+json">
{
  "@context": "https://schema.org",
  "@type": "BlogPosting",
  "mainEntityOfPage": {
    "@type": "WebPage",
    "@id": "https://kangaroocareindia.com/blogs/super-foods-for-breastfeeding-mums/32"
  },
  "headline": "Super Foods For Breastfeeding Mums",
  "description": "As a breastfeeding mother, you may observe that your hunger levels may be high all the time. Breastfeeding women require extra nutrients, proteins, antioxidants, fats, and minerals, higher levels of specific vitamins such as vitamin D, vitamin A, vitamin E, vitamin C, B12, selenium, and zinc.",
  "image": "https://kangaroocareindia.com/static/media/blog-33.6276131e.jpg",  
  "author": {
    "@type": "Organization",
    "name": "Kangaroo Care"
  },  
  "publisher": {
    "@type": "Organization",
    "name": "Kangaroo Care",
    "logo": {
      "@type": "ImageObject",
      "url": "https://kangaroocareindia.com/images/kc-logo-pink.png"
    }
  },
  "datePublished": "2021-07-28"
}
</script>



 `;

  return (
    <div>
      <iframe srcDoc={blogSchemas32} title="Blog Schema" width="0" height="0" />
    </div>
  );
};

export default BlogSchema32;
