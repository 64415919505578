import React from 'react'

function BlogSchema90() {
    const blogSchemas90 = `
<script type="application/ld+json">
{
  "@context": "https://schema.org",
  "@type": "BlogPosting",
  "mainEntityOfPage": {
    "@type": "WebPage",
    "@id": "https://kangaroocareindia.com/blogs/tips-to-handle-overweight-baby/90"
  },
  "headline": "Tips To Handle Overweight Baby",
  "description": "Everyone thinks a fat baby is cute and healthy. But it may turn unhealthy, and every parent should give a little extra time and care to raise a child.",
  "image": "https://kangaroocareindia.com/static/media/blog-90.75940c494c40a47e27ce.jpg",  
  "author": {
    "@type": "Organization",
    "name": "kangaroocare",
    "url": "https://kangaroocareindia.com/"
  },  
  "publisher": {
    "@type": "Organization",
    "name": "kangaroocare",
    "logo": {
      "@type": "ImageObject",
      "url": "https://kangaroocareindia.com/images/kangaroo-care-new-logo.png"
    }
  },
  "datePublished": "2022-12-12"
}
</script>`;
    return (
      <div>
        <iframe srcDoc={blogSchemas90} title="Blog Schema" width="0" height="0" />
      </div>
    );
}

export default BlogSchema90
