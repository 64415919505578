import React from 'react';

const FormInput = ({tag,type,name,placeholder,classes,value,change,loading=false,formClassName="single-input-item"}) => {

    return (
        <div className={formClassName} style={{marginBottom: "0px !important"}}>
            <label>
                {(()=>{
                    if(tag === 'input'){
                        return <input type={type} name={name} id={name} placeholder={placeholder} className={classes} value={value} onChange={change}/>
                    }else if(tag === 'textarea'){
                        return  <textarea name={name} cols="30" rows="7" placeholder={placeholder} className={classes} required id={name} value={value} onChange={change}/>
                    }else if(tag === 'button'){
                        return <button className={`btn-outline ${classes}`}>
                        {(loading === true)?"Sending...":"Send Message"}</button>
                    }
                })()}
            </label>
        </div>
    );
};

export default FormInput;