import React from "react";

const BlogSchema37 = () => {
  const blogSchemas37 = `<script type="application/ld+json">
  {
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": "https://kangaroocareindia.com/blogs/pregnancy-tips-and-precautions-during-monsoon/37"
    },
    "headline": "Pregnancy Tips And Precautions During Monsoon!",
    "description": "Monsoon has a different definition for each one of us.  But for soon-to-be moms, the monsoon may bring in many things.  Here are some tips to help you keep yourself healthy during the monsoon.",
    "image": "https://kangaroocareindia.com/static/media/blog-38.e32cfce0.jpg",  
    "author": {
      "@type": "Organization",
      "name": "Kangaroo Care",
      "url": "https://kangaroocareindia.com/"
    },  
    "publisher": {
      "@type": "Organization",
      "name": "Kangaroo Care",
      "logo": {
        "@type": "ImageObject",
        "url": "https://kangaroocareindia.com/images/kc-logo-pink.png"
      }
    },
    "datePublished": "2021-09-08"
  }
  </script>
  
 `;

  return (
    <div>
      <iframe srcDoc={blogSchemas37} title="Blog Schema" width="0" height="0" />
    </div>
  );
};

export default BlogSchema37;
