import React from "react";
import { Link } from "react-router-dom";

function GetDoctor({ doctor }) {
  let {
    id,
    firstname,
    lastname,
    title,
    image,
    about,
    isDoctor = true,
    actionTabTitle = "Schedule and More Info",
    aboveShortDesc = "",
    qualification = "",
    isConsultant = true,
    qualificationDesc = "",
  } = doctor;

  let name = firstname;
  if (lastname && lastname !== ".") {
    name = name + " " + lastname;
  }
  const docUrl = `/doctor/${name
    .split(" ")
    .join("-")
    .toLocaleLowerCase()}/${id}`;

  const MAXLENGTH = 250;
  let fulltext = about;
  if (aboveShortDesc !== "") fulltext = aboveShortDesc;
  const text = `${fulltext.substring(0, MAXLENGTH)}...`;

  const isConsultantFunc = () => {
    if (isConsultant == false) {
      return <h6>{title}</h6>;
    } else {
      return <h6>CONSULTANT - {title}</h6>;
      //  return (<h6>{title}</h6>);
    }
  };

  //  chk length and keep in next line

  //  console.log("qualification len",qualification.length);

  const string_chop = function (str, size) {
    if (str == null) return [];
    str = String(str);
    size = ~~size;
    return size > 0 ? str.match(new RegExp(".{1," + size + "}", "g")) : [str];
  };

  if (qualificationDesc != "") {
    qualification = qualificationDesc;
  }

  let imgDivSize = "col-md-3";

  let idArr = ["12", "39", "41"];

  if (idArr.includes(id)) {
    imgDivSize = "col-md-3";
  }

  // if(qualification.length > 50){
  //   let qt = string_chop(qualification,50);
  //   console.log("qt",qt);
  //   //  let qt = `${qualification.substring(0, 50)}...`;
  // }
  let altText = `${isDoctor && "Dr. "} ${firstname} ${lastname} ${
    title && title
  }`;
  return (
    <>
      <Link to={`${process.env.PUBLIC_URL + docUrl}`}>
        <div className="row docwrap">
          <div className="icon-box-item row">
            <div className={`icon-box__icon col-md-3 col-xs-12`}>
              <img
                src={require("../../assets/img/doctors/" + image)}
                alt={altText}
                className="img-fluid docpic"
              />
            </div>
            <div className="icon-box__info col-md-10 col-xs-12">
              <div className="row">
                <h3
                  style={{
                    fontWeight: "500",
                    lineHeight: " 1",
                    fontSize: "17px",
                    marginBottom: "10px",
                  }}
                >
                  {isDoctor && "DR."} {name}
                </h3>
              </div>
              <div className="row">{isConsultantFunc()}</div>
              <div className="row">
                <div
                  className="bio"
                  dangerouslySetInnerHTML={{ __html: qualification }}
                />
              </div>
              <div className="col-12">
                <span className="btn docreadmore ml-auto mt-20">
                  {actionTabTitle}
                </span>
              </div>
            </div>
          </div>
        </div>
      </Link>
    </>
  );
}

export default GetDoctor;
