import React from "react";
import SectionTitle from "../../UI/SectionTitle";
import SlickSlider from "../../UI/Slick";
import Founders from "./Founders";

import teamBg from "../../../assets/img/bg-blog.jpg";
import founderData from "../../../data/Founders/founders.json";

function Team() {
  const settings = {
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: false,
    autoplay: false,
    dots: true,
    className: "team-content-wrap slick-dots--light mtn-md-5",
    responsive: [
      {
        breakpoint: 1550,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 1,
          rows: 4,
        },
      },
    ],
  };
  return (
    // <div
    //   className="team-area-wrapper bg-img sp-y"
    //   style={{ backgroundImage: `url(${teamBg})` }}
    // >
    //   <div className="container-fluid">
    //     <div className="row align-items-center">
    //       <div className="col-lg-4">
    //         <SectionTitle
    //           variant="light"
    //           title="Our Board of Directors"
    //           heading=""
    //           text=""
    //           image={require("../../../assets/img/separator7.svg")}
    //         />
    //       </div>

    //       <div className="col-lg-8">
    //         <SlickSlider settings={settings}>
    //           {founderData.map((team) => (
    //             <div key={team.id}>
    //               <Founders
    //                 id={team.id}
    //                 text={team.text}
    //                 image={team.image}
    //                 name={team.name}
    //                 link={team.link}
    //               />
    //             </div>
    //           ))}
    //         </SlickSlider>
    //       </div>
    //     </div>
    //   </div>
    // </div>
    <div
      className="team-area-wrapper bg-img sp-y"
      style={{ backgroundImage: `url(${teamBg})` }}
    >
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-lg-12 text-center">
            <SectionTitle
              variant="light"
              title="Our Board of Directors"
              heading=""
              text=""
              image={require("../../../assets/img/separator7.svg")}
              titleTag="h1"
            />
            {/* <div className="col-lg-12">
              <SlickSlider settings={settings}>
                {founderData.map((team) => (
                  <div key={team.id}>
                    <Founders
                      id={team.id}
                      text={team.text}
                      image={team.image}
                      name={team.name}
                      link={team.link}
                    />
                  </div>
                ))}
              </SlickSlider>
            </div> */}
            <div className="founder-item-container">
              {founderData.map((team) => (
                <div key={team.id}>
                  <Founders
                    id={team.id}
                    text={team.text}
                    image={team.image}
                    name={team.name}
                    link={team.link}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Team;
