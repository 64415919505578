import React from "react";
import DefImage from "../../assets/img/separator1.png";

const Divider = (props) => {
  return (
    <span className="divider">
      <img src={props.image} alt="" />
    </span>
  );
};
Divider.defaultProps = {
  image: DefImage,
};
export default Divider;
