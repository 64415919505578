import React from 'react'

function BlogSchema74() {
    const blogSchemas74 = `<script type="application/ld+json">
    {
      "@context": "https://schema.org",
      "@type": "BlogPosting",
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": "https://kangaroocareindia.com/blogs/tips-for-choosing-the-best-paediatric-dermatologist/74"
      },
      "headline": "Tips For Choosing The Best Paediatric Dermatologist",
      "description": "When it comes to your child, you only want the best - food, doctor, paediatrician, and especially the paediatric dermatologist.",
      "image": "https://kangaroocareindia.com/static/media/blog-74.5f96ded31b2cc019c02d.jpg",  
      "author": {
        "@type": "Organization",
        "name": "Kangaroo care"
      },  
      "publisher": {
        "@type": "Organization",
        "name": "Kangaroo care",
        "logo": {
          "@type": "ImageObject",
          "url": "https://kangaroocareindia.com/images/kclogo-01-01.png"
        }
      },
      "datePublished": ""
    }
    </script>
    `;
    return (
      <div>
        <iframe srcDoc={blogSchemas74} title="Blog Schema" width="0" height="0" />
      </div>
    );
}

export default BlogSchema74
