import React from 'react'

function BlogSchema88() {
    const blogSchemas88 = `<script type="application/ld+json">
    {
      "@context": "https://schema.org",
      "@type": "BlogPosting",
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": "https://kangaroocareindia.com/blogs/self-care-ideas-for-pregnant-moms/88"
      },
      "headline": "Self care ideas for pregnant moms",
      "description": "People often say, a pregnant woman needs love and support from her family. And mainly focuses on babies and the development of babies.",
      "image": "https://kangaroocareindia.com/static/media/blog-88.cff537dad47747d7be49.jpg",  
      "author": {
        "@type": "Organization",
        "name": "kangaroocare",
        "url": "https://kangaroocareindia.com/"
      },  
      "publisher": {
        "@type": "Organization",
        "name": "kangaroocare",
        "logo": {
          "@type": "ImageObject",
          "url": "https://kangaroocareindia.com/images/kangaroo-care-new-logo.png"
        }
      },
      "datePublished": "2022-12-12"
    }
    </script>
       
    `;
    return (
      <div>
        <iframe srcDoc={blogSchemas88} title="Blog Schema" width="0" height="0" />
      </div>
    );
}

export default BlogSchema88
