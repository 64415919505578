import React from "react";
import SectionTitle from "../UI/SectionTitle";
import DownloadItem from "./DownloadItem";
import downloadData from "../../data/Downloads/downloads.json";

import serviceTopBg from "../../assets/img/service/service-bg-1.jpg";

function Services() {
  return (
    <div className={`service-area-wrapper`}>
      <div
        className="service-area-top"
        style={{ backgroundImage: `url("${serviceTopBg}")` }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-xl-8 m-auto text-center">
              <SectionTitle
                variant="light"
                image={require("../../assets/img/separator20.svg")}
                title="Downloads"
                heading=""
              />
            </div>
          </div>
        </div>
      </div>

      <div className="service-content-area">
        <div className="container">
          <div className="row mtn-30">
            {downloadData.map((dl) => (
              <DownloadItem
                key={dl.id}
                id={dl.id}
                title={dl.title}
                file={dl.file}
                image={dl.image}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Services;
