import React from "react";
// import {Link} from "react-router-dom";

const BlogItemList = (props) => {
  //  const blogURL = `/blog/${props.title.split(" ").join("-").toLocaleLowerCase()}?id=${props.id}`;
  const blogURL = `/blog/${props.urlIndex}`;
  return (
    <div className="blog-item">
      <div className="row align-items-center">
        <div className="col-md-5">
          <figure className="blog-thumb">
            <a href={`${process.env.PUBLIC_URL + blogURL}`}>
              <img
                src={require("../../assets/img/" + props.thumb)}
                alt={props.title}
              />
            </a>
          </figure>
        </div>

        <div className="col-md-7">
          <div className="blog-content">
            <h2 className="h5">
              <a href={`${process.env.PUBLIC_URL + blogURL}`}>{props.title}</a>
            </h2>
            <p>{props.excerpt}</p>

            <div className="blog-meta">
              <a href={`${process.env.PUBLIC_URL + blogURL}`}>
                {/* By: {props.postBy} */ }
              </a>
              <a href={`${process.env.PUBLIC_URL + blogURL}`}>{props.date}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogItemList;
