import React from "react";
import OffCanvas from "../UI/OffCanvas";
import NavbarItem from "../Header/Navbar/NavbarItem";

const MobileMenu = () => {
  return (
    <OffCanvas type="menu">
      <div className="res-mobile-menu">
        <div className="mobile-nav">
          <div className="kc-partners">
            <div className="kc-part-fertility">
              <a href="/kats">KATS</a>
            </div>

            <div className="kc-part-fertility">
              <a target="_blank" href="https://kangaroocarefertility.com/">
                Kangaroocare Fertility{" "}
              </a>
            </div>
            <div className="kc-part-premature">
              <a target="_blank" href="https://kcprematurebabies.com/">
                {" "}
                Premature Baby Foundation{" "}
              </a>
            </div>
          </div>
          <NavbarItem />
        </div>
      </div>
    </OffCanvas>
  );
};

export default MobileMenu;
