import React, { Fragment } from "react";
import Header from "../components/Header";
import PageHeader from "../components/PageHeader";
import FrequentlyAskedQuestions from "../components/Faqs";
import Footer from "../components/Footer";
import MobileMenu from "../components/MobileMenu";
import faqData from "../data/Faqs/faqs";

import FaqSEO from '../helpers/SEO/Faq';

const FrequentlyAskedQuestionsPage = () => {
  return (
    <Fragment>
      <Header />
      <PageHeader
        bgImg={require("../assets/img/careers-bg-1.jpg")}
        title="Frequently Asked Questions"
        content=""
      />
      <FrequentlyAskedQuestions data={faqData} />
      <FaqSEO/>
      <Footer />
      <MobileMenu />
    </Fragment>
  );
};

export default FrequentlyAskedQuestionsPage;
