import React from "react";
import SectionTitle from "../UI/SectionTitle";
import ServiceList from "./ServiceList";

import serviceTopBg from "../../assets/img/service/service-bg-1.jpg";

function Services() {
  return (
    <div className={`service-area-wrapper`}>
      <div
        className="service-area-top"
        style={{ backgroundImage: `url("${serviceTopBg}")` }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-xl-8 m-auto text-center">
              <SectionTitle
                variant="light"
                image={require("../../assets/img/separator5.svg")}
                title="Women & Child Care Services at Kangaroo Care Hospital"
                heading="We are staffed with experienced consultants to offer professional care in Obstetrics, Gynaecology, Neonatology, Paediatrics and other specialities and subspecialties for you and your baby. Our services for women and children are set in with trained and seasoned caregivers who ensure that you and your baby are well taken care of."
              />
            </div>
          </div>
        </div>
      </div>

      <ServiceList />
    </div>
  );
}

export default Services;
