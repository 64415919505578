import React, { Fragment } from "react";
import Header from "../components/Header";
import PageHeader from "../components/PageHeader";
import Facilities from "../components/Facilities";
import Footer from "../components/Footer";
import MobileMenu from "../components/MobileMenu";
import SEO from "../components/SEO";

const FacilitiesPage = () => {
  return (
    <Fragment>
      <Header />
      <PageHeader
        bgImg={require("../assets/img/service/service-bg-1.jpg")}
        title="World Class Facilities at Kangaroo Care Women And Children Hospitals"
      />
      <SEO />

      <Facilities />
      <Footer />
      <MobileMenu />
    </Fragment>
  );
};

export default FacilitiesPage;
