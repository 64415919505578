import React, { useState, useContext } from "react";
import styles from "./addMember.module.css";
import DatePicker from "react-datepicker";
import axios from "axios";
import BookConst from "../../../../../AppionmentProvider";
import { Button, Modal } from "react-bootstrap";
// const baseURL ='https://prod.backend.pulseright.io:3000';
const baseURL = "https://prod.backend.pulseright.io:3000";
const moment = require("moment");
export default function AddMember({ setShowMembers, org }) {
  const [presDate, setPresentDate] = useState(new Date());
  const [show, setShow] = useState(false);
  const [message, sendMessage] = useState("");
  const { regisDetails, setRegisDetails, phoneNumber } = useContext(BookConst);
  const modalClose = () => setShow(false);
  const [newMember, setNewMember] = useState({
    memberType: "",
    dateOfBirth: "",
    phoneNumber: "",
  });

  const user = JSON.parse(localStorage.getItem("user"));
  const handleChange = (e) => {
    const { name, value } = e.target;

    setNewMember((prevValue) => {
      return { ...prevValue, [name]: value };
    });
  };

  const members = ["Mother", "Father", "Son", "Daughter", "Husband", "Wife"];

  const handleSubmit = (e) => {
    e.preventDefault();

    if (presDate) {
      // 2023-06-07T12:44:12.657Z
      let date = new Date(presDate);
      let isoDate = date.toISOString();

      let day = isoDate.substring(8, 10);
      let month = isoDate.substring(5, 7);
      let year = isoDate.substring(0, 4);

      const selectedDate = `${year}-${month}-${day}`;
      const addMemberDob = {
        ...newMember,
        dateOfBirth: moment(selectedDate).format(),
        phoneNumber: "+91" + phoneNumber,
      };

      const body = {
        name: addMemberDob.patientName,
        addData: [{ memberType: addMemberDob.memberType }],
        email: user.user.email,
        gender: addMemberDob.radioGender,
        dateOfBirth: addMemberDob.dateOfBirth,
        phoneNumber: addMemberDob.phoneNumber,
        organization: org,
        userType: "Patient",
      };
      axios
        .post(baseURL + "/users/dependent/register/" + user.user._id, body)
        .then((resp) => {
          sendMessage("Member regiteration successs");
          setShow(true);
          setShowMembers(false);
        })
        .catch((err) => {
          alert(err);
        });

      setRegisDetails((prevValue) => {
        return [...prevValue, addMemberDob];
      });
    }

    if (!presDate) {
      alert("enter date ");
    }
  };

  return (
    <section>
      <section className={styles.registration_width}>
        <form
          className={styles.registration_form_container}
          onSubmit={handleSubmit}
        >
          <section className={styles.registration_form}>
            <div>
              <label htmlFor="email">Member Type</label>
              <select
                name="memberType"
                className={styles.memberType}
                required
                onChange={(e) => handleChange(e)}
              >
                <option value="" selected>
                  Select
                </option>
                {members.map((mem, index) => {
                  return <option value={mem}>{mem}</option>;
                })}
              </select>
            </div>
            <div>
              <label htmlFor="patientName">Patient Name</label>
              <input
                type="text"
                name="patientName"
                id="patientName"
                value={newMember.patientName}
                onChange={(e) => handleChange(e)}
                required
              />
            </div>

            <div className={styles.datePicker_block}>
              <label>Date of Birth</label>
              <div className={styles.datePicker}>
                <DatePicker
                  selected={presDate}
                  onChange={(date) => setPresentDate(date)}
                  dateFormat="dd/MM/yyyy"
                  showYearDropdown
                  yearDropdownItemNumber={50}
                  scrollableYearDropdown
                  placeholderText={"select date"}
                />
              </div>
            </div>
            <div className={styles.reg_form_gender}>
              <label>Gender</label>
              <div>
                {" "}
                <section>
                  <label htmlFor="radioMale">Male</label>
                  <input
                    type="radio"
                    id="radioMale"
                    value="Male"
                    name="radioGender"
                    onChange={(e) => handleChange(e)}
                    required
                  />
                </section>
                <section>
                  <label htmlFor="radioFeMale">Female</label>
                  <input
                    type="radio"
                    id="radioFeMale"
                    value="Female"
                    name="radioGender"
                    onChange={(e) => handleChange(e)}
                    required
                  />
                </section>
              </div>
            </div>
          </section>
          <div
            className={"d-flex justify-content-center align-items-center mt-2"}
          >
            <button type={"submit"} className={"btn btn-success"}>
              Add Member
            </button>
          </div>
        </form>
        <div>
          <button
            onClick={() => setShowMembers(false)}
            className={"btn btn-danger float-right"}
          >
            Close
          </button>
        </div>
      </section>
      <Modal show={show} onHide={modalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Alert</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className={styles.message}>{message}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={modalClose} className="primary">
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </section>
  );
}
{
  /* <form className={styles.registration_form}>
<div>
  <label for="patientName">Patient Name</label>
  <input type="text" name="patientName" id="patientName" />
</div>
<div>
  <label for="email">Email</label>
  <input type="email" name="email" id="email" />
</div>
<div>
  <label for="phoneNumber">Phone Number</label>
  <input
    type="tel"
    name="phoneNumber"
    pattern="^[6-9]\d{9}$"
    id="phoneNumber"
  />
</div>
<div>
  <label>Select Date</label>
  <div>
    <DatePicker
      selected={presDate}
      onChange={(date) => setPresentDate(date)}
      dateFormat="dd/MM/yyyy"
      showYearDropdown
      yearDropdownItemNumber={50}
      scrollableYearDropdown
    />
  </div>
</div>

<div className={styles.gender_container}>
  <div>
    <p>Gender</p>
  </div>
  <div>
    <label for="radioMale">Male</label>
    <input
      type="radio"
      id="radioMale"
      value="Male"
      name="radioGender"
    />
    <label for="radioFeMale">Female</label>
    <input
      type="radio"
      id="radioFeMale"
      value="Female"
      name="radioGender"
    />
  </div>
</div>
<div>
  <button> Submit</button>
</div>
</form> */
}
