import React from 'react'

function BlogSchema75() {
    const blogSchemas75 = `<script type="application/ld+json">
    {
      "@context": "https://schema.org",
      "@type": "BlogPosting",
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": "https://kangaroocareindia.com/blogs/common-health-issues-in-senior-women/75"
      },
      "headline": "Common Health Issues in Senior Women",
      "description": "Getting older is intimidating - wrinkles, fine lines, greying hair, etc.,",
      "image": "https://kangaroocareindia.com/static/media/blog-75.b44f7461c45dde10e3bd.jpg",  
      "author": {
        "@type": "Organization",
        "name": "Kangaroo care"
      },  
      "publisher": {
        "@type": "Organization",
        "name": "Kangaroo care",
        "logo": {
          "@type": "ImageObject",
          "url": "https://kangaroocareindia.com/images/kclogo-01-01.png"
        }
      },
      "datePublished": "2022-07-29"
    }
    </script>
    
    `;
    return (
      <div>
        <iframe srcDoc={blogSchemas75} title="Blog Schema" width="0" height="0" />
      </div>
    );
}

export default BlogSchema75
