import React from "react";
import ServicesData from "../../data/Services/allservices";

const ServiceContentWrap = ({ service }) => {
  return (
    <div className="col-lg-8">
      <div className="service-details-content">
        <div className="service-thumbnail-wrapper">
          <img
            src={require("../../assets/img/" + service.previewImg)}
            alt={service.title}
            className="service-img"
          />
        </div>

        <div className="service-details-info">
          <h3>{service.title}</h3>
          <div dangerouslySetInnerHTML={{ __html: service.aboutServiceDesc }} />
        </div>

        <br />
        <br />

        <div className="service-list">
          <h3>Services Offered</h3>
          <div className="row serviceRow">
            {ServicesData.map((service) => (
              <div
                key={service.id}
                className="col-lg-6 col-md-6 serviceTitleDiv"
              >
                <h6>{service.title}</h6>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceContentWrap;
