import React, { Fragment } from "react";
import Header from "../components/Header";
import PageHeader from "../components/PageHeader";
import Careers from "../components/Policy";
import Footer from "../components/Footer";
import MobileMenu from "../components/MobileMenu";
import SEO from "../components/SEO";

import CarrerList from "../data/Policy";

const CareersPage = () => {
  //  FIND PATH NAME
  let { pathname } = window.location;
  let pathnameArr = pathname.split("/");

  // console.log("pathnameArr",pathnameArr);

  let carrerData = CarrerList[0];
  // console.log(carrerData);

  let contentTitle = "PRIVACY POLICY";

  if (pathnameArr && pathnameArr[2] == "privacy_policy") {
    //  contentTitle = "Privacy Policy";
  } else {
    carrerData = CarrerList[1];
    contentTitle = "TERMS AND CONDITIONS";
  }

  return (
    <Fragment>
      <SEO />
      <Header />
      <PageHeader
        bgImg={require("../assets/img/careers-bg-1.jpg")}
        title={contentTitle}
        content=""
      />
      <Careers carrerData={carrerData} />
      <Footer />
      <MobileMenu />
    </Fragment>
  );
};

export default CareersPage;
