import React from "react";

const BlogSchema29 = () => {
  const blogSchemas29 = `<script type="application/ld+json">
{
  "@context": "https://schema.org",
  "@type": "BlogPosting",
  "mainEntityOfPage": {
    "@type": "WebPage",
    "@id": "https://kangaroocareindia.com/blogs/soon-to-be-mom-tips-for-healthy-pregnancy/29"
  },
  "headline": "Soon To Be Mom.! Tips For Healthy Pregnancy",
  "description": "Some important pregnancy tips that soon to be mom must adopt which can help ensure safe and healthy prenatal development:",
  "image": "https://kangaroocareindia.com/static/media/soontobemom-01.4d8edc87.jpg",  
  "author": {
    "@type": "Organization",
    "name": "Kangaroo Care"
  },  
  "publisher": {
    "@type": "Organization",
    "name": "Kangaroo Care",
    "logo": {
      "@type": "ImageObject",
      "url": "https://kangaroocareindia.com/images/kc-logo-pink.png"
    }
  },
  "datePublished": "2021-07-08"
}
</script>


 `;

  return (
    <div>
      <iframe srcDoc={blogSchemas29} title="Blog Schema" width="0" height="0" />
    </div>
  );
};

export default BlogSchema29;
