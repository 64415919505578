import React, { Fragment } from "react";
// import { Link } from "react-router-dom";

function Adminteam(props) {
  const teamMemberURl = `${props.link}`;
  return (
    <Fragment>
      <div className="team-mem-item">
        <figure className="member-pic">
          <a href={`${process.env.PUBLIC_URL + teamMemberURl}`}>
            <img
              src={require("../../../assets/img/" + props.image)}
              alt={props.name + " - " + props.text}
            />
          </a>
        </figure>
        <div className="member-info">
          <h5>
            <a href={`${process.env.PUBLIC_URL + teamMemberURl}`}>
              {props.name}
            </a>
          </h5>
          <span className="designation">{props.text}</span>
          <br />
          <span className="designation">{props.place}</span>
        </div>
      </div>
    </Fragment>
  );
}

export default Adminteam;
