import React, { Fragment, useState, useEffect } from "react";
import { Form, ListGroup } from "react-bootstrap";

//  DATA
import Dept from "../../data/Dept";
import Doctors from "../../data/Doctors/all-doctors.json";

import { doctorDeptListBangalore } from "../../components/Doctors/departments/deptList";
import { doctorDeptListRamanagar } from "../../components/Doctors/departments/ramanagarDeptList";
import { doctorDeptListNagaraBhavi } from "../../components/Doctors/departments/deptListNagarabhavi";

const FindDoctor = () => {
  const [inputSearch, setInputSearch] = useState("");
  const [filteredDoctorList, setFilteredDoctorList] = useState([]);
  const [doctorList, setDoctorList] = useState([]);
  const [deptList, setDeptList] = useState(null);
  const [cityList, setCityList] = useState([
    {
      id: 1,
      city: "bangalore",
      cityName: "Bangalore",
    },
    {
      id: 2,
      city: "mysore",
      cityName: "Mysore",
    },
    {
      id: 3,
      city: "ramanagara",
      cityName: "Ramanagara",
    },
    {
      id: 4,
      city: "kangaroocare-nagarabhavi-clinic",
      cityName: "Kangaroocare Nagarabhavi Clinic",
    },
  ]);

  const [selectedCity, setSelectedCity] = useState(null);

  useEffect(() => {
    async function filterDoctor() {
      const docList = await Doctors.filter((docData) => {
        let { showDoctor = true } = docData;
        if (showDoctor) return docData;
      }).map((docData) => {
        let { firstname, lastname, id } = docData;
        let name = firstname;
        if (lastname && lastname !== ".") {
          name = name + " " + lastname;
        }
        docData.nameUpper = name;

        const docUrl = `/doctor/${name
          .split(" ")
          .join("-")
          .toLocaleLowerCase()}/${id}`;
        docData.docUrl = docUrl;

        name = name.toLowerCase();
        docData.name = name;
        return docData;
      });

      setDoctorList(docList);
    }
    filterDoctor();
  }, []);

  const filterDoctorList = (name) => {
    if (name !== "") {
      name = name.toLowerCase();

      async function filterData() {
        let docList = [];
        if (doctorList.length > 0) {
          docList = await doctorList.filter((o) =>
            Object.keys(o).some((k) => o["name"].includes(name))
          );
        }
        setFilteredDoctorList(docList);
      }
      filterData();
    } else {
      setFilteredDoctorList([]);
    }
  };

  const inputChange = (event) => {
    setInputSearch(event.target.value);
    filterDoctorList(event.target.value);
  };

  const citySelect = (event) => {
    setSelectedCity(event.target.value);
  };

  const branchLinksWithDepts = {
    bangalore: doctorDeptListBangalore,
    mysore: doctorDeptListBangalore,
    ramanagara: doctorDeptListRamanagar,
    "kangaroocare-nagarabhavi-clinic": doctorDeptListNagaraBhavi,
  };
  function filterDocotrByBranch(event) {
    let value = event;

    let branchData = branchLinksWithDepts[value];
    let allData = Dept;

    const filterArray = (arr1, arr2) => {
      return arr1.filter((obj1) =>
        arr2.some((obj2) => obj2.dept === obj1.dept)
      );
    };
    let filteredArray = filterArray(branchData, allData);
    setDeptList(filteredArray);
  }

  useEffect(() => {
    if (selectedCity) {
      filterDocotrByBranch(selectedCity);
    }
  }, [selectedCity]);
  //  SELECT BOX
  const redirectSelect = (event) => {
    let { value } = event.target;
    if (value) {
      let deptData = Dept.find((deptData) => deptData.id == value);
      let url = `doctors/${selectedCity}/${deptData.newLink}-in-${selectedCity}`;
      window.location.replace(url);
    }
  };

  return (
    <Fragment>
      <div className="findDoctorDiv pinkBackground">
        <div className="container">
          <div className="row mtn-30">
            <h2 className="col-12 findDocText">Find Doctors</h2>

            <div className="col-12">
              <div className="row">
                <div className="col-lg-4">
                  <Form.Control
                    as="select"
                    className="my-1 mr-sm-2 chooseSpecialitySelect"
                    custom
                    onChange={citySelect}
                    // onChange={filterDocotrByBranch}
                  >
                    <option value="0" hidden>
                      Choose Speciality
                    </option>
                    {cityList?.map(({ id, city, cityName }) => (
                      <option className="selectOption" key={id} value={city}>
                        {cityName}
                      </option>
                    ))}
                  </Form.Control>
                </div>
                <div className="col-lg-4">
                  <Form.Control
                    as="select"
                    className="my-1 mr-sm-2 chooseSpecialitySelect"
                    custom
                    onChange={redirectSelect}
                  >
                    <option value="0" hidden>
                      Choose Speciality
                    </option>
                    {deptList?.map(({ id, deptTitle }) => (
                      <option className="selectOption" key={id} value={id}>
                        {deptTitle}
                      </option>
                    ))}
                  </Form.Control>
                </div>
                <div className="col-lg-4">
                  <Form.Control
                    type="text"
                    placeholder="Search Doctors"
                    className="my-1 mr-sm-2 chooseSpecialityInput"
                    value={inputSearch}
                    onChange={inputChange}
                  />
                  {doctorList.length > 0 && (
                    <Fragment>
                      <ListGroup className="chooseSpecialityListGroup">
                        {filteredDoctorList.map((doc) => (
                          <ListGroup.Item
                            key={doc.id}
                            action
                            href={doc.docUrl}
                            className="chooseSpecialityListBox"
                          >
                            {doc.nameUpper} - {doc.title}
                          </ListGroup.Item>
                        ))}
                      </ListGroup>
                    </Fragment>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default FindDoctor;
