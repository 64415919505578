import React, { Fragment } from "react";
import Header from "../components/Header";
import PageHeader from "../components/PageHeader";
import Sitemap from "../components/Sitemap";
import Footer from "../components/Footer";
import MobileMenu from "../components/MobileMenu";
import SEO from '../components/SEO';

const SitemapPage = () => {
  return (
    <Fragment>
      <SEO/>
      <Header />
      <PageHeader
        bgImg={require("../assets/img/careers-bg-1.jpg")}
        title="Sitemap"
        content=""
      />
      <Sitemap />
      <Footer />
      <MobileMenu />
    </Fragment>
  );
};

export default SitemapPage;
