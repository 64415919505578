import React from "react";

const BlogSchema31 = () => {
  const blogSchemas31 = `<script type="application/ld+json">
{
  "@context": "https://schema.org",
  "@type": "BlogPosting",
  "mainEntityOfPage": {
    "@type": "WebPage",
    "@id": "https://kangaroocareindia.com/blogs/things-you-need-to-know-about-fertility-treatments/31"
  },
  "headline": "Things You Need To Know About Fertility Treatments",
  "description": "Infertility is the inability to conceive or to carry the pregnancy to full term. With various fertility treatments, there is a better chance of being able to conceive.",
  "image": "https://kangaroocareindia.com/static/media/blog-31.38036cde.jpg",  
  "author": {
    "@type": "Organization",
    "name": "Kangaroo Care"
  },  
  "publisher": {
    "@type": "Organization",
    "name": "Kangaroo Care",
    "logo": {
      "@type": "ImageObject",
      "url": "https://kangaroocareindia.com/images/kc-logo-pink.png"
    }
  },
  "datePublished": "2021-07-20"
}
</script>

 `;

  return (
    <div>
      <iframe srcDoc={blogSchemas31} title="Blog Schema" width="0" height="0" />
    </div>
  );
};

export default BlogSchema31;
