import React from "react";

const DocMoreInfo = ({ qualification, consultations, hospitals, dept, bookLink, docId, doctorName }) => {
  const hosp = (hospitals) => {
    switch (hospitals) {
      case "kcblr":
        return "Bangalore";
      case "kcmys":
        return "Mysore";
      default:
        return "Bangalore";
    }
  };

  const perHour = (count) => {
    return 60 / parseInt(count);
  };

  return (
    <div style={{ color: "#848484" }}>
      <div className="row">
        <div className="container">
          <p dangerouslySetInnerHTML={{ __html: qualification }} />
        </div>
      </div>
      <div className="row ts30">
        <div className="col-4">Department : </div>
        <div className="col-8" style={{ textTransform: "capitalize" }}>
          {dept.replace(/_/g, " ")}
        </div>
      </div>
      <div className="row ts30">
        <div className="col-4">Consultations : </div>
        <div className="col-8">
          {perHour(consultations)} Minutes per Consultation (Average)
        </div>
      </div>
      <div className="row ts30">
        <div className="col-4">Location : </div>
        <div className="col-8 ">{hosp(hospitals)}</div>
      </div>
    </div>
  );
};

export default DocMoreInfo;
