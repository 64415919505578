import React, { Component } from "react";
//  import logo from "../../assets/img/kc-logo-pink.png";
import { Link } from "react-router-dom";

class Logo extends Component {
  render() {
    return (
      <div className="logo-area">
        <Link to="/" title="Kangaroo Care - Women & Child Hospital">
          <img
            src="/images/kangaroo-care-new-logo.png"
            alt="Kangaroo Care - Women & Child Hospital"
            className="img-fluid"
          />
        </Link>
      </div>
    );
  }
}

export default Logo;
