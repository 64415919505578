import React, { useEffect } from "react";
import RedirectinalArray from "./RedirectionLinks";
// import { useLocation } from "react-router-dom";
export default function Redirection() {
  //   const { location } = useLocation();
  const { pathname } = window.location;

  function removeSlash(link) {
    if (link.slice(-1) === "/") {
      return link.slice(0, -1);
    } else {
      return link;
    }
  }

  useEffect(() => {
    let webLink = removeSlash(window.location.pathname);
    RedirectinalArray.map((item) => {
      if (item.link.includes(webLink)) {
        window.location.href = item.redirect;
      }
    });
  }, []);
  return null;
}
