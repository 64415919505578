import React from "react";
import Doctor from "../../components/Doctors/Doctor";

const ServiceContentWrap = ({ service }) => {
  return (
    <div className="col-lg-8">
      <div className="service-details-content">
        <div className="service-thumbnail-wrapper">
          <img
            src={require("../../assets/img/" + service.previewImg)}
            alt={service.title}
            className="service-img"
          />
        </div>

        <div className="service-details-info">
          <h2>{service.title}</h2>
          <div dangerouslySetInnerHTML={{ __html: service.aboutServiceDesc }} />
        </div>
{/* 
        <div className="row mtn-30">
          {doctorList.map((doc) => (
            <Doctor
              key={doc.id}
              type={"page"}
              id={doc.id}
              firstname={doc.firstname}
              lastname={doc.lastname}
              designation={doc.title}
              profilePic={doc.image}
              bio={doc.bio}
              isDoctor={(doc.isDoctor !== undefined)?doc.isDoctor:true}
            />
          ))}
        </div> */}
      </div>
    </div>
  );
};

export default ServiceContentWrap;
