import React from "react";

const BlogSchema51 = () => {
  const blogSchemas51 = `
  <script type="application/ld+json">
{
  "@context": "https://schema.org",
  "@type": "BlogPosting",
  "mainEntityOfPage": {
    "@type": "WebPage",
    "@id": "https://kangaroocareindia.com/blogs/hpv-awareness-knowledge-and-vaccination/51"
  },
  "headline": "HPV Awareness, Knowledge and Vaccination",
  "description": "Across the globe, about 5.2% of all cancers can be attributed to HPV infection. According to the World Health Organization, the frequency of HPV infection is between nine and thirteen percent. HPV is a viral infection that generally causes the growth of warts on the skin. There are around 100+ forms of human papillomavirus, out of which few forms of HPV infection cause warts while others cause different types of cancer.",
  "image": "https://kangaroocareindia.com/static/media/blog-51.4fe81a5b6af3a8764298.jpeg",  
  "author": {
    "@type": "Organization",
    "name": "Kangaroo Care",
    "url": "https://kangaroocareindia.com/"
  },  
  "publisher": {
    "@type": "Organization",
    "name": "Kangaroo Care",
    "logo": {
      "@type": "ImageObject",
      "url": ""
    }
  },
  "datePublished": "2022-01-11"
}
</script>

 `;

  return (
    <div>
      <iframe srcDoc={blogSchemas51} title="Blog Schema" width="0" height="0" />
    </div>
  );
};

export default BlogSchema51;
