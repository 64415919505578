import React from "react";

const NICUInfantCareFAQ = () => {
  const infantSchema = `
  
<script type="application/ld+json">
{
  "@context": "https://schema.org",
  "@type": "FAQPage",
  "mainEntity": [{
    "@type": "Question",
    "name": "Why is infant care important?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Early childhood care plays an important role in children’s development: mental, physical, and emotional as well. It provides valuable support to the children and significantly reduces the risk of chronic diseases. You can book your appointment at Kangaroo Care Hospital which is a pediatric hospital near Rajajinagar, West Bangalore."
    }
  },{
    "@type": "Question",
    "name": "When should I take my baby to a pediatrician?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "The first visit to a pediatrician should be done within 3-5 days of birth. Then schedule the next appointment according to you or the doctor will suggest the specific time slots depending on the health condition of a newborn baby."
    }
  },{
    "@type": "Question",
    "name": "What is pediatric care?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Pediatric care refers to the medical diagnosis and treatment of children, which can be varied from child to child according to the health condition, till the age of 16."
    }
  },{
    "@type": "Question",
    "name": "What are the care needs of a child?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Childhood is the best time to take care of your child. They require certain basic needs that must be provided to ensure their optimal development and well-being that mainly includes encouragement, a sense of responsibility, affection, and a chance to learn and explore their environment."
    }
  },{
    "@type": "Question",
    "name": "What care do newborn babies require?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Newborn babies are supposed to be handled with utmost care. You have to take care of their feeding schedule, sleeping patterns, health, bathing, and diapering."
    }
  },{
    "@type": "Question",
    "name": "Where can you get the best pediatric care in Bangalore?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "At Kangaroo Care, we have a team of experienced and qualified pediatricians who are well trained in managing childhood-related problems and also staff to handle any type of emergency cases round the clock."
    }
  },{
    "@type": "Question",
    "name": "Where can you get good pediatricians in Bangalore?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "At Kangaroo Care, we have well-qualified pediatricians and pediatric subspecialists to diagnose and treat a broad range of childhood-related diseases. Our pediatricians are available 24*7 to handle children with emergency conditions such as seizures, asthma, injuries, trauma, and infections."
    }
  },{
    "@type": "Question",
    "name": "What are the best newborn baby care tips for the age of 0–8 months?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "As a new parent, you will discover how to take care of your baby. You have to pay attention to some common baby care tips such as feeding, bathing, diapering, weaning, immunization, regular health checkups, growth, and so on for the first few months."
    }
  }

  ]
}
</script>
 `;

  return (
    <div>
      <iframe
        srcDoc={infantSchema}
        title="Infant Structured Schema"
        width="0"
        height="0"
      />
    </div>
  );
};

export default NICUInfantCareFAQ;
