import React from "react";

const NICUFertilityFAQ = () => {
  const fertilitySchema = `
  <script type="application/ld+json">
  {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": [{
      "@type": "Question",
      "name": "What is IVF?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "IVF stands for In-Vitro Fertilization, it is the method of fertilizing an egg, where eggs combined with the sperm outside the body, that is in vitro. It is one of the well known types of assisted reproductive technology."
      }
    },{
      "@type": "Question",
      "name": "Which is the best fertility center near Rajajinagar Bangalore?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Kangaroo Care is known as the best fertility center near Rajajinagar Bangalore, as it provides a wide range of facilities to cater the couples in need."
      }
    },{
      "@type": "Question",
      "name": "How much does IVF cost in Bangalore?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "The IVF treatment in Bangalore can primarily depend on a person, as it varies from one person to another person. The cost of IVF is around 1,60,000 to 1,75,000. Visit Kangaroo Care for the best IVF treatment at the most affordable cost."
      }
    },{
      "@type": "Question",
      "name": "Which month is best for IVF?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Increased levels of gonadotropin-releasing hormones can make pregnancy much easier for women through IVF. During spring, that is from Mid February to Mid April, the brain produces higher levels of these hormones."
      }
    },{
      "@type": "Question",
      "name": "Can I get fertility treatment to have twins?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Yes, through fertility treatment like Clomid, Gonal-F, and Follistim it’s more likely to conceive multiples. Other factors like age, weight, height, and family history of having twins can increase the odds of having twins."
      }
    },{
      "@type": "Question",
      "name": "How does IVF improve fertility?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "In vitro fertilization can overcome most causes of male and female infertility. IVF has a high rate of success because it allows for a controlled interaction of eggs and sperm. Medications increase the number of mature eggs a woman can produce at a single time. This process has higher chances of women getting pregnant."
      }
    },{
      "@type": "Question",
      "name": "How long does IVF take to get pregnant?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "During IVF, mature eggs are collected (retrieved) from ovaries and fertilized by sperm in a lab. Then the fertilized egg (embryo) or eggs (embryos) are transferred to a uterus. One full cycle of IVF takes about two months."
      }
    },{
      "@type": "Question",
      "name": "What are the 4 stages of IVF?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Stage 1: Ovarian stimulation, monitoring, and ovulation triggering. Having a greater number of mature eggs available for fertilization increases the chances of pregnancy. Stage 2: Egg retrieval (Egg Pick Up ) Stage 3: Fertilisation Stage 4: Embryo"
      }
    }
  ]
  }
  </script> `;

  return (
    <div>
      <iframe
        srcDoc={fertilitySchema}
        title="Fertility Structured Schema"
        width="0"
        height="0"
      />
    </div>
  );
};

export default NICUFertilityFAQ;
