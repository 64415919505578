import React, { Fragment } from "react";

import EventList from "../../data/Camp/index.json";

const EventContent = () => {
  const EventDetail = ({ event }) => {
    let { title, period, image } = event;
    // console.log(title);
    return (
      <Fragment>
        <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
          <div className="innerDiv">
            <div className="hovereffect">
              <img
                className="img-responsive"
                alt={title}
                src={require(`../../assets/img/media/camp/${image}`)}
              />
              <div className="overlay">
                <h2>{title}</h2>
                <p>{period}</p>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  };

  return (
    <Fragment>
      <div className="container mediaEventDiv">
        <div className="row">
          {EventList.map((event) => (
            <EventDetail key={event.id} event={event} />
          ))}
        </div>
      </div>
    </Fragment>
  );
};

export default EventContent;
