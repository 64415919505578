import React from "react";

const DoctorsSchema = () => {
  const doctorSchema1 = ` 
  <script type='application/ld+json'>
    {
        "@context": "https://www.schema.org",
        "@type": "Physician",
        "name": "Dr.Thrupti.S",
        "url": "https://kangaroocareindia.com/doctor/thrupti-s/102",
        "logo": "https://kangaroocareindia.com/images/kangaroo-care-new-logo.png",
        "image": "https://kangaroocareindia.com/static/media/dr-thrupthi-new.0faf7b7fc188451aa9ce.JPG",
        "description": "Dr.Thrupti.S, Pediatric endocrinologist at Kangaroo care hospital, Mysore. She has completed MBBS from Mysore Medical College & Research Institute.",
        "address":{
        "@type":"PostalAddress",
        "addressLocality":"Mysore",
        "addressCountry":"IND"
        } ,   
        "telephone": "1800-425-4500",
        "medicalSpecialty":"PEDIATRIC ENDOCRINOLOGIST"
        }
    }
    </script>
`;
  const doctorSchema2 = `<script type='application/ld+json'> 
  {
      "@context": "https://www.schema.org",
      "@type": "Physician",
      "name": "Dr. Shekar Subbaiah",
      "url": "https://kangaroocareindia.com/doctor/shekar-subbaiah/1",
      "logo": "https://kangaroocareindia.com/images/kangaroo-care-new-logo.png",
      "image": "https://kangaroocareindia.com/static/media/shekar-subbaiah.211c981f4117295a51ab.jpg",
      "description": "Dr. Shekar Subbaiah is the Neonatology and Paediatrics specialist at Kangaroo Care Hospital. Book an instant appointment with the best neonatologist. Call Today",
      "address":{
      "@type":"PostalAddress",
      "addressLocality":"Bangalore",
      "addressCountry":"IND"
      } ,   
      "telephone": "1800-425-4500",
      "medicalSpecialty":"NEONATOLOGIST"
      }
      </script>
`;
  const doctorSchema3 = `<script type='application/ld+json'>
  {
      "@context": "https://www.schema.org",
      "@type": "Physician",
      "name": "Dr. RAMAPRIYA KALKUNTE",
      "url": "https://kangaroocareindia.com/doctor/ramapriya-kalkunte/3",
      "logo": "https://kangaroocareindia.com/",
      "image": "https://kangaroocareindia.com/static/media/dr-ramapriya-kalkunte-new.8ae24c494a31bd9e1f97.png",
      "description": "Dr. Ramapriya Kalkunte is the best pediatrician with more than 15 years of expertise in Respiratory Paediatrics and hands-on experience in neonatal care. Call Today!!",
      "address":{
      "@type":"PostalAddress",
      "addressLocality":"Bangalore",
      "addressCountry":"IND"
      } ,   
      "telephone": "1800-425-4500",
      "medicalSpecialty":"PEDIATRICIAN AND NEONATOLOGIST"
      }
  }
  </script>
   `;
  const doctorSchema4 = `<script type='application/ld+json'>
  {
      "@context": "https://www.schema.org",
      "@type": "Physician",
      "name": "Dr. JAVGAL S SURAJ",
      "url": "https://kangaroocareindia.com/doctor/javgal-s-suraj/4",
      "logo": "https://kangaroocareindia.com/images/kangaroo-care-new-logo.png",
      "image": "https://kangaroocareindia.com/static/media/dr-javgal-suraj-new.d1f19ddd085ea7dfff53.png",
      "description": "Dr. Javgal S Suraj is the best paediatric intensivist with 14 years of expertise in paediatric Intensive care. Book Instant Online Appointment. Call Us Today!!",
      "address":{
      "@type":"PostalAddress",
      "addressLocality":"Bangalore",
      "addressCountry":"IND"
      } ,   
      "telephone": "1800-425-4500",
      "medicalSpecialty":"PAEDIATRICIAN AND PEDIATRIC INTENSIVIST"
      }
  }
  </script>
   `;
  const doctorSchema5 = `<script type='application/ld+json'>
  {
      "@context": "https://www.schema.org",
      "@type": "Physician",
      "name": "Dr. KISHORE YERUR",
      "url": "https://kangaroocareindia.com/doctor/kishore-yerur/5",
      "logo": "https://kangaroocareindia.com/images/kangaroo-care-new-logo.png",
      "image": "https://kangaroocareindia.com/static/media/dr-kishore-yeruru-new.c12a9a0aa98e60e2eb14.png",
      "description": "Dr. Kishore Yerur is a well-known Paediatrician at Kangaroo Care Hospital. Book Instant Online Appointment. Call Us Today!!",
      "address":{
      "@type":"PostalAddress",
      "addressLocality":"Bangalore",
      "addressCountry":"IND"
      } ,   
      "telephone": "1800-425-4500",
      "medicalSpecialty":"PEDIATRICIAN AND NEONATOLOGIST"
      }
  }
  </script>
   `;
  const doctorSchema6 = ` 
  <script type='application/ld+json'>
{
    "@context": "https://www.schema.org",
    "@type": "Physician",
    "name": "Dr. SUMAN",
    "url": "https://kangaroocareindia.com/doctor/suman/113",
    "logo": "https://kangaroocareindia.com/images/kangaroo-care-new-logo.png",
    "image": "https://kangaroocareindia.com/static/media/dr-suman-new.fe95c60a0009a59fb8df.png",
    "description": "Suman, Pediatric Dermatologist, She has 7+ years of experience in this field and has completed her MBBS from Bidar Institute of Medical Sciences, Bidar. ",
    "address":{
    "@type":"PostalAddress",
    "addressLocality":"Bangalore",
    "addressCountry":"IND"
    } ,   
    "telephone": "1800-425-4500",
    "medicalSpecialty":"PEDIATRIC DERMATOLOGIST"
    }
}
</script>
`;
  const doctorSchema7 = ` <script type='application/ld+json'>
  {
      "@context": "https://www.schema.org",
      "@type": "Physician",
      "name": "Dr. SOWMYA ARUDI NAGARAJAN",
      "url": "https://kangaroocareindia.com/doctor/sowmya-arudi-nagarajan/7",
      "logo": "https://kangaroocareindia.com/images/kangaroo-care-new-logo.png",
      "image": "https://kangaroocareindia.com/static/media/dr.sowmyaa.c171c787364dc398e324.png",
      "description": "Dr. Sowmya Arudi Nagarajan is a Paediatric Immunologist and Allergist has over 14 years of experience",
      "address":{
      "@type":"PostalAddress",
      "addressLocality":"Bangalore",
      "addressCountry":"IND"
      } ,   
      "telephone": "1800-425-4500",
      "medicalSpecialty":"paediatric allergy specialist"
      }
  }
  </script>
  `;
  const doctorSchema8 = ` <script type='application/ld+json'>
  {
      "@context": "https://www.schema.org",
      "@type": "Physician",
      "name": "Dr. ARUN RAYKAR",
      "url": "https://kangaroocareindia.com/doctor/arun-raykar/8",
      "logo": "https://kangaroocareindia.com/images/kangaroo-care-new-logo.png",
      "image": "https://kangaroocareindia.com/static/media/drarunraykarnew.82f8c13034682adc8df7.png",
      "description": "Dr. Arun Raykar is the well-known Paediatric ENT Specialist in Bangalore with over 9 years of experience in the treatment of ENT related issues in children.",
      "address":{
      "@type":"PostalAddress",
      "addressLocality":"Bangalore",
      "addressCountry":"IND"
      } ,   
      "telephone": "1800-425-4500",
      "medicalSpecialty":"paediatric ent specialist"
      }
  }
  </script>
  `;
  const doctorSchema9 = ` <script type='application/ld+json'>
  {
      "@context": "https://www.schema.org",
      "@type": "Physician",
      "name": "Dr. NAGENDRA KUMAR",
      "url": "https://kangaroocareindia.com/doctor/nagendra-kumar/11",
      "logo": "https://kangaroocareindia.com/images/kangaroo-care-new-logo.png",
      "image": "https://kangaroocareindia.com/static/media/nagendra-kumar-new.534365f740932a1357f2.png",
      "description": "Dr. Nagendra Kumar is the best Paediatric Gastroenterologist in Bangalore with rich experience in treating digestive disorders from acute, chronic, low, or high acuity problems.",
      "address":{
      "@type":"PostalAddress",
      "addressLocality":"Bangalore",
      "addressCountry":"IND"
      } ,   
      "telephone": "1800-425-4500",
      "medicalSpecialty":"paediatric gastroenterologist"
      }
  }
  </script>
  `;
  const doctorSchema10 = `<script type='application/ld+json'>
  {
      "@context": "https://www.schema.org",
      "@type": "Physician",
      "name": "Dr. JAYA KIRAN",
      "url": "https://kangaroocareindia.com/doctor/jaya-kiran/12",
      "logo": "https://kangaroocareindia.com/images/kangaroo-care-new-logo.png",
      "image": "https://kangaroocareindia.com/static/media/jaya-kiran-new.97bb1904b52fce841b1f.jpg",
      "description": "Dr. Jaya Kiran is the best Paediatric Dentist in Bangalore with expertise in the treatment of oral diseases and quality therapeutic oral health care solutions for children.",
      "address":{
      "@type":"PostalAddress",
      "addressLocality":"Bangalore",
      "addressCountry":"IND"
      } ,   
      "telephone": "1800-425-4500",
      "medicalSpecialty":"paediatric dentist"
      }
  }
  </script>
   `;

  return (
    <div>
      <iframe srcDoc={doctorSchema1} title="Blog Schema" width="0" height="0" />
      <iframe srcDoc={doctorSchema2} title="Blog Schema" width="0" height="0" />
      <iframe srcDoc={doctorSchema3} title="Blog Schema" width="0" height="0" />
      <iframe srcDoc={doctorSchema4} title="Blog Schema" width="0" height="0" />
      <iframe srcDoc={doctorSchema5} title="Blog Schema" width="0" height="0" />
      <iframe srcDoc={doctorSchema6} title="Blog Schema" width="0" height="0" />
      <iframe srcDoc={doctorSchema7} title="Blog Schema" width="0" height="0" />
      <iframe srcDoc={doctorSchema8} title="Blog Schema" width="0" height="0" />
      <iframe srcDoc={doctorSchema9} title="Blog Schema" width="0" height="0" />
      <iframe
        srcDoc={doctorSchema10}
        title="Blog Schema"
        width="0"
        height="0"
      />
    </div>
  );
};

export default DoctorsSchema;
