import React, { Fragment } from "react";
import Header from "../components/Header";
import PageHeader from "../components/PageHeader";
import Careers from "../components/Careers";
import Footer from "../components/Footer";
import MobileMenu from "../components/MobileMenu";
import SEO from "../components/SEO";

const CareersPage = () => {
  return (
    <Fragment>
      <SEO />
      <Header />
      <PageHeader
        bgImg={require("../assets/img/careers-bg-1.jpg")}
        title="Careers at Kangaroo Care Women And Children Hospitals"
        content=""
      />
      <Careers />
      <Footer />
      <MobileMenu />
    </Fragment>
  );
};

export default CareersPage;
