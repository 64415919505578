import React from "react";

const BlogSchema45 = () => {
  const blogSchemas45 = `

  <script type="application/ld+json">
  {
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": "https://kangaroocareindia.com/blogs/healthy-feeding-for-preemies-for-their-healthy-growth/45"
    },
    "headline": "Healthy Feeding For Preemies For Their Healthy Growth",
    "description": "Healthy feeding for healthy growth.! Whether it is a term baby or a preterm baby, additional nutritional needs are a must for healthy development. But premature babies may require additional care. Let’s see what can be fed to premature babies;",
    "image": "https://kangaroocareindia.com/static/media/blog-45.c814ad39928a917a7e7a.jpg",  
    "author": {
      "@type": "Organization",
      "name": "Kangaroo Care",
      "url": "https://kangaroocareindia.com/"
    },  
    "publisher": {
      "@type": "Organization",
      "name": "Kangaroo Care",
      "logo": {
        "@type": "ImageObject",
        "url": ""
      }
    },
    "datePublished": "2021-11-17"
  }
  </script>
  
 `;

  return (
    <div>
      <iframe srcDoc={blogSchemas45} title="Blog Schema" width="0" height="0" />
    </div>
  );
};

export default BlogSchema45;
