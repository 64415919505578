import React, { Fragment } from "react";
import Header from "../components/Header";
import PageHeader from "../components/PageHeader";
import Appointment from "../templates/Appointment/Pulsersight/Appointment";
import Footer from "../components/Footer";
import LoginRegister from "../components/LoginRegister";
import MobileMenu from "../components/MobileMenu";
import AppointmentBranch from "../templates/Appointment/Pulsersight/AppointemntBranch";

const SingleAppointmentTest = () => {
  return (
    <Fragment>
      <Header />
      {/* <PageHeader
        bgImg={require("../assets/img/doctors-bg.jpg")}
        title="Book Appointment"
        content=""
      /> */}
      <AppointmentBranch />
      <Footer />
      <LoginRegister />
      <MobileMenu />
    </Fragment>
  );
};

export default SingleAppointmentTest;
