import React from 'react'

function BlogSchema84() {
    const blogSchemas84 = `<script type="application/ld+json">
    {
      "@context": "https://schema.org",
      "@type": "BlogPosting",
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": "https://kangaroocareindia.com/blogs/superfoods-to-avoid-major-gynecological-issues/84"
      },
      "headline": "Superfoods To Avoid Major Gynecological Issues",
      "description": "Unlike male body, the female body is unique, sensitive, and has different functionalities. Every woman is required to take proper care of her body to maintain a healthy life cycle.",
      "image": "https://kangaroocareindia.com/static/media/blog-84.9cc4e8ce3f6eeb36c2bd.jpg",  
      "author": {
        "@type": "Organization",
        "name": "kangaroocare",
        "url": "https://kangaroocareindia.com/"
      },  
      "publisher": {
        "@type": "Organization",
        "name": "kangaroocare",
        "logo": {
          "@type": "ImageObject",
          "url": "https://kangaroocareindia.com/images/kangaroo-care-new-logo.png"
        }
      },
      "datePublished": "2022-11-11"
    }
    </script>       
    `;
    return (
      <div>
        <iframe srcDoc={blogSchemas84} title="Blog Schema" width="0" height="0" />
      </div>
    );
}

export default BlogSchema84
