import React from 'react'

function BlogSchema89() {
    const blogSchemas89 = `<script type="application/ld+json">
    {
      "@context": "https://schema.org",
      "@type": "BlogPosting",
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": "https://kangaroocareindia.com/blogs/5-tips-to-control-hormonal-changes-in-pregnancy/89"
      },
      "headline": "5 Tips To Control Hormonal Changes In Pregnancy",
      "description": "A pregnant woman undergoes many changes, including hormonal changes during pregnancy to aid in the development of the baby's growth. Hormonal changes are essential to both fertility and fetus development.",
      "image": "https://kangaroocareindia.com/static/media/blog-89.c1d16c1a78ed0977c763.jpg",  
      "author": {
        "@type": "Organization",
        "name": "Kangaroocare",
        "url": "https://kangaroocareindia.com/"
      },  
      "publisher": {
        "@type": "Organization",
        "name": "Kangaroocare",
        "logo": {
          "@type": "ImageObject",
          "url": "https://kangaroocareindia.com/images/kangaroo-care-new-logo.png"
        }
      },
      "datePublished": "2022-12-26"
    }
    </script>    
    `;
    return (
      <div>
        <iframe srcDoc={blogSchemas89} title="Blog Schema" width="0" height="0" />
      </div>
    );
}

export default BlogSchema89
