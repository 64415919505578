import React from "react";

const BlogSchema50 = () => {
  const blogSchemas50 = `
  <script type="application/ld+json">
{
  "@context": "https://schema.org",
  "@type": "BlogPosting",
  "mainEntityOfPage": {
    "@type": "WebPage",
    "@id": "https://kangaroocareindia.com/blogs/winter-care-tips-during-pregnancy/50"
  },
  "headline": "Winter Care Tips During Pregnancy!",
  "description": "The period of Pregnancy/Gestation is one of the most instigative and pleasurable events in every woman's life. Winter months can be harsh and being pregnant during these cold dreary months can be hard, but it's easier than you think to stay comfortable and healthy.
Frequently, women wonder whether it can negatively affect their pregnancy. Wintertime is full of pleasurable conditioning, you can enjoy it during the period of gestation.",
  "image": "https://kangaroocareindia.com/static/media/blog-50.1dfb970fdc78d737fa14.jpeg",  
  "author": {
    "@type": "Organization",
    "name": "Kangaroo Care",
    "url": "https://kangaroocareindia.com/"
  },  
  "publisher": {
    "@type": "Organization",
    "name": "Kangaroo Care",
    "logo": {
      "@type": "ImageObject",
      "url": ""
    }
  },
  "datePublished": "2021-01-03"
}
</script>

 `;

  return (
    <div>
      <iframe srcDoc={blogSchemas50} title="Blog Schema" width="0" height="0" />
    </div>
  );
};

export default BlogSchema50;
