import React from "react";

const NICUMaternityFAQ = () => {
  const maternitySchema = `
<script type="application/ld+json">
{
  "@context": "https://schema.org",
  "@type": "FAQPage",
  "mainEntity": [{
    "@type": "Question",
    "name": "What is Antenatal Care?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Antenatal care is the routine health checkup for pregnant women in order to diagnose complications during pregnancy and educate women for a healthy pregnancy."
    }
  },{
    "@type": "Question",
    "name": "Why is antenatal care important?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Antenatal care is beneficial for to-be-moms as well as the baby. It helps to diagnose the factors that might adversely affect pregnant women or pregnancy outcomes."
    }
  },{
    "@type": "Question",
    "name": "What does antenatal Care include?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Antenatal Care is a healthcare service that is beyond maternity care. It includes diagnosis and management of any pregnancy-related complications and classes that will help you and your partner experience a happy and healthy pregnancy."
    }
  },{
    "@type": "Question",
    "name": "Which is the best maternity hospital in Bangalore?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Kangaroo Care is the leading maternity hospital in Vijayanagar, Bangalore which ensures to offer the best care and facilities to pregnant women for a healthy delivery."
    }
  },{
    "@type": "Question",
    "name": "Why is maternity care important?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Maternity Care is important as it reduces complications and prevents problems during childbirth. And also regular medical checkup during pregnancy provides you with information on changes that could put the pregnancy at risk."
    }
  },{
    "@type": "Question",
    "name": "Which is the best maternity hospital in Bangalore?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "For the best maternity care, Kangaroo Care has a team of highly qualified doctors, trained staff for prenatal care and postnatal care. We are available 24/7 for your medical assistance."
    }
  },{
    "@type": "Question",
    "name": "What are the benefits of antenatal care?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Antenatal care is a vital part of your pregnancy. It detects any abnormalities in the mother or child at early stages and helps you with proper guidance."
    }
  },{
    "@type": "Question",
    "name": "How to Choose the Best Maternity Hospital for Delivery?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "While choosing the best maternity hospital for you and your baby, focus on factors such as Clinical excellence in labor and delivery, special care nursery, birth options, pain relief, pain relief medications, postpartum care, lactation support, prenatal, postpartum care, and classes."
    }
  },{
    "@type": "Question",
    "name": "Why is prenatal care important?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Prenatal care is important because it helps to detect any illness or risk factors for baby and mother at an early stage of growth. It's also a period of time where you get the opportunity to understand more about labor and delivery."
    }
  }
]
}
</script>

 `;

  return (
    <div>
      <iframe
        srcDoc={maternitySchema}
        title="Gynecology Structured Schema"
        width="0"
        height="0"
      />
    </div>
  );
};

export default NICUMaternityFAQ;
