import React from 'react'

function BlogSchema79() {
    const blogSchemas79 = ` <script type="application/ld+json">
    {
      "@context": "https://schema.org",
      "@type": "BlogPosting",
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": "https://kangaroocareindia.com/blogs/first-6-weeks-of-recovery-after-postnatal-care/79"
      },
      "headline": "First 6 Weeks Of Recovery After Postnatal Care",
      "description": "Nothing can describe the feeling of being a mother. Your newborn would be your priority; however, you can’t forget about the postnatal/postpartum care.",
      "image": "https://kangaroocareindia.com/static/media/blog-79.5937befdb94537675878.jpg",  
      "author": {
        "@type": "Organization",
        "name": "Kangaroo care"
      },  
      "publisher": {
        "@type": "Organization",
        "name": "Kangaroo care",
        "logo": {
          "@type": "ImageObject",
          "url": "https://kangaroocareindia.com/images/kclogo-01-01.png"
        }
      },
      "datePublished": "2022-09-28"
    }
    </script>`;
    return (
      <div>
        <iframe srcDoc={blogSchemas79} title="Blog Schema" width="0" height="0" />
      </div>
    );
}

export default BlogSchema79
