import React from "react";

const BlogDetailsContentWrapper = ({ post, sidebar }) => {
  let { imageTitle = "", title } = post;
  if (imageTitle === "") imageTitle = title;

  return (
    <article className="blog-post-details">
      <figure className="blog-post-thumb">
        <img
          src={require("../../assets/img/" + post.thumb)}
          alt={imageTitle}
          title={imageTitle}
        />
      </figure>

      <div className="blog-post-txt-wrap">
        <div className="row">
          {!sidebar ? (
            <div className="col-md-3 col-lg-2 order-1 order-md-0">
              <div className="author-info mt-sm-40">
                <div className="author-thumb">
                  <img
                    src={require("../../assets/img/" + post.author.proPic)}
                    alt={post.author.name}
                  />
                </div>
                <div className="author-txt">
                  <p>
                    {post.author.name}{" "}
                    <span className="designation">
                      {post.author.designation}
                    </span>
                  </p>
                </div>
              </div>
            </div>
          ) : null}

          <div
            className={`m-auto order-0 ${
              !sidebar ? "col-md-9 col-lg-8" : "col-12"
            }`}
          >
            <div className="blog-post-txt">
              <h2 className="h3">{post.title}</h2>

              <div dangerouslySetInnerHTML={{ __html: post.content }} />
            </div>
          </div>
        </div>
      </div>
    </article>
  );
};

export default BlogDetailsContentWrapper;
