import React from "react";

const BlogSchema42 = () => {
  const blogSchemas42 = `
  <script type="application/ld+json">
{
  "@context": "https://schema.org",
  "@type": "BlogPosting",
  "mainEntityOfPage": {
    "@type": "WebPage",
    "@id": "https://kangaroocareindia.com/blogs/tips-to-deal-with-postpartum-depression/42"
  },
  "headline": "Tips To Deal With Postpartum Depression..!",
  "description": "It is important for the mother to get the same amount of care after giving birth along with taking care of the little one. You will have mood swings, baby blues, change in sleep pattern, trouble in decision making, and so on, these can be symptoms of having postpartum depression. It is better to deal with it before it gets worse. Here are a few tips that can help you feel ease with postpartum depression,",
  "image": "https://kangaroocareindia.com/static/media/blog-42.0d4f0deb.jpg",  
  "author": {
    "@type": "Organization",
    "name": "Kangaroo Care",
    "url": "https://kangaroocareindia.com/"
  },  
  "publisher": {
    "@type": "Organization",
    "name": "Organization",
    "logo": {
      "@type": "ImageObject",
      "url": "https://kangaroocareindia.com/images/kc-logo-pink.png"
    }
  },
  "datePublished": "2021-10-12"
}
</script>
  
 `;

  return (
    <div>
      <iframe srcDoc={blogSchemas42} title="Blog Schema" width="0" height="0" />
    </div>
  );
};

export default BlogSchema42;
