import React, { Fragment, useState } from "react";
import { Button } from "react-bootstrap";
import Modal from "../Modal";
import "../style.css";

const Appointment = () => {
  const [cityUrl, setCityUrl] = useState("");
  const [showModal, setShowModal] = useState(false);
  const handleShow = () => setShowModal(false);

  const clickCity = (cityKey) => {
    let url = "https://www.meetmydoctor.in/eclinic/kangaroo-care-vijayanagar-1";
    if (cityKey === 1) {
      url = "https://www.meetmydoctor.in/eclinic/kangaroo-care-vijayanagar";

      // location.href = window.open()
    }
    if (cityKey === 2) {
      url = "https://www.meetmydoctor.in/eclinic/kangaroo-care-vijayanagar-2";
    }
    console.log(url);
    setCityUrl(url);
    setShowModal(true);
  };

  return (
    <Fragment>
      <div className="findDoctorDiv doctorAppointmentContainer">
        <div className="container">
          <div className="row mtn-30">
            <h1 className="col-12 findDocText">Book Appointment</h1>

            <div className="col-12">
              <div className="row">
                <div className="col-lg-4">
                  <a
                    variant="primary"
                    size="lg"
                    block
                    className="bookAppointmentanchor"
                    href={`https://kangaroocareindia.com/book-appointment/bengaluru`}
                  >
                    Bangalore
                  </a>
                  {/* <Button
                    onClick={() => clickCity(1)}
                    variant="primary"
                    size="lg"
                    block
                    className="bookAppointmentBtn"
                  >
                    Bangalore
                  </Button> */}
                </div>
                <div className="col-lg-4">
                  <a
                    variant="primary"
                    size="lg"
                    className="bookAppointmentanchor"
                    href="https://kangaroocareindia.com/book-appointment/mysore"
                  >
                    Mysore
                  </a>
                </div>

                <div className="col-lg-4">
                  <a
                    variant="primary"
                    size="lg"
                    className="bookAppointmentanchor"
                    href="https://kangaroocareindia.com/book-appointment/ramanagara"
                  >
                    Ramanagara
                  </a>
                </div>
                <div className="col-lg-4">
                  <a
                    variant="primary"
                    size="lg"
                    block
                    className="bookAppointmentanchor"
                    href="https://kangaroocareindia.com/book-appointment/fertility"
                  >
                    Fertility Center Bengaluru
                  </a>
                </div>
                <div className="col-lg-4">
                  <a
                    variant="primary"
                    size="lg"
                    block
                    className="bookAppointmentanchor"
                    href="https://kangaroocareindia.com/book-appointment/mysore-fertility"
                  >
                    Fertility Center Mysore
                  </a>
                </div>
                <div className="col-lg-4">
                  <a
                    variant="primary"
                    size="lg"
                    block
                    className="bookAppointmentanchor"
                    href="https://kangaroocareindia.com/book-appointment/ramanagara-fertility"
                  >
                    Fertility Center Ramanagara
                  </a>
                </div>
                <div className="col-lg-4">
                  <a
                    variant="primary"
                    size="lg"
                    block
                    className="bookAppointmentanchor"
                    href="https://kangaroocareindia.com/book-appointment/nagarbhavi"
                  >
                    Nagarbhavi Clinic
                  </a>
                </div>
                <div className="col-lg-4">
                  <a
                    variant="primary"
                    size="lg"
                    block
                    className="bookAppointmentanchor"
                    href="https://kangaroocareindia.com/book-appointment/dentistry"
                  >
                    Kangaroo Care Dentistry
                  </a>
                  {/* <Button
                    onClick={() => clickCity(2)}
                    variant="primary"
                    size="lg"
                    block
                    className="bookAppointmentBtn"
                  >
                    Kangaroo Care Dentistry
                  </Button> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Appointment;
