import React from "react";

const BlogSchema34 = () => {
  const blogSchemas34 = `<script type="application/ld+json">
{
  "@context": "https://schema.org",
  "@type": "BlogPosting",
  "mainEntityOfPage": {
    "@type": "WebPage",
    "@id": "https://kangaroocareindia.com/blogs/know-how-to-engage-your-child-mentally-and-socially-well-during-covid/34"
  },
  "headline": "Know How To Engage Your Child Mentally And Socially Well During Covid",
  "description": "Keeping children engaged inside the house is a challenge for parents and especially for working parents during the Covid-19 pandemic. It is natural for children who bubble up with energy as they are accustomed to playing outside, restricting them to stay back home is a task, indeed!",
  "image": "https://kangaroocareindia.com/static/media/blog-35.f15b039e.jpg",  
  "author": {
    "@type": "Organization",
    "name": "Kangaroo Care"
  },  
  "publisher": {
    "@type": "Organization",
    "name": "Kangaroo Care",
    "logo": {
      "@type": "ImageObject",
      "url": "https://kangaroocareindia.com/images/kc-logo-pink.png"
    }
  },
  "datePublished": "2021-08-09"
}
</script>
 `;

  return (
    <div>
      <iframe srcDoc={blogSchemas34} title="Blog Schema" width="0" height="0" />
    </div>
  );
};

export default BlogSchema34;
