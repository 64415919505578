import React from "react";

const BlogSchema35 = () => {
  const blogSchemas35 = `<script type="application/ld+json">
  {
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": "https://kangaroocareindia.com/blogs/cause-of-low-birth-weight-in-babies/35"
    },
    "headline": "Cause Of Low Birth Weight In Babies!",
    "description": "The average weight of an Indian newborn is between 2.5 - 3Kg. Whereas some babies are born with weight less than 2.5kg. A newborn with a low birth weight can have many serious health problems. But what can cause low birth weight.!",
    "image": "https://kangaroocareindia.com/static/media/blog-36.da607d41.jpg",  
    "author": {
      "@type": "Organization",
      "name": "Kangaroo Care",
      "url": "https://kangaroocareindia.com/"
    },  
    "publisher": {
      "@type": "Organization",
      "name": "Kangaroo Care",
      "logo": {
        "@type": "ImageObject",
        "url": "https://kangaroocareindia.com/images/kc-logo-pink.png"
      }
    },
    "datePublished": "2021-08-19"
  }
  </script>
  
 `;

  return (
    <div>
      <iframe srcDoc={blogSchemas35} title="Blog Schema" width="0" height="0" />
    </div>
  );
};

export default BlogSchema35;
