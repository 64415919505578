import React, { Fragment } from "react";
// import { Link } from "react-router-dom";

function Adminteam(props) {
  let name = props.firstname;
  if (props.lastname && props.lastname !== ".") {
    name = name + " " + props.lastname;
  }
  const docUrl = `/doctor/${name.split(" ").join("-").toLocaleLowerCase()}/${
    props.id
  }`;
  // console.log(props);

  return (
    <Fragment>
      <div className="team-mem-item">
        <figure className="member-pic">
          <a href={`${process.env.PUBLIC_URL + docUrl}`}>
            {/* 
            <img
              src={require("../../../assets/img/doctors" + props.image)}
              alt={name}
            />
            */}

            <img
              src={require("../../../assets/img/doctors/aathira_ravindranath.jpg")}
              alt={name}
            />
          </a>
        </figure>
        <div className="member-info">
          <h5>
            <a href={`${process.env.PUBLIC_URL + docUrl}`}>{name}</a>
          </h5>
          <span className="designation">{props.text}</span>
        </div>
      </div>
    </Fragment>
  );
}

export default Adminteam;
