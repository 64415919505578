import React, { Fragment } from "react";
import Header from "../components/Header";
import TestimonalList from "../templates/Testimonal";
import Footer from "../components/Footer";
import MobileMenu from "../components/MobileMenu";

const Testimonal = () => {
  return (
    <Fragment>
      <Header />
      <TestimonalList />
      <Footer />
      <MobileMenu />
    </Fragment>
  );
};

export default Testimonal;
