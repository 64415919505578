import React from "react";

const NICUMysoreFAQSchema = () => {
  const nicuSchema = `
<script type="application/ld+json">
{
  "@context": "https://schema.org",
  "@type": "FAQPage",
  "mainEntity": [{
    "@type": "Question",
    "name": "Which is the best neonatology hospital in Mysore?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "With state of the art neonatal technology and treatment procedures, Kangaroo Care India ranked among the best neonatology hospitals in Mysore."
    }
  },{
    "@type": "Question",
    "name": "How long will the baby be kept in NICU?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "It depends on the health condition of the baby at the time of birth. Some babies stay only for a few hours or days, while some stay for weeks and months. The treating doctor will update regarding the duration of stay."
    }
  },{
    "@type": "Question",
    "name": "Why are prenatal babies kept in NICU?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "When babies are born early, they come with several health problems, so they have to be kept in NICU for intensive care."
    }
  },{
    "@type": "Question",
    "name": "Why is neonatal care important for babies?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Neonatal care is effective to detect and treat a range of medical conditions, which can include problems found before the birth of your baby. Some of the focused conditions may include genetic or inherited conditions."
    }
  },{
    "@type": "Question",
    "name": "What are the levels of NICU?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "There are four distinct levels of neonatal care, Level I - Well Newborn Nursery, Level II - Special Care Nursery, Level III - Intensive Care Unit, Level IIIB - combined of all."
    }
  }]
}
</script>


 `;

  return (
    <div>
      <iframe
        srcDoc={nicuSchema}
        title="NICU Gynecology FAQ Schema"
        width="0"
        height="0"
      />
    </div>
  );
};

export default NICUMysoreFAQSchema;
