import React, { Fragment } from "react";
import Header from "../components/Header";
import EventsPage from "../templates/Camp";
import Footer from "../components/Footer";
import MobileMenu from "../components/MobileMenu";
import SEO from "../components/SEO";

const Camp = () => {
  return (
    <Fragment>
      <Header />
      <EventsPage
        blog_type={"grid"}
        sidebar={false}
        sidebar_position={"left"}
      />
      <SEO />
      <Footer />
      <MobileMenu />
    </Fragment>
  );
};

export default Camp;
