import React from "react";

const NewBeginnings = (props) => {
  return (
    <div className="row ts50">
      <div className="col-md-6 col-xs-12">
        <img
          src={require("../../assets/img/gallery/" + props.image)}
          alt=""
          className="facimg"
        />
      </div>
      <div className="col-md-6 col-xs-12 service-details-info nb-text">
        <h4>{props.title}</h4>
        <div dangerouslySetInnerHTML={{ __html: props.text }} />
      </div>
    </div>
  );
};

export default NewBeginnings;
