import React from "react";

const BlogSchema39 = () => {
  const blogSchemas39 = `
  <script type="application/ld+json">
  {
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": "https://kangaroocareindia.com/blogs/signs-of-labor-baby-is-all-set-to-come-out/39"
    },
    "headline": "Signs of Labor: Baby is all set to come out.!",
    "description": "Finally, it is the moment that you have been happily anticipating for months. Meanwhile, you have to get ready to do your best for safe delivery. Labor pain is the indication that your baby is all set to come out to this world. However, the signs of labor pain differ from one woman to another. There is no way to predict how a woman's labor will progress or for how long it will last. Here are a few common signs of labor pain,",
    "image": "https://kangaroocareindia.com/static/media/blog-40.ff34ba6a.jpg",  
    "author": {
      "@type": "Organization",
      "name": "Kangaroo Care",
      "url": "https://kangaroocareindia.com/"
    },  
    "publisher": {
      "@type": "Organization",
      "name": "Organization",
      "logo": {
        "@type": "ImageObject",
        "url": "https://kangaroocareindia.com/images/kc-logo-pink.png"
      }
    },
    "datePublished": "2021-09-21"
  }
  </script>
  
 `;

  return (
    <div>
      <iframe srcDoc={blogSchemas39} title="Blog Schema" width="0" height="0" />
    </div>
  );
};

export default BlogSchema39;
