import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
//  import seoData from '../../data/SEO/seo-data.json';
import seodata from "./seoList.json";

const SEO = () => {
  let { pathname, href: canonicalLink } = window.location;

  const homeKeyword =
    "Best Gynaecology Hospital in Bangalore, Best Child Care Hospital in Bangalore, Pregnancy Care Hospital in Vijayanagar, Bangalore, Best Pediatric Hospital in Vijayanagar, Bangalore";

  const [pageData, setPageData] = React.useState({
    title: "Kangaroo Care: Women & Children Hospitals",
    description:
      "Kangaroo Care Hospital is the Bangalore's most trusted Women and Child Care Hospital providing premier quality and high standard healthcare to every mother and child.",
    keywords: homeKeyword,
  });

  const [customSEODATA, setcustomSEODATA] = useState({});

  useEffect(() => {
    seodata.map((value, key) => {
      value.pathname === window.location.pathname && setcustomSEODATA(value);
      return key;
    });
  }, [window.location.pathname]);

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{customSEODATA.title}</title>
        <meta name="description" content={customSEODATA.description} />
        <meta name="keywords" content={customSEODATA.keywords} />
        <link rel="canonical" href={canonicalLink} />
      </Helmet>
    </div>
  );
};

export default SEO;
