import React from "react";
import navbarData from "../../data/Navbar/navbar";

const Sitemap = () => {

  return (
    <>
    <div className="container sm-top sitemapDiv">

    {navbarData.map((item) => (
    <li
      key={item.id}
      className={item.subMenu || item.megaMenu ? "has-submenu" : ""}
    >
      <a className="btn btn-primary siteMapMainBtn" href={`${process.env.PUBLIC_URL + item.link}`}>{item.title}</a>
      {(() => {
        if (item.subMenu) {
          return (
            <ul className="list-unstyled">
              {item.subMenu.map((subItem, index) => {
                return (
                  <li key={index}>
                    <a className="btn btn-primary siteMapSubBtn" href={`${process.env.PUBLIC_URL + subItem.link}`}>
                      {subItem.title}
                    </a>
                  </li>
                );
              })}
            </ul>
          );
        }

        if (item.megaMenu) {
          return (
            <ul className="list-unstyled">
              {item.megaMenu.map((megaItem, indx) => (
                <li key={indx} className="mega-menu-item">
                  <a href={megaItem.link}>{megaItem.title}</a>
                  <ul className="list-unstyled">
                    {megaItem.lists.map((listItem, idx) => {
                      return (
                        <>
                        {/* {(listItem.link == "/centre/mysore") ? "/centre/mysore":"/centre/mysore/centre/mysore"} */}
                        <li key={idx}>
                          <a href={`${process.env.PUBLIC_URL + listItem.link}`}>
                            {listItem.title}
                          </a>
                        </li>
                        </>
                      );
                    })}
                  </ul>
                </li>
              ))}
            </ul>
          );
        }
      })()}
    </li>
  ))}
  
</div>
</>
  );
};

export default Sitemap;
