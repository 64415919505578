import React, { Fragment } from "react";
import Header from "../components/Header";
import PageHeader from "../components/PageHeader";
import Kats from "../components/Kats";
import Footer from "../components/Footer";
import MobileMenu from "../components/MobileMenu";
import KatsSEO from '../helpers/SEO/Kats';

const KatsPage = () => {
  return (
    <Fragment>
      <Header />
      <PageHeader
        bgImg={require("../assets/img/kats.jpg")}
        title=""
        content=""
      />
      <Kats />
      <KatsSEO />
      <Footer />
      <MobileMenu />
    </Fragment>
  );
};

export default KatsPage;
