import React from "react";
import VideoTestList from "../../data/Testimonials/videoTest.json";


const VideoTestListPage = () => {
  return (
	<div className="container-fluid videoTestList">
		<h3>Testimonals</h3>
		<div className="row">
			{VideoTestList.map((videoTest) => 
				<div key={videoTest.id} className="col-lg-6 col-md-6 videoDiv">
					<div className='player-wrapper'>
						<iframe className="iframeClass" title={videoTest.title} src="https://www.youtube.com/embed/tgbNymZ7vqY" frameBorder='0'></iframe>
						<h6 className="videoTestTitle">{videoTest.title}</h6>
					</div>
				</div>
			)}
		</div>
	</div>
  );
};

export default VideoTestListPage;
