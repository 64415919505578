import React, { Fragment } from "react";
import Header from "../components/Header";
import PageHeader from "../components/PageHeader";
import Appointment from "../templates/Appointment";
import Footer from "../components/Footer";
import LoginRegister from "../components/LoginRegister";
import MobileMenu from "../components/MobileMenu";

const BookAppointment = () => {
  return (
    <Fragment>
      <Header />
      {/* <PageHeader
        bgImg={require("../assets/img/doctors-bg.jpg")}
        title="Book Appointment"
        content=""
      /> */}
      <Appointment />
      <Footer />
      <LoginRegister />
      <MobileMenu />
    </Fragment>
  );
};

export default BookAppointment;
