import React from "react";

const BlogSchema52 = () => {
  const blogSchemas52 = `<script type="application/ld+json">
  {
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": "https://kangaroocareindia.com/blogs/tips-for-managing-a-high-risk-pregnancy/52"
    },
    "headline": "Tips for Managing a High-Risk Pregnancy.",
    "description": "For every woman, hearing the news of being pregnant is the most joyous and life-changing experience, hence it becomes necessary that women should enjoy this life.
  In case, any pregnant woman is diagnosed with a high-risk pregnancy can lead to a lot of fear and anxiety. With early and regular antenatal care, women with high-risk pregnancies will still have healthy babies and issues of safety.",
    "image": "https://kangaroocareindia.com/static/media/blog-52.8a8126927f56abd05182.jpeg",  
    "author": {
      "@type": "Organization",
      "name": "Kangaroo Care",
      "url": "https://kangaroocareindia.com/"
    },  
    "publisher": {
      "@type": "Organization",
      "name": "Kangaroo Care",
      "logo": {
        "@type": "ImageObject",
        "url": ""
      }
    },
    "datePublished": "2022-01-18"
  }
  </script>
  
 `;

  return (
    <div>
      <iframe srcDoc={blogSchemas52} title="Blog Schema" width="0" height="0" />
    </div>
  );
};

export default BlogSchema52;
