import React, { Fragment } from "react";
import Header from "../components/Header";
import PageHeader from "../components/PageHeader";
import Doctor from "../templates/Doctor";
import FindDoctor from "../templates/Doctor/FindDoctor";
import Footer from "../components/Footer";
import MobileMenu from "../components/MobileMenu";
import SEO from "../components/SEO";
import DoctorsSchema from "./DoctorsSchema";

const Doctors = () => {
  return (
    <Fragment>
      <Header />
      <PageHeader
        bgImg={require("../assets/img/doctors-bg.jpg")}
        title="Our Doctors - Kangaroo Care"
        content=""
      />
      <SEO />
      <Doctor />
      <Footer />
      <MobileMenu />
      <DoctorsSchema />
    </Fragment>
  );
};

export default Doctors;
