import React from 'react'

function BlogSchema72() {
    const blogSchemas72 = `<script type="application/ld+json">
    {
      "@context": "https://schema.org",
      "@type": "BlogPosting",
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": "https://kangaroocareindia.com/blogs/how-can-parents-contribute-to-the-growth-and-overall-development-of-a-child/72"
      },
      "headline": "How Can Parents Contribute To The Growth And Overall Development Of A Child?",
      "description": "Humans do not know how to behave in society and what are essentials in life by birth.",
      "image": "https://kangaroocareindia.com/static/media/blog-72.6a416069a6e63072ffda.jpg",  
      "author": {
        "@type": "Organization",
        "name": "Kangaroo care"
      },  
      "publisher": {
        "@type": "Organization",
        "name": "Kangaroo care",
        "logo": {
          "@type": "ImageObject",
          "url": "https://kangaroocareindia.com/images/kclogo-01-01.png"
        }
      },
      "datePublished": "2022-07-26"
    }
    </script>`;
    return (
      <div>
        <iframe srcDoc={blogSchemas72} title="Blog Schema" width="0" height="0" />
      </div>
    );
}

export default BlogSchema72
