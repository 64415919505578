import React, { Component } from "react";
import Logo from "./Logo";
import Navbar from "./Navbar/Navbar";
import MobileNavbar from "./Navbar/MobileNavbar";
import HeaderConfig from "./HeaderConfig";
import SEO from "../SEO";

class Header extends Component {
  componentDidMount() {
    window.addEventListener("scroll", this.isSticky);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.isSticky);
  }

  isSticky = (e) => {
    const header = document.querySelector("header");
    const scrollTop = window.scrollY;
    scrollTop >= 100
      ? header.classList.add("sticky")
      : header.classList.remove("sticky");
  };

  render() {
    return (
      <header className="header-area">
        <SEO />
        <div className="container-fluid header-top">
          <div className="row align-items-center">
            <div className="col-5 col-lg-3">
              <Logo />
            </div>
            <div className="col-7 col-lg-9">
              <HeaderConfig
                logRegContentShow={this.props.logRegContentShow}
                mobileMenuShow={this.props.mobileMenuShow}
              />
            </div>
          </div>
        </div>
        <div
          className="container-fluid header-bottom d-none d-md-block"
          style={{ backgroundColor: "#ef586f" }}
        >
          <div className="row justify-content-md-center">
            <div className="col-md-12 d-none d-md-block ">
              <div className="navigation-area row justify-content-md-center">
                <Navbar />
              </div>
            </div>
          </div>
        </div>
        {/* <div
          className="container-fluid header-bottom d-sm-block d-md-none d-lg-none justify-content-around"
          style={{ backgroundColor: "#ef586f" }}
        >
          <MobileNavbar />
        </div> */}
      </header>
    );
  }
}

export default Header;
