import React,{Fragment} from 'react';
import SocialLink from './SocialLink';

const ContactInfo = ({address,social=false}) => {
    return (
        <Fragment>
            <div className="widget-item m-0">
                <address>
                    <span dangerouslySetInnerHTML={{__html: address}}/>
                </address>
            </div>
            {(social === true) && 
                <SocialLink />
            }
        </Fragment>
    );
};

export default ContactInfo;
