import React from "react";

const BlogSchema40 = () => {
  const blogSchemas40 = `
  <script type="application/ld+json">
  {
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": "https://kangaroocareindia.com/blogs/fetal-development-cycle-stages-of-growth/40"
    },
    "headline": "Fetal Development Cycle - Stages of Growth",
    "description": "At your earliest stages of pregnancy, you can observe a few physical and emotional changes within yourself and it is common during the starting of the pregnancy period. Have you ever wondered how your baby is developing inside your womb.! Let us see here, the different stages that count in during the process of fetal development,",
    "image": "https://kangaroocareindia.com/static/media/blog-41.a955cd7e.jpg",  
    "author": {
      "@type": "Organization",
      "name": "Kangaroo Care",
      "url": "https://kangaroocareindia.com/"
    },  
    "publisher": {
      "@type": "Organization",
      "name": "Organization",
      "logo": {
        "@type": "ImageObject",
        "url": "https://kangaroocareindia.com/images/kc-logo-pink.png"
      }
    },
    "datePublished": "2021-10-06"
  }
  </script>  
  
 `;

  return (
    <div>
      <iframe srcDoc={blogSchemas40} title="Blog Schema" width="0" height="0" />
    </div>
  );
};

export default BlogSchema40;
