import React, { Fragment } from "react";
import PageHeader from "../../components/PageHeader";
import PageWrapper from "../../components/PageWrapper";
import ServiceContentWrap from "./ServiceContentWrap";
import Sidebar from "../../components/Sidebar";
import SidebarItem from "../../components/Sidebar/SidebarItem";
import Testimonials from "../../components/Testimonials/home-one";
import Form from "../CentreDetails/Form";
import List from "../../components/UI/List";
import LI from "../../components/UI/List/Item";
import { Link } from "react-router-dom";
import Anchor from "../../components/UI/Anchor";

import PackageList from "../../data/Packages/allPackages.json";

const PackageDetail = () => {
  //  FIND PATH NAME
  let { pathname } = window.location;
  let pathnameArr = pathname.split("/");

  if (pathnameArr[2] === undefined) {
    //  REDIRECT TO ERR PAGE
  }

  const centreData = PackageList.find(
    (centre) => centre.urlIndex === pathnameArr[2]
  );

  // console.log("centreData", centreData);

  if (centreData === undefined) {
    window.location.replace("/404"); //  REDIRECT TO ERR PAGE
  }

  return (
    <Fragment>
      <PageHeader
        bgImg={require("../../assets/img/service/service-bg-1.jpg")}
        title={centreData.title}
      />

      <PageWrapper classes="sm-top service-details-wrapper bs100">
        <ServiceContentWrap service={centreData} />

        <Sidebar classes={"col-lg-4 mtn-40"}>
          <SidebarItem title="Our Centres">
            <List classes="service-list">
              {PackageList.map((centreItem) => (
                <LI key={centreItem.id}>
                  <a
                    href={`${
                      process.env.PUBLIC_URL + `/centre/${centreItem.urlIndex}`
                    }`}
                  >
                    {centreItem.title}
                  </a>
                </LI>
              ))}
            </List>
          </SidebarItem>

          <SidebarItem title="GET A CALLBACK">
            <List classes="service-list">
              <Form />
            </List>
          </SidebarItem>

          <SidebarItem title="Contact Pregnancy Care">
            <List classes="service-list">
              <LI>
                <div className="container" style={{ textAlign: "center" }}>
                  <Link to={"/book-appointment"} style={{ margin: "0 auto" }}>
                    <button className="book-appointment">
                      Book Appointment
                    </button>
                  </Link>
                </div>
              </LI>
              <LI>
                <Anchor path="/contact">
                  <i className="fa fa-map-marker contact-icon" />
                  #89, 17th cross, MC layout, Near Balagangadharnath Swamy Metro
                  Station, Vijayanagar, Bangalore 560040.
                </Anchor>
              </LI>
              <LI>
                <Anchor path="/contact">
                  <i className="fa fa-phone-square contact-icon" />
                  Toll Free - 1800-425-4500
                </Anchor>
              </LI>
              <LI>
                <Anchor path="/contact">
                  <i className="fa fa-envelope contact-icon" />
                  info@kangaroocareindia.com
                </Anchor>
              </LI>
            </List>
          </SidebarItem>
        </Sidebar>
      </PageWrapper>
      <Testimonials />
    </Fragment>
  );
};

export default PackageDetail;
