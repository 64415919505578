import React from "react";
import { Link } from "react-router-dom";

import ServicesList from "../../data/Services/allservices";
import DoctorList from "../../data/Doctors/all-doctors.json";
import BlogList from "../../data/Blog/blog.json";
import DeptList from "../../data/Dept";

const Breadcrumb = () => {
  //  FIND PATH NAME
  let { pathname } = window.location;
  let pathnameArr = pathname.split("/");
  let pathnameArrLength = pathnameArr.length;
  // console.log(pathnameArrLength);
  if (pathnameArrLength === 4) {
    let title = "";
    let id = pathnameArr[3];

    //  FIND SPECIALITIES
    if (pathnameArr[1] === "specialities") {
      let data = ServicesList.find((detail) => id == detail.id);
      title = data.title;
    }

    //  FIND DOCTOR
    if (pathnameArr[1] === "doctor") {
      let data = DoctorList.find((detail) => id == detail.id);
      title = `${data.firstname} ${data.lastname}`;
      title = title.toLowerCase();
    }

    //  FIND BLOGS
    if (pathnameArr[1] === "blogs") {
      let data = BlogList.find((detail) => id == detail.id);
      title = data.title;
    }

    //  RE-STRUCTURE THE PATH ARR
    pathnameArr.pop(); //  REPLACE LAST INDEX
    pathnameArr[2] = title;
    pathnameArrLength = pathnameArr.length;
  }

  const MainModule = ({ path }) => {
    let pathName = path;
    let isLink = true; //  IF SUB-LINK LINK OPTION NOT AVAILABLE

    if (path === "media") {
      isLink = false;
    }

    if (path === "") {
      path = "/";
      pathName = "Home";
    } else if (path === "doctor") {
      path = "/doctors";
    } else if (path === "blogs") {
      path = "/blog";
    } else {
      path = `/${path}`;
    }

    return (
      <React.Fragment>
        {isLink ? (
          <Link to={path} className="breadCrumbMainLink" title={pathName}>
            {pathName}
          </Link>
        ) : (
          <p className="breadCrumbActiveLink" title={pathName}>
            {pathName}
          </p>
        )}
        <i className="fa fa-angle-right rightIcon" aria-hidden="true"></i>
      </React.Fragment>
    );
  };

  const LastChildModule = ({ path }) => {
    //  FIND SPECIALITIES _ & REPLACE
    if (pathnameArr[1] === "doctors") {
      path = path.replace(/_/g, " ");
    }

    return <p className="breadCrumbActive">{path}</p>;
  };

  const MapData = ({ index, path }) => {
    let nextIndex = ++index;
    let lastIndex = nextIndex === pathnameArrLength;

    return (
      <React.Fragment>
        {lastIndex === false ? (
          <MainModule path={path} />
        ) : (
          <LastChildModule path={path} />
        )}
      </React.Fragment>
    );
  };

  return (
    <div className="container">
      <div className="breadcrumbPage container">
        <div className="row">
          <div className="col-sm-12">
            {pathnameArr.map((path, index) => (
              <MapData key={index} index={index} path={path} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Breadcrumb;
