import React from 'react'

function BlogSchema78() {
    const blogSchemas78 = ` <script type="application/ld+json">
    {
      "@context": "https://schema.org",
      "@type": "BlogPosting",
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": "https://kangaroocareindia.com/blogs/tips-for-managing-hormonal-changes-during-pregnancy/78"
      },
      "headline": "Tips For Managing Hormonal Changes During Pregnancy",
      "description": "Pregnancy can be a complete roller coaster of emotions. Moreover, trimester after trimester, you would notice different ups and downs.",
      "image": "https://kangaroocareindia.com/static/media/blog-78.870bf93b052056384d74.jpg",  
      "author": {
        "@type": "Organization",
        "name": "Kangaroo care"
      },  
      "publisher": {
        "@type": "Organization",
        "name": "Kangaroo care",
        "logo": {
          "@type": "ImageObject",
          "url": "https://kangaroocareindia.com/images/kclogo-01-01.png"
        }
      },
      "datePublished": "2022-09-16"
    }
    </script>    
    `;
    return (
      <div>
        <iframe srcDoc={blogSchemas78} title="Blog Schema" width="0" height="0" />
      </div>
    );
}

export default BlogSchema78
