import React, {useEffect, useState} from "react";
// import moment from "moment";
import { useContext } from "react";
import BookConst from "../../AppionmentProvider";
import GetDate from "./Components/GetDate";
import GetTimeSlot from "./Components/GetTimeSlot";
//styles
import styles from "./step.module.css";
import axios from "axios";
import er from "react-datepicker";
let user = JSON.parse(localStorage.getItem('user'));
const moment = require('moment')

// const baseUrl ='https://prod.backend.pulseright.io:3000';
const baseUrl ='https://prod.backend.pulseright.io:3000';
export default function StepOne(props) {
  //context
  let slots = [];
  let {selservice,selSetService, seldoctors, setSeldoctors,} = useContext(BookConst);
  let showAvailability,displaySlots,docTimeoff;
  let temp = [];
  let doctor = {};

  let bookedAppointmentSlots = [];
  let appointmentDetails = {
    name: '',
    date: '',
    time: '',
    service: '',
    serviceName: '',
    patient: '',
    user: '',
    doctor: '',
    organization: '',
    message: ''
  };

  const [doctorId,setDoctorId] = useState('');
  const [filteredDoctos,SetfilerationOfDoctors] = useState([])
  const [doctorList,setDoctor] = useState([])
  const [serviceLists,setServices] = useState([])
  const [appointmentList,setAptLists] = useState([])
  const [timeoffList,settimeoffList] = useState([])
  const [selDate,SetselDate] = useState(moment(new Date()).format());
  const [service,setSelectedService] = useState({})
  const [message,setMessage] = useState([]);
  const [doctors,setDoctors] = useState([]);

  useEffect(()=>{
    user = JSON.parse(localStorage.getItem('user'));
    servicesList();
    fetchTimeOff();
    doctorsList();
    fetchAllAppointments();
  },[]);

  function doctorsList(){
    axios.get(baseUrl+'/users/org/Doctor/'+ props.org,{headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + user.token,
      },
    }).then(resp=>{
      setDoctor(resp.data)
    }).catch(err =>{
    })
  }




  function sortServices(doctorServices ,selectedService) {
    return (doctorServices.service.name === selectedService.name);
  }

  function filerDoctor(value){
    setDoctors([]);
    SetfilerationOfDoctors([])
    setMessage([])
    axios.get(baseUrl+'/service/details/'+value.target.value,{headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + user.token,
      },
    }).then(resp=>{
      setSelectedService(resp.data);
      selSetService(resp.data)
      doctorList.map((doctorServices, index) => {
        const selectedDoctor = doctorServices.services.map((services) => {
          return sortServices(services ,resp.data);
        })
        const servicePresent = selectedDoctor.some(function (docService) {
          return docService === true;
        });
        if (selectedDoctor.length > 0 && servicePresent) {
          doctors.push(doctorList[index]);
        }
      });
      SetfilerationOfDoctors(doctors);
    }).catch(err =>{
    })
  }


  function servicesList(){
    axios.get(baseUrl+'/service/'+ props.org,{headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + user.token,
      },
    }).then(resp=>{
      setServices(resp.data);
    }).catch(err =>{
    })
  }

  function fetchAllAppointments(){
    axios.get(baseUrl+'/booking/org/'+props.org,{headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + user.token,
      },
    }).then(resp=>{
      setAptLists(resp.data);
    }).catch(err =>{

    })
  }

  function fetchTimeOff(){
    axios.get(baseUrl+'/timeoff/'+props.org,{headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + user.token,
      },
    }).then(resp=>{
      settimeoffList(resp.data);
    }).catch(err =>{

    })
  }

  async function getSelectedDoctor(doctorId){
    setMessage([]);
    setDoctorId(doctorId.target.value);
    return axios.get(baseUrl+'/users/'+doctorId.target.value,{headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + user.token,
      },
    }).then(async resp => {
      doctor = resp.data;
      setSeldoctors(resp.data);
      showAvailability = true;
      // Show Slots
      displaySlots = true;
      docTimeoff = [];
      temp = [];

      temp = doctor?.services?.filter(serve => {
        if (serve.service.name === service.name && serve.organization === props.org && serve.deleted === false) {
          return serve;
        }
      });


      docTimeoff = timeoffList.filter(timeoff => {
        return timeoff.status === true;
      })
      const res = temp;
      appointmentDetails.service = service._id;
      await generateSlots(temp);
    }).catch(async error=>{
    })
  }


  const getDateFromCal =  async (data) => {
    SetselDate(moment(data).format());
    setMessage([]);
    await callme();
  }

  async function callme() {
    axios.get(baseUrl+'/users/'+doctorId,{headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + user?.token,
      },
    }).then(async resp => {
      doctor = resp.data;
      showAvailability = true;
      // Show Slots
      displaySlots = true;
      docTimeoff = [];
      temp = [];
      temp = doctor?.services?.filter(serve => {
        if (serve.service.name === service.name && serve.deleted === false && serve.organization === props.org) {
          return serve;
        }
      });
      docTimeoff = timeoffList.filter(timeoff => {
        return timeoff.status === true;
      })
      // const res = temp[0];
      appointmentDetails.service = service._id;
      await generateSlots(temp);
    }).catch(err =>{
    })
  }


  async function generateSlots(docServe) {
    // reset slots on doctor change
    slots = [];
    const timeoffData = {};
    // Check if the doctor is on timeoff
    if (docTimeoff?.length > 0) {
      docTimeoff.filter(timeoff => {
        // Check if the timeoff is on the same day
        if (moment(selDate).format('l') === moment(timeoff.startDate).format('l') &&
            moment(selDate).format('l') === moment(timeoff.endDate).format('l')) {
          // console.log('Same Day!');
          // Consider start time and endtime
          timeoffData.startTime = timeoff.startTime;
          timeoffData.endTime = timeoff.endTime;
        } else if (moment(selDate).format('l') === moment(timeoff.startDate).format('l')) {
          // console.log('Start Day StartTime!');
          // Consider only starttime and make endtime 11:59pm
          timeoffData.startTime = timeoff.startTime;
          timeoffData.endTime = moment(timeoff.endTime).set('hour', 23).set('minute', 59).format();
        } else if (moment(selDate).format('l') === moment(timeoff.endDate).format('l')) {
          // console.log('End Day EndTime!');
          // Consider only endtime and make starttime 12:00am
          timeoffData.startTime = moment(timeoff.startTime).set('hour', 0).set('minute', 0).format();
          timeoffData.endTime = timeoff.endTime;
        } else if (moment(selDate).isBetween(moment(timeoff.startDate).format('l'), moment(timeoff.endDate).format('l'))) {
          // console.log('Multiple Days');
          timeoffData.startTime = moment(timeoff.startTime).set('hour', 0).set('minute', 0).format();
          timeoffData.endTime = moment(timeoff.endDate).set('hour', 23).set('minute', 59).format();
        }
      })
    }

    for (const docSer of docServe) {
      await showSlots(docSer,timeoffData);
    }


  }
  //
  async function showSlots(doctorService,timeoff) {
    bookedAppointmentSlots = [];
    const currentTime = new Date().getHours() + ':' + new Date().getMinutes();
    const result = 10 - (moment(currentTime, 'HH:mm').minute() % 10);
    // const dateTime = moment(moment(currentTime, 'HH:mm').format()).add(result, 'minutes').format();
    let startTime;
    if (moment(selDate).format('l') === moment(new Date()).format('l')) {
      startTime = moment(moment(currentTime, 'HH:mm A').tz('UTC').format()).add(result, 'minutes').format();
    } else {
      startTime = doctorService.startTime;
    }
    const endTime = doctorService.endTime;
    const weekday = moment(selDate).day();
    const checkDay = doctorService?.serviceDays?.some(function (days) {
      return days === weekday;
    });

    if (doctorService.status && checkDay) {
      // hideSlotCreation = false;
      if (timeoff) {
        while (moment(startTime).format('HH:mm') <= moment(endTime).format('HH:mm')) {
          if (startTime === moment(currentTime, 'HH:mm A').tz('UTC').format()) {
            slots.push({time: moment(startTime).format('HH:mm'), status: false, additionalSlot: false, holiday: false});
          } else if (
              (moment(startTime).format('HH:mm') >= moment(timeoff.startTime).format('HH:mm') && moment(startTime).format('HH:mm') <= moment(timeoff.endTime).format('HH:mm'))
          ) {
            slots.push({time: moment(startTime).format('HH:mm'), status: true, additionalSlot: false, holiday: true});
          } else {
            slots.push({time: moment(startTime).format('HH:mm'), status: false, additionalSlot: false, holiday: false});
          }
          startTime = moment(startTime).add(doctorService.timeSlot, 'm').format();
        }
      } else {
        while (moment(startTime).format('HH:mm') <= moment(endTime).format('HH:mm')) {
          slots.push({time: moment(startTime).format('HH:mm'), status: false, additionalSlot: false, holiday: false});
          startTime = moment(startTime).add(doctorService.timeSlot, 'm').format();
        }
      }
    }
    // // Function to remove the slots that are already booked
    appointmentList.filter(appointment => {
      if (appointment.doctor?._id === doctor?._id
          && moment(selDate).format('L') === moment(appointment.date).format('L')) {
        bookedAppointmentSlots.push({time: moment(appointment.time).format('HH:mm'), status: true});
      }
    });


    //  const momentTimeSlots = bookedAppointmentSlots.map(slot => moment(slot, "HH:mm"));
    //  momentTimeSlots.sort((a, b) => moment(a.time,'HH:mm').isBefore(moment(b.time,'HH:mm ')) ? -1 : 1);
    // bookedAppointmentSlots = momentTimeSlots.map(slot =>{
    //    return slot._i;
    //    // console.log(moment(slot.time,'HH:mm').format('HH:mm'));
    //  });

    bookedAppointmentSlots.filter(s => {
      let count = 0;
      slots.map(slot => {
        if (slot.time === s.time) {
          slot.status = true;
        } else {
          count++;
        }
      })
      if (count === slots.length) {
        slots.push({time: s.time, status: true, additionalSlot: false, holiday: false});
      }
    });



    // Set appointment Date and Time
    appointmentDetails.date = moment(selDate).format();
    setMessage(slots);
  }



  // let getDoctor = doctorList.filter((item) => item.type == selService);

  return (
      <div className={styles.step_container}>
        <div>
          <form className={styles.step_form}>
            <div>
              <label htmlFor="serviceName">SERVICES</label>
              <select
                  id="serviceName"
                  name="serviceName"
                  required
                  onChange={filerDoctor}>
                <option value="" selected>
                  Select Services
                </option>
                {serviceLists.map((serveItem,index) => {
                  return (
                      <option key={index} value={serveItem._id}>
                        {serveItem.name}
                      </option>);
                })}
              </select>
            </div>
            <div>
              {/*<p>{filteredDoctos.length}</p>*/}
              <label htmlFor="doctorsName">DOCTORS</label>
              <select id="doctorsName" name="doctorsName" required
                      onChange={getSelectedDoctor}>
                <option value="" selected>
                  Select Doctor
                </option>
                {filteredDoctos.map((item,index) => {
                  return (<option value={item._id} key={index}>{item.name}</option>)
                })}
              </select>
            </div>
          </form>
        </div>
        <div className={styles.date_time_container}>
          {filteredDoctos && (
              <>
                <h3>DOCTOR AVAILABILITY</h3>
                <div className={styles.date_time_item}>
                  <section className={styles.date_date_item}>
                    <b>Select Date</b>&nbsp;&nbsp;
                    <GetDate onChange={getDateFromCal}  />
                  </section>
                  <section>
                    <p className={`${doctor.holiday ? styles.show_message : styles.hide_message}`}>Sorry! The Doctor is on leave</p>
                    <p className={`${message.length === 0 ? styles.show_message : styles.hide_message}`}>No Slots available</p>
                    <div className={`${doctor.holiday && styles.hide_slot_block}
                    ${message.length === 0 && styles.hide_slot_block}`}>
                      <GetTimeSlot message={message} styles={styles}  />
                    </div>
                  </section>
                </div>
              </>
          )}
        </div>
      </div>
  );
}
