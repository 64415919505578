import React, { Fragment } from "react";
// import { Link } from "react-router-dom";

function Docs(props) {
  return (
    <Fragment>
      <div className="team-mem-item">
        <figure className="member-pic">
          <a href={process.env.PUBLIC_URL + props.link}>
            <img
              src={require("../../../assets/img/founders/" + props.image)}
              alt={props?.name + " - " + props?.text}
            />
          </a>
        </figure>
        <div className="member-info">
          <h5>
            <a href={process.env.PUBLIC_URL + props.link}>Dr. {props.name}</a>
          </h5>
          <span className="designation">{props.text}</span>
        </div>
      </div>
    </Fragment>
  );
}

export default Docs;
