import React,{useState} from "react";
import { Button,Modal } from "react-bootstrap";


function Example({cityUrl,showModal,handleShow}) {
/*
    const [show, setShow] = useState(false);  
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
*/

    return (
      <>
        <Modal
          show={showModal}
          onHide={handleShow}
          backdrop="static"
          keyboard={false}
          size="xl"
        >
          <Modal.Header closeButton>
          </Modal.Header>
          <Modal.Body>
            <div className="container">
                <iframe title="Appointment" src={cityUrl} height="3000px" width="100%" frameBorder="0" scrollbar='no' />
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
}

export default Example;