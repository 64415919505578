import React from "react";
import MobileNavbarItem from "./MobileNavbarItem";

function MobileNavbar(props) {
  return (
    <ul className="main-menu nav justify-content-around">
      <MobileNavbarItem />
    </ul>
  );
}

export default MobileNavbar;
