import React from "react";

const NICUFertilityMysoreFAQSchema = () => {
  const fertilitySchema = `
<script type="application/ld+json">
{
  "@context": "https://schema.org",
  "@type": "FAQPage",
  "mainEntity": [{
    "@type": "Question",
    "name": "What is IVF treatment?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "IVF (In Vitro Fertilization) helps with fertilization, embryo development, and implantation, so you can get pregnant."
    }
  },{
    "@type": "Question",
    "name": "How much does it cost to have IVF treatment?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Being the best IVF center in Mysore, Karnataka, Kangaroo Care India offers affordable IVF treatment starting from 70,000."
    }
  },{
    "@type": "Question",
    "name": "Is IVF 100 percent successful?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Kangaroo Care India is the best IVF center in Karnataka that holds a very high success rate which is perhaps the best in the competition."
    }
  },{
    "@type": "Question",
    "name": "Which is the Best IVF center in Mysore?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "It all depends on the years of practice and Kangaroo Care India has a big team of certified doctors, advanced technologies and offers best-in-class services at an affordable rate. It makes it the best IVF center in Mysore, Karnataka."
    }
  },{
    "@type": "Question",
    "name": "How many times a couple can try for IVF treatment?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Since IVF treatments take a toll both mentally and physically, experts recommend you should not try IVF more than three times without changing"
    }
  }]
}
</script>

 `;

  return (
    <div>
      <iframe
        srcDoc={fertilitySchema}
        title="NICU Gynecology FAQ Schema"
        width="0"
        height="0"
      />
    </div>
  );
};

export default NICUFertilityMysoreFAQSchema;
