import React from "react";

import { blogSchema } from "./BlogSchemaData";

export default function BlogSchema({ id }) {
  const blogSchemaFilter = blogSchema.filter((schemaid) => id == schemaid.id);

  if (!id) {
    return "";
  }

  if (blogSchemaFilter.length == 0) {
    return "";
  }

  let articleStructuredData2;

  if (blogSchemaFilter.length >= 1) {
    articleStructuredData2 = blogSchemaFilter[0].schemaCode.trim();
  }

  return (
    <>
      <script type="application/ld+json">{`{${articleStructuredData2}}`}</script>
    </>
  );
}
