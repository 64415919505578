import React, { Fragment } from "react";
import PageWrapper from "../../components/PageWrapper";
import PageHeader from "../../components/PageHeader";
import EventContent from "./EventContent";

const BlogPage = ({ sidebar_position, blog_type, sidebar }) => {
  return (
    <Fragment>
      <PageHeader
        bgImg={require("../../assets/img/gallery-bg.jpg")}
        title={"Camp"}
        content=""
      />

      <PageWrapper classes={"blog-page-content-area"}>
        <EventContent />
      </PageWrapper>
    </Fragment>
  );
};

export default BlogPage;
