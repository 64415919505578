import React, { Fragment } from "react";
import Header from "../components/Header";
import PackageDetail from "../templates/PackageDetail";
import Footer from "../components/Footer";
import MobileMenu from "../components/MobileMenu";

const PackageDetailPage = () => {
  return (
    <Fragment>
      <Header />
      <PackageDetail />
      <Footer />
      <MobileMenu />
    </Fragment>
  );
};

export default PackageDetailPage;
