import React from "react";

const BlogSchema30 = () => {
  const blogSchemas30 = `<script type="application/ld+json">
{
  "@context": "https://schema.org",
  "@type": "BlogPosting",
  "mainEntityOfPage": {
    "@type": "WebPage",
    "@id": "https://kangaroocareindia.com/blogs/everything-you-need-to-know-about-baby-formula-feeding/30"
  },
  "headline": "Everything You Need To Know About Baby Formula Feeding.!",
  "description": "Breastfeeding is beneficial for both mother and the child. But not every mother can breastfeed her baby. In such cases, breastfeeding can be challenging wherein mothers can choose baby formula feeding.",
  "image": "https://kangaroocareindia.com/static/media/blog-30.aeb5dda4.jpg",  
  "author": {
    "@type": "Organization",
    "name": "Kangaroo Care"
  },  
  "publisher": {
    "@type": "Organization",
    "name": "Kangaroo Care",
    "logo": {
      "@type": "ImageObject",
      "url": "https://kangaroocareindia.com/images/kc-logo-pink.png"
    }
  },
  "datePublished": "2021-07-13"
}
</script>
 `;

  return (
    <div>
      <iframe srcDoc={blogSchemas30} title="Blog Schema" width="0" height="0" />
    </div>
  );
};

export default BlogSchema30;
