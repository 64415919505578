import React from "react";
import SectionTitle from "../UI/SectionTitle";
import DownloadItem from "./PackageDetailsMediaItems";
import downloadData from "../../data/Downloads/downloads";
import downloadDatas from "../../data/PackageDetailsMediaFiles/packagesItems";
import serviceTopBg from "../../assets/img/service/service-bg-1.jpg";
import package1 from "../../assets/img/media/packageimage/package-01.jpg";
import package2 from "../../assets/img/media/packageimage/package-02.jpg";
import pcospackage from "../../assets/img/media/packageimage/package-pcos.jpg";
import menpackage from "../../assets/img/media/packageimage/package-03-men-fertility-care.jpg";
import womenpackage from "../../assets/img/media/packageimage/package-04-women-fertility-care.jpg";
import womenhealthpackage from "../../assets/img/media/packageimage/package-women-health.jpg";
import womendiabeticpackage from "../../assets/img/media/packageimage/women-diabetic.jpg";
import womenquickscreening from "../../assets/img/media/packageimage/women-quickscreening.jpg";
import childadolescent from "../../assets/img/media/packageimage/package-10.jpg";
import childpackage from "../../assets/img/media/packageimage/child-2.jpg";
import childadvanced from "../../assets/img/media/packageimage/child-3.jpg";
import obesity from "../../assets/img/media/packageimage/obesity-package.jpg";

import { Link } from "react-scroll";
function Services() {
  return (
    <>
      <div className="container">
        <div className="row deptListDiv">
          <div className="card cardStyle m-2 m-md-3 views360">
            <Link to="kcbengaluru" offset={-400} smooth={true}>
              <div className="card-body">
                <h5 className="card-title cardTitle">
                  Kangaroo Care Bangalore
                </h5>
              </div>
            </Link>
          </div>
          <div className="card cardStyle m-2 m-md-3 views360">
            <Link to="kcmysore" offset={-400} smooth={true}>
              <div className="card-body">
                <h5 className="card-title cardTitle">Kangaroo Care Mysore</h5>
              </div>
            </Link>
          </div>
          <div className="card cardStyle m-2 m-md-3 views360">
            <Link to="kcramanagara" offset={-400} smooth={true}>
              <div className="card-body">
                <h5 className="card-title cardTitle">
                  Kangaroo Care Ramanagara
                </h5>
              </div>
            </Link>
          </div>

          <div className="card cardStyle m-2  m-md-3 views360">
            <Link to="kcnagarbhavi" offset={-300} smooth={true}>
              <div className="card-body">
                <h5 className="card-title cardTitle ">Kangaroo Care Clinic</h5>
              </div>
            </Link>
          </div>

          <div className="card cardStyle m-2 m-md-3 views360">
            <Link to="kcVijayanagar" offset={-400} smooth={true}>
              <div className="card-body">
                <h5 className="card-title cardTitle">Kangaroo Care Kids</h5>
              </div>
            </Link>
          </div>
        </div>
      </div>

      <div className={`service-area-wrapper`}>
        {/* <h3
          id="pcospack"
          className=" text-center mb-5 mt-md-3 mb-md-3 mt-4  package-titles "
        >
          WOMEN HEALTH CHECK
        </h3> */}
        <div className="container  mt-5">
          <div
            className="row justify-content-center align-items-center view_iframe_wrapper"
            id="kcbengaluru"
          >
            <div className="m-2 view_iframe">
              <h3 className=" text-center mt-md-1 mb-md-3 package-title">
                Kangaroo Care Bangalore
              </h3>
              <div className="mt-1 ">
                <iframe
                  src="https://app.lapentor.com/sphere/kangaroocarewomenandchildren"
                  frameborder="0"
                  width="100%"
                  height="400px"
                  scrolling="no"
                  allow="vr,gyroscope,accelerometer"
                  allowfullscreen="true"
                  webkitallowfullscreen="true"
                  mozallowfullscreen="true"
                  oallowfullscreen="true"
                  msallowfullscreen="true"
                ></iframe>
              </div>
            </div>
          </div>

          <div
            className="row justify-content-center align-items-center view_iframe_wrapper"
            id="kcmysore"
          >
            <div className="m-2 view_iframe">
              <h3 className=" text-center mt-md-1 mb-md-3 package-title">
                Kangaroo Care Mysore
              </h3>
              <div className="mt-1 ">
                <iframe
                  src="https://app.lapentor.com/sphere/kangaroocaremysoree"
                  frameborder="0"
                  width="100%"
                  height="400px"
                  scrolling="no"
                  allow="vr,gyroscope,accelerometer"
                  allowfullscreen="true"
                  webkitallowfullscreen="true"
                  mozallowfullscreen="true"
                  oallowfullscreen="true"
                  msallowfullscreen="true"
                ></iframe>
              </div>
            </div>
          </div>

          <div
            className="row justify-content-center align-items-center view_iframe_wrapper"
            id="kcramanagara"
          >
            <div className="m-2 view_iframe">
              <h3 className=" text-center mt-md-1 mb-md-3 package-title">
                Kangaroo Care Ramanagara
              </h3>
              <div className="mt-1 ">
                <iframe
                  src="https://app.lapentor.com/sphere/kangaroocareramanagar"
                  frameborder="0"
                  width="100%"
                  height="400px"
                  scrolling="no"
                  allow="vr,gyroscope,accelerometer"
                  allowfullscreen="true"
                  webkitallowfullscreen="true"
                  mozallowfullscreen="true"
                  oallowfullscreen="true"
                  msallowfullscreen="true"
                ></iframe>
              </div>
            </div>
          </div>

          <div
            className="row justify-content-center align-items-center view_iframe_wrapper"
            id="kcVijayanagar"
          >
            <div className="m-2 view_iframe">
              <h3 className=" text-center mt-md-1 mb-md-3 package-title">
                Kangaroo Care Kids
              </h3>
              <div className="mt-1 ">
                <iframe
                  src="https://app.lapentor.com/sphere/kangaroocarekids"
                  frameborder="0"
                  width="100%"
                  height="400px"
                  scrolling="no"
                  allow="vr,gyroscope,accelerometer"
                  allowfullscreen="true"
                  webkitallowfullscreen="true"
                  mozallowfullscreen="true"
                  oallowfullscreen="true"
                  msallowfullscreen="true"
                ></iframe>
              </div>
            </div>
          </div>
          <div
            className="row justify-content-center align-items-center view_iframe_wrapper"
            id="kcnagarbhavi"
          >
            <div className="m-2 view_iframe">
              <h3 className=" text-center mt-md-1 mb-md-3 package-title">
                Kangaroo Care Clinic
              </h3>
              <div className="mt-1 ">
                <iframe
                  src="https://app.lapentor.com/sphere/kangaroocareclinic"
                  frameborder="0"
                  width="100%"
                  height="400px"
                  scrolling="no"
                  allow="vr,gyroscope,accelerometer"
                  allowfullscreen="true"
                  webkitallowfullscreen="true"
                  mozallowfullscreen="true"
                  oallowfullscreen="true"
                  msallowfullscreen="true"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Services;
