import React from "react";

const PaediatricHduFaq = () => {
  const paediatricHduSchema = `
  <script type="application/ld+json">
  {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": [{
      "@type": "Question",
      "name": "What is Pediatric HDU?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Pediatric HDU is Pediatric High Dependency Unit, where children require close monitoring and treatment for their illness."
      }
    },{
      "@type": "Question",
      "name": "What is Pediatric critical care?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Pediatric Critical care is a special treat for children suffering from severe illness. They are kept under high supervision for treatment."
      }
    }]
  }
  </script>  
 `;

  return (
    <div>
      <iframe
        srcDoc={paediatricHduSchema}
        title="Gynecology Structured Schema"
        width="0"
        height="0"
      />
    </div>
  );
};

export default PaediatricHduFaq;
