import React from "react";
import styles from "./leftSidebar.module.css";
export default function LeftSidebar() {
  // const mobileTrue = window.innerWidth < 600 && window styles.hiddenMobile : "";
  let mobileTrue =
    window.location.href.split("/").includes("blogs") &&
    window.innerWidth < 600;

  return (
    <>
      <div
        className={`${styles.left_callback} ${
          mobileTrue && styles.hiddenMobile
        }`}
      >
        <a href="tel:18004254500">Toll-Free Number</a>
      </div>
      <div
        className={`${styles.left_request} ${
          mobileTrue && styles.hiddenMobile
        }`}
      >
        <a href="/book-appointment">Book Appiontment</a>
      </div>
    </>
  );
}
