import React, { Fragment } from "react";
import FormInput from "../../components/UI/Input";
import ServicesData from "../../data/Services/allservices";

const Form = () => {
  //  console.log(ServicesData);

  const [formData, setFormData] = React.useState({
    firstName: "",
    lastName: "",
    mobileNumber: "",
    email: "",
    message: "",
    service: "",
    city: "",
  });

  const [city, setCity] = React.useState(["Bangalore", "Mysore", "Ramanagara"]);

  const [showLoader, setShowLoader] = React.useState(false);

  const [error, setError] = React.useState({
    type: "success",
    message: "",
  });

  const formDataChange = async (event) => {
    let { id, value } = event.target;
    if (id === "service" && value !== "") {
      --value;
      value = ServicesData[value]["title"];
    } else if (id === "city" && value !== "") {
      value = city[value];
    }
    // console.log(value);
    setFormData({ ...formData, [id]: value });
  };

  // console.log(formData);

  const postMethod = (params, type = 1) => {
    return {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(params),
    };
  };

  const submitForm = async (event) => {
    event.preventDefault();

    const PROD_URL =
      "https://8ufdu1ayv0.execute-api.eu-central-1.amazonaws.com/prod/api/";
    // "http://localhost:5000/api";

    let url = `${PROD_URL}/contactUs/store`;
    try {
      setShowLoader(true);
      setError({ ...error, message: "" });
      let formOptional = {
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.email,
        message: formData.message,
        service: formData.service,
        city: formData.city,
      };

      if (formData.mobileNumber !== "") {
        formOptional.mobileNumber = formData.mobileNumber;
      }
      let post = postMethod(formOptional);
      const result = await (await fetch(url, post)).json();
      if (result) {
        let { httpCode, message } = result;
        setError({ ...error, message: message });
        if (httpCode === 200) {
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        }
      }
      setShowLoader(false);
    } catch (err) {
      setShowLoader(false);
      console.log(err);
    }
  };

  return (
    <Fragment>
      <form id="contact-form" onSubmit={submitForm}>
        <div className="row">
          <div className="col-md-12 col-lg-12">
            <FormInput
              tag={"input"}
              type={"text"}
              name={"firstName"}
              placeholder={"First Name *"}
              value={formData.firstName}
              change={formDataChange}
              formClassName={"single-input-item-withoutBottom"}
            />
          </div>

          <div className="col-md-12 col-lg-12">
            <FormInput
              tag={"input"}
              type={"text"}
              name={"lastName"}
              placeholder={"Last Name *"}
              value={formData.lastName}
              change={formDataChange}
              formClassName={"single-input-item-withoutBottom"}
            />
          </div>

          <div className="col-md-12 col-lg-12">
            <FormInput
              tag={"input"}
              type={"email"}
              name={"email"}
              placeholder={"Email address *"}
              value={formData.email}
              change={formDataChange}
              formClassName={"single-input-item-withoutBottom"}
            />
          </div>

          <div className="col-md-12 col-lg-12">
            <FormInput
              tag={"input"}
              type={"text"}
              name={"mobileNumber"}
              placeholder={"Phone No"}
              value={formData.mobileNumber}
              change={formDataChange}
              formClassName={"single-input-item-withoutBottom"}
            />
          </div>

          <div className="col-12">
            <select
              required
              onChange={formDataChange}
              className="form-control selectBox"
              id="service"
            >
              <option value="">Select Service</option>
              {ServicesData.map((service) => (
                <option key={service.id} value={service.id}>
                  {service.title}
                </option>
              ))}
            </select>
          </div>

          <div className="col-12">
            <select
              required
              onChange={formDataChange}
              className="form-control selectBox"
              id="city"
            >
              <option value="">Select City</option>
              {city.map((c, key) => (
                <option key={key} value={key}>
                  {c}
                </option>
              ))}
            </select>
          </div>

          <div className="col-12">
            <FormInput
              tag={"textarea"}
              name={"message"}
              placeholder={"Write Your Message *"}
              value={formData.message}
              change={formDataChange}
              formClassName={"single-input-item-withoutBottom"}
            />

            <FormInput
              tag={"button"}
              classes={"btn-outline"}
              loading={showLoader}
              formClassName={"single-input-item-withoutBottom"}
            />

            <div className="form-message">
              {error.message !== "" && (
                <div className={`alert alert-${error.type}`} role="alert">
                  {error.message}
                </div>
              )}
            </div>
          </div>
        </div>
      </form>
    </Fragment>
  );
};

export default Form;
