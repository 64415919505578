import React from 'react'

function BlogSchema86() {
    const blogSchemas86 = `<script type="application/ld+json">
    {
      "@context": "https://schema.org",
      "@type": "BlogPosting",
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": "https://kangaroocareindia.com/blogs/5-ways-to-care-for-a-premature-baby-at-home/86"
      },
      "headline": "5 Ways to Care for a Premature Baby at Home",
      "description": "Taking care of a premature baby is a major concern. If you are the mother of a premature baby, we understand how worried and anxious you must be for your baby’s health.",
      "image": "https://kangaroocareindia.com/static/media/blog-86.ca2c3b4b8f858cb35d92.jpg",  
      "author": {
        "@type": "Organization",
        "name": "kangaroocare",
        "url": "https://kangaroocareindia.com/"
      },  
      "publisher": {
        "@type": "Organization",
        "name": "kangaroocare",
        "logo": {
          "@type": "ImageObject",
          "url": "https://kangaroocareindia.com/images/kangaroo-care-new-logo.png"
        }
      },
      "datePublished": "2022-11-24"
    }
    </script>
    
    `;
    return (
      <div>
        <iframe srcDoc={blogSchemas86} title="Blog Schema" width="0" height="0" />
      </div>
    );
}

export default BlogSchema86
