import React, { Fragment } from "react";
import Header from "../components/Header";
import PageHeader from "../components/PageHeader";
import Services from "../components/ServicesPage";
import Footer from "../components/Footer";
import MobileMenu from "../components/MobileMenu";
import SEO from '../components/SEO';

const PageService = () => {
  return (
    <Fragment>
      <Header />
      <PageHeader
        bgImg={require("../assets/img/gallery-bg.jpg")}
        title=""
        content=""
      />
      <SEO/>

      <Services />
      <Footer />
      <MobileMenu />
    </Fragment>
  );
};

export default PageService;
