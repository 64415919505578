import React from "react";

const BlogSchema43 = () => {
  const blogSchemas43 = `
  <script type="application/ld+json">
  {
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": "https://kangaroocareindia.com/blogs/tips-to-keep-babys-skin-healthy!/43"
    },
    "headline": "Tips To Keep Baby's Skin Healthy!",
    "description": "A baby's skin is very sensitive and needs special care and protection to maintain its health and moisture. It’s difficult for every new parent, about where and how to start taking care of their baby’s needs. Here are some tips to keep your baby’s skin healthy and smooth,",
    "image": "https://kangaroocareindia.com/static/media/blog-43.230529e0.jpg",  
    "author": {
      "@type": "Organization",
      "name": "Kangaroo Care",
      "url": "https://kangaroocareindia.com/"
    },  
    "publisher": {
      "@type": "Organization",
      "name": "Organization",
      "logo": {
        "@type": "ImageObject",
        "url": "https://kangaroocareindia.com/images/kc-logo-pink.png"
      }
    },
    "datePublished": "2021-10-21"
  }
  </script>
  
  
 `;

  return (
    <div>
      <iframe srcDoc={blogSchemas43} title="Blog Schema" width="0" height="0" />
    </div>
  );
};

export default BlogSchema43;
