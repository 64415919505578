import React from "react";

const BlogSchema49 = () => {
  const blogSchemas49 = `<script type="application/ld+json">
  {
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": "https://kangaroocareindia.com/blogs/simple-skincare-routine-during-pregnancy-and-post-childbirth/49"
    },
    "headline": "Simple Skincare Routine during Pregnancy & Post Childbirth",
    "description": "Pregnancy brings lots of joy and glows to a woman's life. A pregnant woman experiences various emotional and physical changes. They might also experience changes in their skin like rosy cheeks, and shiny hair while some have breakouts, dark skin patches, acne, and skin tags. Well, these changes are totally natural and common due to changes in hormonal levels. However, if you are worried about physical changes/beauty, here are some tips to take care of your skin during and post-pregnancy.",
    "image": "https://kangaroocareindia.com/static/media/blog-49.60294e76cf3e18c4e036.jpg",  
    "author": {
      "@type": "Organization",
      "name": "Kangaroo Care",
      "url": "https://kangaroocareindia.com/"
    },  
    "publisher": {
      "@type": "Organization",
      "name": "Kangaroo Care",
      "logo": {
        "@type": "ImageObject",
        "url": ""
      }
    },
    "datePublished": "2021-12-28"
  }
  </script>
  
  

 `;

  return (
    <div>
      <iframe srcDoc={blogSchemas49} title="Blog Schema" width="0" height="0" />
    </div>
  );
};

export default BlogSchema49;
