import React from "react";

const GalleryItems = (props) => {
  return (
    <div className="col-sm-12 col-md-4 card-wrap">
      <div className="container img-container">
        <div className="row img-wrap">
          <img
            src={require("../../assets/img/gallery/pics/" + props.image)}
            alt="Kangaroo Care Hospitals"
          />
        </div>
        <div className="row img-text">
          <p>{props.text}</p>
        </div>
      </div>
    </div>
  );
};

export default GalleryItems;
