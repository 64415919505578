import React, { useState, useEffect } from "react";
import docData from "../../data/Doctors/all-doctors.json";
import Doctor from "../../components/Doctors/Doctor";
import FindDoctor from "./FindDoctor";
import Admin from "../../components/Team/doctorteam";

const TeamPage = () => {
  const [doctorList, setDoctorList] = useState(docData);

  useEffect(() => {
    async function filterList() {
      let doctorList = await docData.filter((doc) => {
        let { showDoctor = true } = doc;
        if (showDoctor === true) {
          return doc;
        }
      });
      setDoctorList(doctorList);
    }
    filterList();
  }, []);

  // console.log(doctorList);

  return (
    <React.Fragment>
      <FindDoctor />
      {/*   <Admin doctorList={doctorList} /> */}

      <div className="team-page-area-wrapper bg-offwhite sp-y pinkBackground">
        <div className="container">
          <div className="row mtn-30">
            {doctorList.map((doc) => (
              <Doctor
                key={doc.id}
                type={"page"}
                id={doc.id}
                firstname={doc.firstname}
                lastname={doc.lastname}
                designation={doc.title}
                profilePic={doc.image}
                bio={doc.bio}
                isDoctor={doc.isDoctor !== undefined ? doc.isDoctor : true}
              />
            ))}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default TeamPage;
