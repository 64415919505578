import React from "react";
import parse from "html-react-parser";
import Divider from "../../Divider";

function SectionTitle(props) {
  const titleTag = () => {
    if (props.titleTag !== undefined) {
      return <h1>{props.title}</h1>;
    } else {
      return <h2>{props.title}</h2>;
    }
  };
  let ptag;
  let testlink = window.location.href;
  let testlink2 = testlink.split("/").slice(3).join("/");
  // console.log(testlink)
  // console.log(testlink2)
  if (testlink2 == "") {
    ptag = <p id="home-page-seo-women">{parse(props.heading)}</p>;
  } else {
    ptag = <h6>{parse(props.heading)}</h6>;
  }
  // console.log(ptag);
  return (
    <div
      className={`section-title ${
        props.variant === "light" ? "section-title--light" : ""
      }`}
    >
      {titleTag()}
      <Divider image={props.image} />

      <h6>{parse(props.heading)}</h6>
      {(() => {
        if (props.text) {
          return <p>{parse(props.text)}</p>;
        }
        if (props.tagline) {
          return <h5 className="tagline">{parse(props.tagline)}</h5>;
        }
      })()}
    </div>
  );
}

export default SectionTitle;
