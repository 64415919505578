import React, { Fragment } from "react";
import PageWrapper from "../../components/PageWrapper";
import BlogDetailsContentWrapper from "./ContentWrapper";
import SidebarForBlog from "../SidebarForBlog";
import PageHeader from "../../components/PageHeader";
import BlogData from "../../data/Blog/blog";
import BlogSchema from "./BlogSchema/BlogSchema";
//  HELPERS
import { redirectTo404 } from "../../helpers";
import RedirectWithStatus from "../../helpers/RedirectWithStatus";

//  HELPERS Schema
import BlogSchema29 from "../../helpers/BlogSchema/Blog29";
import BlogSchema30 from "../../helpers/BlogSchema/Blog30";
import BlogSchema31 from "../../helpers/BlogSchema/Blog31";
import BlogSchema32 from "../../helpers/BlogSchema/Blog32";
import BlogSchema33 from "../../helpers/BlogSchema/Blog33";
import BlogSchema34 from "../../helpers/BlogSchema/Blog34";
import BlogSchema35 from "../../helpers/BlogSchema/Blog35";
import BlogSchema36 from "../../helpers/BlogSchema/Blog36";
import BlogSchema37 from "../../helpers/BlogSchema/Blog37";
import BlogSchema38 from "../../helpers/BlogSchema/Blog38";
import BlogSchema39 from "../../helpers/BlogSchema/Blog39";
import BlogSchema40 from "../../helpers/BlogSchema/Blog40";
import BlogSchema42 from "../../helpers/BlogSchema/Blog42";
import BlogSchema43 from "../../helpers/BlogSchema/Blog43";
import BlogSchema44 from "../../helpers/BlogSchema/Blog44";
import BlogSchema45 from "../../helpers/BlogSchema/Blog45";
import BlogSchema46 from "../../helpers/BlogSchema/Blog46";
import BlogSchema47 from "../../helpers/BlogSchema/Blog47";
import BlogSchema48 from "../../helpers/BlogSchema/Blog48";
import BlogSchema49 from "../../helpers/BlogSchema/Blog49";
import BlogSchema50 from "../../helpers/BlogSchema/Blog50";
import BlogSchema51 from "../../helpers/BlogSchema/Blog51";
import BlogSchema52 from "../../helpers/BlogSchema/Blog52";
import BlogSchema72 from "../../helpers/BlogSchema/Blog72";
import BlogSchema73 from "../../helpers/BlogSchema/Blog73";
import BlogSchema74 from "../../helpers/BlogSchema/Blog74";
import BlogSchema75 from "../../helpers/BlogSchema/Blog75";
import BlogSchema76 from "../../helpers/BlogSchema/Blog76";
import BlogSchema77 from "../../helpers/BlogSchema/Blog77";
import BlogSchema78 from "../../helpers/BlogSchema/Blog78";
import BlogSchema79 from "../../helpers/BlogSchema/Blog79";
import BlogSchema82 from "../../helpers/BlogSchema/Blog82";
import BlogSchema83 from "../../helpers/BlogSchema/Blog83";
import BlogSchema84 from "../../helpers/BlogSchema/Blog84";
import BlogSchema85 from "../../helpers/BlogSchema/Blog85";
import BlogSchema86 from "../../helpers/BlogSchema/Blog86";
import BlogSchema87 from "../../helpers/BlogSchema/Blog87";
import BlogSchema88 from "../../helpers/BlogSchema/Blog88";
import BlogSchema89 from "../../helpers/BlogSchema/Blog89";
import BlogSchema90 from "../../helpers/BlogSchema/Blog90";

const BlogDetailsPage = ({ sidebar }) => {
  // console.log(BlogData);
  // BlogData.map((item) => {
  //   console.log(
  //     `<url> <loc>https://kangaroocareindia.com/blogs/${item.urlIndex}</loc> </url>`
  //   );
  // });
  //  FIND PATH NAME
  let { pathname } = window.location;
  let pathnameArr = pathname.split("/");

  //  OLD URL - GET QUERY PARAM ID & REDIRECT TO NEW URL
  let postId = new URLSearchParams(window.location.search).get("id");
  if (postId !== null) {
    const postData = BlogData.find((post) => post.id === parseInt(postId));
    return (
      <RedirectWithStatus status={301} from="" to={`${postData.urlIndex}`} />
    );
  }

  if (pathnameArr[3] === undefined) {
    //  IF KEY NOT FOUND
    redirectTo404();
  }

  postId = pathnameArr[3];
  const postData = BlogData.find((post) => post.id === parseInt(postId));

  if (!postData) {
    redirectTo404();
  }
  //Blog Schema
  // else if (postId == "38") {
  //   BlogSchemaCodeShow = <BlogSchema38 />;
  // }

  let BlogSchemaCodeShow;
  if (postId == "29") {
    BlogSchemaCodeShow = <BlogSchema29 />;
  } else if (postId == "30") {
    BlogSchemaCodeShow = <BlogSchema30 />;
  } else if (postId == "31") {
    BlogSchemaCodeShow = <BlogSchema31 />;
  } else if (postId == "32") {
    BlogSchemaCodeShow = <BlogSchema32 />;
  } else if (postId == "33") {
    BlogSchemaCodeShow = <BlogSchema33 />;
  } else if (postId == "34") {
    BlogSchemaCodeShow = <BlogSchema34 />;
  } else if (postId == "35") {
    BlogSchemaCodeShow = <BlogSchema35 />;
  } else if (postId == "36") {
    BlogSchemaCodeShow = <BlogSchema36 />;
  } else if (postId == "37") {
    BlogSchemaCodeShow = <BlogSchema37 />;
  } else if (postId == "39") {
    BlogSchemaCodeShow = <BlogSchema39 />;
  } else if (postId == "40") {
    BlogSchemaCodeShow = <BlogSchema40 />;
  } else if (postId == "42") {
    BlogSchemaCodeShow = <BlogSchema42 />;
  } else if (postId == "43") {
    BlogSchemaCodeShow = <BlogSchema43 />;
  } else if (postId == "44") {
    BlogSchemaCodeShow = <BlogSchema44 />;
  } else if (postId == "45") {
    BlogSchemaCodeShow = <BlogSchema45 />;
  } else if (postId == "46") {
    BlogSchemaCodeShow = <BlogSchema46 />;
  } else if (postId == "47") {
    BlogSchemaCodeShow = <BlogSchema47 />;
  } else if (postId == "48") {
    BlogSchemaCodeShow = <BlogSchema48 />;
  } else if (postId == "49") {
    BlogSchemaCodeShow = <BlogSchema49 />;
  } else if (postId == "50") {
    BlogSchemaCodeShow = <BlogSchema50 />;
  } else if (postId == "51") {
    BlogSchemaCodeShow = <BlogSchema51 />;
  } else if (postId == "52") {
    BlogSchemaCodeShow = <BlogSchema52 />;
  } else if (postId == "72") {
    BlogSchemaCodeShow = <BlogSchema72 />;
  } else if (postId == "73") {
    BlogSchemaCodeShow = <BlogSchema73 />;
  } else if (postId == "74") {
    BlogSchemaCodeShow = <BlogSchema74 />;
  } else if (postId == "75") {
    BlogSchemaCodeShow = <BlogSchema75 />;
  } else if (postId == "76") {
    BlogSchemaCodeShow = <BlogSchema76 />;
  } else if (postId == "77") {
    BlogSchemaCodeShow = <BlogSchema77 />;
  } else if (postId == "78") {
    BlogSchemaCodeShow = <BlogSchema78 />;
  } else if (postId == "79") {
    BlogSchemaCodeShow = <BlogSchema79 />;
  } else if (postId == "82") {
    BlogSchemaCodeShow = <BlogSchema82 />;
  } else if (postId == "83") {
    BlogSchemaCodeShow = <BlogSchema83 />;
  } else if (postId == "84") {
    BlogSchemaCodeShow = <BlogSchema84 />;
  } else if (postId == "85") {
    BlogSchemaCodeShow = <BlogSchema85 />;
  } else if (postId == "86") {
    BlogSchemaCodeShow = <BlogSchema86 />;
  } else if (postId == "87") {
    BlogSchemaCodeShow = <BlogSchema87 />;
  } else if (postId == "88") {
    BlogSchemaCodeShow = <BlogSchema88 />;
  } else if (postId == "89") {
    BlogSchemaCodeShow = <BlogSchema89 />;
  } else if (postId == "90") {
    BlogSchemaCodeShow = <BlogSchema90 />;
  }

  return (
    <Fragment>
      <PageHeader
        bgImg={require("../../assets/img/gallery-bg.jpg")}
        title={postData.title}
        content=""
      />
      {/* <BlogSchemaCodeShow /> */}
      {BlogSchemaCodeShow}
      <PageWrapper classes={"blog-details-page-content sp-y"}>
        <div className={!sidebar ? "col-12" : "col-lg-9"}>
          <BlogDetailsContentWrapper sidebar={sidebar} post={postData} />
        </div>
        {sidebar ? <SidebarForBlog /> : null}
        <BlogSchema id={postId} />
      </PageWrapper>
    </Fragment>
  );
};

export default BlogDetailsPage;
