import React, { Fragment } from "react";
import Header from "../components/Header";
import DocDetailsPage from "../templates/DocDetails";
import Footer from "../components/Footer";
import MobileMenu from "../components/MobileMenu";
import SEO from "../components/SEO";
import DocData from "../data/Doctors/all-doctors.json";
const PageDocDetails = () => {
  // DocData.map((item) => {
  //   let name = item.firstname;
  //   if (item.lastname && item.lastname !== ".") {
  //     name = name + " " + item.lastname;
  //   }
  //   const docUrl = `/doctor/${name.split(" ").join("-").toLocaleLowerCase()}/${
  //     item.id
  //   }`;

  //   console.log(
  //     `<url> <loc>https://kangaroocareindia.com${docUrl}</loc> </url>`
  //   );
  // });
  return (
    <Fragment>
      <Header />
      <DocDetailsPage />
      <SEO />
      <Footer />
      <MobileMenu />
    </Fragment>
  );
};

export default PageDocDetails;
