import React from "react";
import { Accordion } from "react-bootstrap";
import Faqs from "./Faqs";

const FrequentlyAskedQuestions = ({data:faqData,title=""}) => {
  return (
    <>
      <div className="faq-page-wrapper sm-top">
        <div className="container">
          {title !== "" && <h2><b>{title}</b></h2>}
          <div className="row">
            <Accordion defaultActiveKey="0">
              {faqData.map((faq) => (
                <Faqs
                  key={faq.id}
                  id={faq.id}
                  title={faq.title}
                  text={faq.text}
                />
              ))}
            </Accordion>
          </div>
        </div>
      </div>
    </>
  );
};

export default FrequentlyAskedQuestions;
