import React from "react";
import SectionTitle from "../UI/SectionTitle";
import serviceTopBg from "../../assets/img/service/service-bg-1.jpg";

function Services() {
  return (
    <div className={`service-area-wrapper`}>
      <div
        className="service-area-top"
        style={{ backgroundImage: `url("${serviceTopBg}")` }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-xl-8 m-auto text-center">
              <SectionTitle
                variant="light"
                image={require("../../assets/img/separator20.svg")}
                title="Packages"
                heading=""
              />
            </div>
          </div>
        </div>
      </div>

      <div className="service-content-area">
        <div className="container">
          <div className="row mtn-30 align-items-center justify-content-center">
            {/* {downloadDatas.map((dl) => (
              <DownloadItem
                key={dl.id}
                id={dl.id}
                title={dl.title}
                file={dl.file}
                image={dl.image}
              />
            ))} */}
            <div className="col-sm-12 col-lg-9">
              <div className="service-item ">
                <figure className="service-thumb">
                  <a
                    href={"/files/senior-women-wellness-coupon-print.pdf" }
                    target="_blank"
                    rel="noopener noreferrer"
                    className="stretched-link"
                  >
                    <img
                      src={require("../../assets/img/media/packageimage/package-02.jpg")}
                      alt="Senior Women Wellness"
                    />
                  </a>

                  <figcaption className="service-txt">
                    <h5> Senior Women Wellness</h5>
                  </figcaption>
                </figure>
                <div className="service-content">
                  <div className="service-content-inner">
                    <img
                      src={require("../../assets/img/separator20.svg")}
                      alt=""
                      className="dlIcon"
                    />
                    <h5>
                      <a
                        href={"/files/senior-women-wellness-coupon-print.pdf"}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="stretched-link"
                      >
                       Senior Women Wellness
                      </a>
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Services;
