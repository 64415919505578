import React, { useEffect, useState } from "react";
import { Link, Redirect, useHistory } from "react-router-dom";
//styles
import styles from "./step.module.css";
import axios from "axios";
import { useContext } from "react";
import BookConst from "../../AppionmentProvider";
import { Button, Modal } from "react-bootstrap";
const moment = require("moment");

const momentTimeZone = require("moment-timezone");
// const timezone = require('moment-timezone')
// const baseUrl ='https://prod.backend.pulseright.io:3000';
const baseUrl = "https://prod.backend.pulseright.io:3000";
// const history = useHistory();
let user;
export default function StepThree(props) {
  // const history = useHistory();
  const {
    seldoctors,
    selecDates,
    timeSlot,
    //step 2
    phoneNumber,
    selservice,
    selecPatient,
  } = useContext(BookConst);

  let dateformat;
  useEffect(() => {}, []);

  const [number, setNumber] = useState(phoneNumber);
  const [email, setemail] = useState(selecPatient?.email);
  const [message, setMessage] = useState("");
  const [alertMessage, sendMessage] = useState("");
  const [show, setShow] = useState(false);

  const modalClose = () => setShow(false);

  useEffect(() => {
    user = JSON.parse(localStorage.getItem("user"));
  });

  function bookappointment() {
    let body = {
      name: selecPatient?.patientName,
      date: momentTimeZone(selecDates).tz("UTC").format(),
      time: momentTimeZone(timeSlot, ["HH:mm"]).tz("UTC").format(),
      service: selservice?._id,
      message: message,
      status: true,
      user: selecPatient?.id,
      doctor: seldoctors?._id,
      organization: props.org,
    };
    if (selservice?.prepaid) {
      body.status = false;
    } else {
      body.status = true;
    }

    axios
      .post(baseUrl + "/booking/create", body, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + user.token + "",
        },
      })
      .then((res) => {
        sendMessage("Appointment Booking success..");
        setShow(true);

        setTimeout(() => {
          // history("/book-appointment");

          console.log("appt booked");
          window.location.href = "/book-appointment";
        }, 5000);
      })
      .catch((err) => {
        sendMessage("Appointment Booking Already exists");
        setShow(true);
      });
  }

  return (
    <section className={styles.step_container}>
      <section className={styles.step_items}>
        <section className={styles.step_appiontment}>
          <h2>Appointment Details</h2>
          <div>
            <div>
              <h3>DATE</h3>
              <p>{moment(selecDates).format("DD-MM-yyyy")}</p>
            </div>
            <div>
              <h3>TIME</h3>
              <p>{moment(timeSlot, "HH:mm").format("LT")}</p>
            </div>
            <div>
              <h3>PATIENT NAME</h3>
              <p>{selecPatient?.patientName}</p>
            </div>
            <div>
              <h3>PHONE NUMBER</h3>
              <p>{selecPatient?.phoneNumber}</p>
            </div>
            <div>
              <h3>DOCTOR NAME</h3>
              <p>{seldoctors?.name}</p>
            </div>
            <div>
              <h3>SERVICE</h3>
              <p>{selservice?.name}</p>
            </div>
          </div>
        </section>
        <section className={styles.step_date}>
          <h2>Notification</h2>

          <section className={styles.step_date_items}>
            <div>
              <h3>Patient Number</h3>
              <input
                type="tel"
                minLength="10"
                maxLength="10"
                value={selecPatient?.phoneNumber}
                disabled
                onChange={(e) => setNumber(e.target.value)}
                pattern="^[6-9]\d{9}$"
              />
            </div>
            <div>
              <h3>Email</h3>
              <input
                type="email"
                disabled
                value={email}
                onChange={(e) => setemail(e.target.value)}
              />
            </div>
          </section>
          <section className={styles.step_date_message}>
            <label>Your Messagae</label>
            <textarea onChange={(e) => setMessage(e.target.value)}>
              {message}
            </textarea>
          </section>
        </section>
      </section>
      <section className={styles.confirm_btn}>
        <button onClick={bookappointment} className={"btn btn-danger"}>
          Confirm Your Appiontment
        </button>{" "}
      </section>
      <Modal show={show} onHide={modalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Alert</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className={styles.message}>{alertMessage}</p>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </section>
  );
}
