import React from "react";

const NICUGynecologyFAQSchema = () => {
  const gyanecologySchema = `
<script type="application/ld+json">
{
  "@context": "https://schema.org",
  "@type": "FAQPage",
  "mainEntity": [{
    "@type": "Question",
    "name": "What does an obstetrician do?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "The role & responsibility of an obstetrician is to provide women care during pregnancy and after birth. Professional Obstetricians at Kangaroo Care have experience in managing complex or high-risk pregnancies and births."
    }
  },{
    "@type": "Question",
    "name": "At what age should I consult a gynecologist?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "According to a report of the American College of Obstetricians and Gynecologists, girls should first visit a gynecologist between the ages of 13 and 15."
    }
  },{
    "@type": "Question",
    "name": "Is there any difference between an obstetrician and a gynecologist?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Yes, there is a huge difference between both. An obstetrician specializes in obstetrics and deals with all normal and complex (high-risk) pregnancies, right from prenatal care to postnatal care. Gynecologists look after the health of female reproductive organs. Conditions like painful periods, heavy bleeding during periods, fibroids, etc are dealt by a gynecologist."
    }
  },{
    "@type": "Question",
    "name": "Who are the best gynecologists in Mysore?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "The team of doctors at Kangaroo Care India consists of the best gynecologists in Mysore that offers comprehensive services ranging from pre-birth to post-birth care."
    }
  },{
    "@type": "Question",
    "name": "Which is the best pregnancy care hospital in Mysore?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Kangaroo Care India is undoubtedly the best pregnancy care hospital in Mysore that offers comprehensive pregnancy care to both mother and newborn."
    }
  }]
}
</script>


 `;

  return (
    <div>
      <iframe
        srcDoc={gyanecologySchema}
        title="NICU Gynecology FAQ Schema"
        width="0"
        height="0"
      />
    </div>
  );
};

export default NICUGynecologyFAQSchema;
