import React from 'react'

function BlogSchema76() {
    const blogSchemas76 = `<script type="application/ld+json">
    {
      "@context": "https://schema.org",
      "@type": "BlogPosting",
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": "https://kangaroocareindia.com/blogs/breastfeeding-benefits-for-the-baby-Mother/76"
      },
      "headline": "Breastfeeding Benefits for the Baby & Mother",
      "description": "Have you ever wondered why breastfeeding is considered best for children when there are several options to feed the newly born child?",
      "image": "https://kangaroocareindia.com/static/media/blog-76.b01340f576bf59d99d5d.jpg",  
      "author": {
        "@type": "Organization",
        "name": "Kangaroo care"
      },  
      "publisher": {
        "@type": "Organization",
        "name": "Kangaroo care",
        "logo": {
          "@type": "ImageObject",
          "url": "https://kangaroocareindia.com/images/kclogo-01-01.png"
        }
      },
      "datePublished": "2022-08-12"
    }
    </script>   
    `;
    return (
      <div>
        <iframe srcDoc={blogSchemas76} title="Blog Schema" width="0" height="0" />
      </div>
    );
}

export default BlogSchema76
