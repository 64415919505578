import React from 'react'

function BlogSchema87() {
    const blogSchemas87 = `<script type="application/ld+json">
    {
      "@context": "https://schema.org",
      "@type": "BlogPosting",
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": "https://kangaroocareindia.com/blogs/a-step-by-step-guide-to-treating-pcos/87"
      },
      "headline": "Admin Administrator A Step-by-Step Guide to Treating PCOS",
      "description": "PCOS (Polycystic Ovarian Syndrome) is a common health disorder today. It is caused due to imbalance of hormones, which further results in ovarian cysts.",
      "image": "https://kangaroocareindia.com/static/media/blog-87.e2515e6b1b0ed4e8a481.jpg",  
      "author": {
        "@type": "Organization",
        "name": "kangaroocare",
        "url": "https://kangaroocareindia.com/"
      },  
      "publisher": {
        "@type": "Organization",
        "name": "kangaroocare",
        "logo": {
          "@type": "ImageObject",
          "url": "https://kangaroocareindia.com/images/kangaroo-care-new-logo.png"
        }
      },
      "datePublished": "2022-11-28"
    }
    </script>    
    `;
    return (
      <div>
        <iframe srcDoc={blogSchemas87} title="Blog Schema" width="0" height="0" />
      </div>
    );
}

export default BlogSchema87
